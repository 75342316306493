import { CancelTokenSource } from "axios";
import { METHODS, request } from "../../slices/axiosInstances";

export default {
  getReceiptTranserProductsDetails: (): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getReceiptTranserProductsDetails.php", {}),

  transferProductsToContrustionCrew: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } =>
    request(
      METHODS.POST,
      "/transferProductsToContrustionCrew.php",
      { params },
      true,
      { "Content-Type": "application/pdf" },
      "blob"
    ),
};
