import { CancelTokenSource } from "axios";
import { METHODS, request } from "../../slices/axiosInstances";

export default {
  loginUser: (params?: {
    username: string;
    password: string;
  }): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.POST, "/login.php", { params }),
  isLoggedIn: (): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/login_status.php", {}),
};
