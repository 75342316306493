import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { DisableComponent, TextWorkSans } from "../../components/common.style";
import { shouldForwardProp } from "@mui/system";
import { ColorsType } from "../../theme/types";
import SelectComponent from "../../components/Select/Select";
import { TextField } from "../../components";
import Button from "../../components/Button/Button";
import { selectFashDetails } from "../../slices/receiptSlice/selectors";
import { useFormContext } from "react-hook-form";
import CustomTableRow from "./Table/TableRow";
import { AppDispatch } from "../../slices/store";
import { setTabOption } from "../../slices/dashboardProductsSlice/dashboardProductsSlice";
import { selectDashboardProducts } from "../../slices/dashboardProductsSlice/selectors";
import { Product } from "../../slices/dashboardProductsSlice/types";

export interface CustomTableCellProps extends TableCellProps {
  disablePadding?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  backgroundColor?: ColorsType;
  whiteSpace?: boolean;
}

export const CustomTableCell = styled(TableCell, {
  shouldForwardProp: (prop: any) =>
    shouldForwardProp(prop) && prop !== "disablePadding",
})<CustomTableCellProps>(
  ({ disablePadding, width, maxWidth, whiteSpace, backgroundColor }) => {
    const processedWidth = typeof width === "number" ? `${width}px` : width;
    const processedMaxWidth =
      typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;
    return {
      color: "white",
      "&.MuiTableCell-head": {
        color: "#7879F1",
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
      "&.MuiTableCell-body": {
        color: "white",
        fontSize: 14,
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: whiteSpace ? "nowrap" : "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
    };
  }
);

export const HeaderRow = styled(TableRow)({
  color: "#7879F1",
  "&.MuiTableRow-head": {
    backgroundColor: "transparent",
    color: "#7879F1",
  },
});

interface Props {
  tabOption?: number;
}

const TableComponent: React.FC<Props> = ({ tabOption }) => {
  const { control, reset } = useFormContext<any>();
  const { typos } = useSelector(selectFashDetails);

  const [loader, setLoader] = useState<boolean>(false);
  const [item, setItem] = useState<{ title: string }>({ title: "" });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const { crewInventoryQuantity, warehouseInventoryQuantity } = useSelector(
    selectDashboardProducts
  );

  const tableHeaders = [
    { id: "id", label: "Id", align: "left", fontSize: 12, color: "primary" },
    { id: "kay", label: "KAY", align: "left", fontSize: 12, color: "primary" },
    {
      id: "name",
      label: "Όνομα",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "mm",
      label: "MM",
      align: "left",
      fontSize: 12,
      color: "primary",
      whiteSpace: "desktopMode",
    },
    {
      id: "abbreviation",
      label: "Συντόμευση",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "type",
      label: "Τύπος",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "supplier",
      label: "Προμηθευτής",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "quantity",
      label: "Ποσότητα",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    reset();
    dispatch(setTabOption(newValue));
  };

  // Calculate products array based on tabOption
  const products =
    tabOption === 0
      ? warehouseInventoryQuantity?.products || []
      : crewInventoryQuantity?.products || [];

  // Use useEffect to recalculate filteredProducts whenever dependencies change
  useEffect(() => {
    if (searchTerm !== "" || item.title !== "") {
      setFilteredProducts((prevFilteredProducts) =>
        products
          .filter((product) =>
            item.title ? product.typos === item.title : true
          )
          .filter((product) =>
            searchTerm
              ? product.product_name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              : true
          )
      );
    } else {
      // If both searchTerm and item.title are empty, just reset to the original products
      setFilteredProducts(products);
    }
  }, [searchTerm, item.title, products]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ marginTop: "25px", marginBottom: "25px" }}>
        {/* <TextWorkSans fontSize={22} color={"secondary"}>
          Προϊόντα
        </TextWorkSans> */}
      </div>
      <DisableComponent
        style={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <SelectComponent
            maxWidth={"180px"}
            label="Διάλεξε Τύπο"
            placeholder="= Επέλεξε -"
            items={typos}
            value={item}
            noneOption={true}
            onChange={(value: any) => setItem(value)}
          />
          <TextField
            maxwidth={280}
            maxLength={10}
            type="text"
            label="Αναζήτηση"
            placeholder="Αναζήτηση"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e)}
          />
          <Button
            padding={0}
            size="medium"
            backgroundColor="primary"
            color="white"
            type="button"
            fontSize={12}
            onClick={() => {
              setSearchTerm("");
              setItem({ title: "" });
            }}
          >
            ΚΑΘΑΡΙΣΜΑ ΦΙΛΤΡΩΝ
          </Button>
        </div>
      </DisableComponent>
      <Table style={{ width: "100%" }}>
        <TableHead>
          <HeaderRow>
            {tableHeaders.map((header) => (
              <CustomTableCell key={header.id} width={"auto"}>
                <TextWorkSans color="primary" fontSize={header.fontSize}>
                  {header.label}
                </TextWorkSans>
              </CustomTableCell>
            ))}
          </HeaderRow>
        </TableHead>

        {loader ? (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={10}
                style={{ verticalAlign: "middle", height: "200px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress color={"primary"} size={50} />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {filteredProducts.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ verticalAlign: "middle", height: "200px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <TextWorkSans>NO MORE DATA</TextWorkSans>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              filteredProducts.map((product) => (
                <CustomTableRow key={product.product_id} product={product} />
              ))
            )}
          </TableBody>
        )}
      </Table>
    </div>
  );
};

export default TableComponent;
