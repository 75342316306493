import { CancelTokenSource } from "axios";
import { METHODS, request } from "../../slices/axiosInstances";

export default {
  getWarehouseLog: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getWarehouseLog.php", { params }),
  getReceiptsLog: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getReceiptsLog.php", { params }),
  cancelTransfer: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/cancelTransfer.php", { params }),
  getTransferConstructionCrewLogs: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getTransferConstructionCrewLogs.php", { params }),
  getTransferWarehouseLogs: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getTransferWarehouseLogs.php", { params }),
  generatePDFWarehouses: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } =>
    request(
      METHODS.POST,
      "/generatePDFWarehouses.php",
      { params },
      true,
      { "Content-Type": "application/pdf" },
      "blob"
    ),
  generatePDFConstructionCrew: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } =>
    request(
      METHODS.POST,
      "/generatePDFConstructionCrew.php",
      { params },
      true,
      { "Content-Type": "application/pdf" },
      "blob"
    ),
  generatePDFAddReceipt: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } =>
    request(
      METHODS.POST,
      "/generatePDFAddReceipt.php",
      { params },
      true,
      { "Content-Type": "application/pdf" },
      "blob"
    ),

  getQuantityCrew: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } =>
    request(
      METHODS.GET,
      "/getQuantityCrew.php",
      { params },
      true,
      { "Content-Type": "application/pdf" },
      "blob"
    ),
  getQuantityWarehouses: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } =>
    request(
      METHODS.GET,
      "/getQuantityWarehouses.php",
      { params },
      true,
      { "Content-Type": "application/pdf" },
      "blob"
    ),
};
