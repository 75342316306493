import { Icon } from "../../../../components";
import { CardProps } from "./types";
import { CardContainer } from "./Card.style";
import { TextWorkSans } from "../../../../components/common.style";

const Card: React.FC<CardProps> = ({ title, icon, onClick }) => {
  return (
    <CardContainer onClick={onClick}>
      <Icon name={icon} color="primary" width={200} height={200} />
      <TextWorkSans fontSize={18} color="secondary">
        {title}
      </TextWorkSans>
    </CardContainer>
  );
};

export default Card;
