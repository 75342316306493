import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const ZoomIn = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "21"}
      height={height ? height : "21"}
      fill="none"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8549 1.75003C12.8597 2.3023 13.3112 2.74616 13.8635 2.74142L16.9162 2.71524L12.2221 7.40933C11.8316 7.79986 11.8316 8.43302 12.2221 8.82355C12.6127 9.21407 13.2458 9.21407 13.6364 8.82355L18.2668 4.19312L18.3039 7.20278C18.3108 7.75502 18.764 8.19717 19.3162 8.19036C19.8685 8.18354 20.3106 7.73033 20.3038 7.17809L20.2359 1.68297C20.2291 1.1322 19.7782 0.690627 19.2274 0.69535L13.8463 0.741495C13.2941 0.746231 12.8502 1.19777 12.8549 1.75003ZM8.14621 19.2511C8.14147 18.6988 7.68993 18.2549 7.13767 18.2597L4.0849 18.2859L8.77899 13.5918C9.16952 13.2012 9.16952 12.5681 8.77899 12.1776C8.38847 11.787 7.7553 11.787 7.36478 12.1776L2.73435 16.808L2.69719 13.7983C2.69037 13.2461 2.23716 12.8039 1.68492 12.8107C1.13268 12.8176 0.690525 13.2708 0.697343 13.823L0.765193 19.3181C0.771993 19.8689 1.2229 20.3105 1.77369 20.3058L7.15482 20.2596C7.70708 20.2549 8.15094 19.8033 8.14621 19.2511Z"
        fill={colors[color || "white"]}
      />
    </svg>
  );
};

export default ZoomIn;
