// Import the styled method from @emotion/styled
import styled from "@emotion/styled";
import { Button } from "@mui/material";

// Create a styled component
export const ButtonComponent = styled(Button)`
  background-color: blue;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
`;
