import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Cancel = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      fill="none"
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="6.41487"
        cy="6.23713"
        r="5.55549"
        fill={colors[color || "white"]}
      />
    </svg>
  );
};

export default Cancel;
