import styled from "@emotion/styled";
import { ColorsType } from "../theme/types";
import { css } from "@emotion/react";

type Text = {
  color?: ColorsType;
  fontSize?: number;
  fontWeight?: number;
  paddingTop?: string;
};

export const TextWorkSans = styled.span<Text>`
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  color: ${(props) =>
    props.color
      ? `${props.theme.colors[props.color]}`
      : `${props.theme.colors.irisPure}`};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14")}px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  padding-top: ${(props) => (props.paddingTop ? `${props.paddingTop}` : "0px")};
`;

type HeadingsType = {
  color?: ColorsType;
  opacity?: number | null;
  textDecorationLine?:
    | "underline"
    | "overline"
    | "line-through"
    | "blink"
    | "undefined";
  theme?: any;
};

const fontFamilyAlt = css`
  font-family: "DM Sans", sans-serif;
`;

export const lineDecoration = ({
  opacity,
  textDecorationLine,
  color,
}: HeadingsType) => css`
  text-decoration-line: ${textDecorationLine ? textDecorationLine : "none"};
  opacity: ${opacity ? opacity : null};
`;

/* Headings / H4 - Category */
export const H4 = styled.span<HeadingsType>`
  ${fontFamilyAlt}
  ${(props: HeadingsType) =>
    lineDecoration({
      opacity: props.opacity,
      textDecorationLine: props.textDecorationLine,
      color: props.color,
    })}
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: ${(props) =>
    props.theme.mode === "dark"
      ? props.theme.colors[props.color || "primary"]
      : props.theme.colors.primary};
`;
export const lineDecorationBody = ({
  opacity,
  textDecorationLine,
}: TextProps) => css`
  text-decoration-line: ${textDecorationLine ? textDecorationLine : "none"};
  opacity: ${opacity ? opacity : null};
`;
export type TextProps = {
  color?: ColorsType;
  bold?: boolean;
  fontSize?: string;
  center?: boolean;
  onClick?: () => void; // to automatically change the cursor when onClick is given
  opacity?: number | null;
  textDecorationLine?:
    | "underline"
    | "overline"
    | "line-through"
    | "blink"
    | "undefined";
  theme?: any;
};
const fontFamily = css`
  font-family: "Work Sans", sans-serif;
`;
const commonStyles = ({ onClick }: TextProps) => css`
  ${fontFamily};
  ${onClick && `cursor: pointer;`}
`;
export const bodyTextLarge = ({
  color,
  bold,
  center,
  onClick,
  fontSize,
}: TextProps) => css`
  ${commonStyles({ onClick })}
  font-weight: ${bold ? "bold" : "normal"};
  font-size: ${fontSize ? fontSize : "18px"};
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${color};
  ${center && `text-align: center;`}
`;
export const BodyTextLarge = styled.span<TextProps>`
  ${(props: TextProps) =>
    lineDecorationBody({
      opacity: props.opacity,
      textDecorationLine: props.textDecorationLine,
    })}
  ${(props: TextProps) =>
    bodyTextLarge({
      color: props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.metallicDark,
      bold: props.bold,
      center: props.center,
      onClick: props.onClick,
    })}
`;

export const customScrollbars = css`
  scrollbar-color: #888 transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const DisableComponent = styled.div<{
  disabled?: boolean;
  opacity?: number;
}>`
  ${(props) =>
    props.disabled &&
    `
      opacity: ${props.opacity ?? 0.2};
      width: auto;
      height: 100%;
      pointer-events: none;
      user-select: none;
      overflow-wrap: break-word;
      word-wrap: break-word;
    `}
`;
