import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Eye = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "21"}
      height={height ? height : "21"}
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
        fill={colors[color || "white"]}
      />
      <path
        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
        fill={colors[color || "white"]}
      ></path>
    </svg>

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width ? width : '21'}
    //   height={height ? height : '21'}
    //   aria-hidden="true"
    //   className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar"
    //   viewBox="0 0 24 24"
    //   {...props}
    // >
    //   <g fill={colors[color || 'white']}>
    //     <path d="M9.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z" />
    //     <path
    //       fillRule="evenodd"
    //       d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4 7.818 4 4.972 6.5 3.275 8.704 2.425 9.81 2 10.361 2 12Zm10-3.75a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
    //       clipRule="evenodd"
    //     />
    //   </g>
    // </svg>
  );
};

export default Eye;
