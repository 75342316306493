import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import fashReducer from "./receiptSlice/receiptSlice";
import desktopMode from "./desktopMode/desktopMode";
import productsSlice from "./productsSlice/productsSlice";
import modalSlice from "./modalSlice/modalSlice";
import receiptTransferSlice from "./receiptTransferSlice/receiptTransferSlice";
import transferProductsToCrew from "./transferProductsToCrewSlice/transferProductsToCrewSlice";
import dashboardSlice from "./dashboardSlice/dashboardSlice";
import dashboardAdminSlice from "./dashboardAdminSlice/dashboardAdminSlice";
import crewDashboardSlice from "./crewDashboardSlice/crewDashboardSlice";
import dashboardProductsSlice from "./dashboardProductsSlice/dashboardProductsSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    fasi: fashReducer,
    receiptTransfer: receiptTransferSlice,
    products: productsSlice,
    desktopMode: desktopMode,
    modal: modalSlice,
    transferToCrew: transferProductsToCrew,
    dashboard: dashboardSlice,
    crewDashboardSlice: crewDashboardSlice,
    dashboardAdminSlice: dashboardAdminSlice,
    dashboardProductsSlice: dashboardProductsSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
