import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const LeftArrow = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  return (
    <svg
      width={width ? width : "14"}
      height={height ? height : "22"}
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ transition: "transform 450ms ease-in-out 25ms" }}
        fill={colors[color || "metallicPearlWhite"]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2961 0.77323C13.0051 1.46915 13.0105 2.6098 12.3079 3.31233L5.16298 10.4573L12.3103 17.6046C13.0146 18.3089 13.0179 19.4499 12.3175 20.1582C11.6056 20.8783 10.4415 20.8746 9.73412 20.1499L0.272461 10.4573L9.76984 0.795873C10.4614 0.0924217 11.592 0.0822872 12.2961 0.77323Z"
      />
    </svg>
  );
};

export default LeftArrow;
