import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../slices/store";
import { useFieldArray, useForm } from "react-hook-form";
import { TextWorkSans } from "../../components/common.style";
import Button from "../../components/Button/Button";
import SearchBar from "./SearchBar/SearchBar";
import { getReceiptTransferProducts } from "../../slices/receiptTransferSlice/receiptTransferSlice";
import {
  getTransferedProducts,
  getTransferedProductsStatus,
} from "../../slices/receiptTransferSlice/selectors";
import {
  FormValues,
  getReceiptTranserProductsDetails,
} from "../../slices/transferProductsToCrewSlice/transferProductsToCrewSlice";
import TableComponent from "./Table";
import {
  getBuildings,
  getDashboardDetails,
} from "../../slices/dashboardSlice/dashboardSlice";

const Dashboard: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { control, watch, setValue, handleSubmit, getValues, resetField } =
    useForm<FormValues>({
      defaultValues: {
        fash: undefined,
        provider: undefined,
        from: undefined,
        to_construction_crew: undefined,
        products: undefined,
        date: "",
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });

  const allFieldsFilled = React.useMemo(
    () => (): boolean => {
      const values = watch();
      if (
        values.fash &&
        values.provider &&
        values.from &&
        values.to_construction_crew &&
        values.products &&
        values.products.length > 0 &&
        values.date
      ) {
        return true;
      } else {
        return false;
      }
    },
    [watch]
  );

  useEffect(() => {
    dispatch(getReceiptTranserProductsDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboardDetails());
  }, [dispatch]);

  const onSubmit = () => {
    history("/transferToConstructionCrew/preview");
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };
  const [previewPage, setPreviewPage] = useState<boolean>(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "40px",
        padding: "40px",
        width: "100%",
        maxWidth: "1300px",
        marginTop: "40px",
        marginBottom: "40px",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
        boxSizing: "border-box",
        alignItems: "center",
      }}
    >
      <div
        style={{
          marginTop: "25px",
          marginBottom: "10px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <TextWorkSans fontSize={25} color={"secondary"}>
          {previewPage ? " ΕΠΙΘΕΩΡΗΣΗ ΦΟΡΜΑΣ " : " ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ"}
        </TextWorkSans>
        <div style={{ marginTop: "auto" }}>
          <Button
            padding={0}
            size="medium"
            backgroundColor="primary"
            color="white"
            type="submit"
            fontSize={12}
            onClick={() => {
              history("/dashboard/newBuilding");
            }}
          >
            {previewPage ? " ΠΡΟΣΘΗΚΗ ΠΑΡΑΛΑΒΗΣ " : " ΠΡΟΣΘΗΚΗ ΠΟΛΥΚΑΤΟΙΚΙΑΣ"}
          </Button>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <TableComponent
          watch={watch}
          control={control}
          fields={fields}
          remove={remove}
          append={append}
          setValue={setValue}
          getValues={getValues}
        />
      </div>
    </div>
  );
};

export default Dashboard;
