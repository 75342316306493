import React, { useState } from "react";
import { TextArea } from "./TextArea.style";
import { TextFieldLabel } from "../TextField/TextField.style";
import { H4 } from "../common.style";

type TextAreaComponentProps = {
  id?: string;
  placeholder?: string;
  border?: string;
  borderRadius?: string;
  height?: string;
  maxwidth?: number;
  minWidth?: number;
  padding?: string;
  fontSize?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void; // Accept onChange prop
  onBlur?: () => void; // Accept onBlur prop if needed
  disabled?: boolean;
  name?: string; // Accept name prop if needed
  required?: boolean;
  label?: string;
  type?: string;
};

const TextAreaComponent: React.FC<TextAreaComponentProps> = ({
  id,
  placeholder,
  border,
  borderRadius,
  height,
  maxwidth,
  minWidth,
  padding,
  fontSize,
  value = "",
  onChange,
  onBlur,
  disabled,
  name,
  required,
  label,
  type,
}) => {
  const [textLength, setTextLength] = useState(value.length);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextLength(event.target.value.length);
    if (onChange) {
      onChange(event); // Pass the event to the parent onChange handler
    }
  };

  return (
    <div>
      {label && (
        <TextFieldLabel>
          <H4 color="secondary">{label}</H4>
          {required && <H4 color="candyRed"> *</H4>}
        </TextFieldLabel>
      )}{" "}
      <TextArea
        id={id}
        placeholder={placeholder}
        border={border}
        borderRadius={borderRadius}
        height={height}
        maxwidth={maxwidth}
        minWidth={minWidth}
        padding={padding}
        fontSize={fontSize}
        value={value}
        onChange={handleChange} // Handle change event
        onBlur={onBlur}
        disabled={disabled}
        name={name}
        required={required}
      />
    </div>
  );
};

export default TextAreaComponent;
