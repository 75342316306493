import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import fasisApi from "../../providers/fasisAPI";
import { GenericModalProperties } from "../modalSlice/types";
import { closeGenericModal, openGenericModal } from "../modalSlice/modalSlice";

export interface FormValues {
  fash: { title: string; id: string };
  location: { title: string; id: string };
  provider: { title: string; id: string };
  company: { title: string; id: string };
  couponNumber: string;
  products: { productId: number; quantity: string }[];
  pickupDate: string;
  couponDate: string;
  work: string;
  notes: string;
}

export interface ReceiptIformationReponse {
  fasi: { title: string }[];
  location: { title: string }[];
  companies: { title: string }[];
  types: { title: string }[];
  brands: { title: string; id: string }[];
  cities: { title: string; id: string }[];
}

export interface FashState {
  fasis: { title: string }[];
  location: { title: string }[];
  companies: { title: string }[];
  typos: { title: string }[];
  brands: { title: string; id: string }[];
  formValues: FormValues;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
}

export const initialState: FashState = {
  fasis: [],
  location: [],
  companies: [],
  typos: [],
  brands: [],
  status: "loading",
  error: null,
  formValues: {
    fash: { title: "", id: "" },
    location: { title: "", id: "" },
    provider: { title: "", id: "" },
    company: { title: "", id: "" },
    couponNumber: "",
    products: [{ productId: -1, quantity: "" }],
    pickupDate: "",
    couponDate: "",
    work: "",
    notes: "",
  },
};

export const fetchFash = createAsyncThunk(
  "fash/fetchFash",
  async (_, thunkAPI) => {
    const { request } = fasisApi.single.getReceiptInformation();
    return request()
      .then((response: ReceiptIformationReponse) => {
        return response;
      })

      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const addReceipt = createAsyncThunk(
  "fash/addReceipt",
  async (params: { receipt: any; navigate: any }, thunkAPI) => {
    const { request } = fasisApi.single.addReceipt(params.receipt);
    return request()
      .then((response: ReceiptIformationReponse) => {
        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η παραλαβή προστέθηκε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            params.navigate("/");
            // You can now use the `someOtherProp` here
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));

        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

const fashSlice = createSlice({
  name: "fasi",
  initialState: initialState,
  reducers: {
    updateFormValue: (
      state,
      action: PayloadAction<{ field: keyof FormValues; value: any }>
    ) => {
      state.formValues![action.payload.field] = action.payload.value;
    },
    setFormValues: (state, action: PayloadAction<FormValues>) => {
      state.formValues = { ...state.formValues, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFash.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      fetchFash.fulfilled,
      (state, action: PayloadAction<ReceiptIformationReponse>) => {
        state.status = "succeeded";
        state.fasis = action.payload.fasi;
        state.companies = action.payload.companies;
        state.location = action.payload.location;
        state.typos = action.payload.types;
        state.brands = action.payload.brands;
      }
    );
    builder.addCase(fetchFash.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error;
    });

    builder.addCase(
      addReceipt.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        state.formValues = initialState.formValues;
      }
    );
    builder.addCase(addReceipt.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error;
    });
  },
});

const { actions, reducer } = fashSlice;
export const { setFormValues, updateFormValue } = actions;
export default reducer;
