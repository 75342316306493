import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Logout = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "21"}
      height={height ? height : "19"}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.97949 0.959473C2.77035 0.959473 0.979492 2.75034 0.979492 4.95947V14.9487C0.979492 17.1579 2.77035 18.9487 4.97949 18.9487H10.1134C11.4467 18.9487 12.5275 17.8679 12.5275 16.5346C12.5275 15.9823 12.0798 15.5346 11.5275 15.5346C10.9752 15.5346 10.5275 15.9823 10.5275 16.5346C10.5275 16.7633 10.3421 16.9487 10.1134 16.9487H4.97949C3.87492 16.9487 2.97949 16.0533 2.97949 14.9487V4.95947C2.97949 3.8549 3.87492 2.95947 4.97949 2.95947H10.176C10.3701 2.95947 10.5275 3.11686 10.5275 3.31101C10.5275 3.8633 10.9752 4.31101 11.5275 4.31101C12.0798 4.31101 12.5275 3.8633 12.5275 3.31101C12.5275 2.01229 11.4747 0.959473 10.176 0.959473H4.97949ZM15.5045 4.76908C15.0713 5.11166 14.9978 5.74055 15.3404 6.17374L17.6035 9.03534H7.98629C7.43401 9.03534 6.98629 9.48306 6.98629 10.0353C6.98629 10.5876 7.43401 11.0353 7.98629 11.0353H17.5105L15.328 13.9129C14.9943 14.353 15.0804 14.9803 15.5205 15.314C15.9605 15.6477 16.5878 15.5616 16.9215 15.1215L20.3918 10.5459C20.6674 10.1826 20.6623 9.67891 20.3794 9.32127L16.9091 4.93314C16.5666 4.49995 15.9377 4.4265 15.5045 4.76908Z"
        fill={colors[color || "carinalTeal"]}
      />
    </svg>
  );
};

export default Logout;
