import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { GenericModalProperties } from "../modalSlice/types";
import { closeGenericModal, openGenericModal } from "../modalSlice/modalSlice";
import { DashboardPruducts, GetWareHouseAPIResponse } from "./types";
import dashboardProductsAPI from "../../providers/dashboardProductsAPI";

export const initialState: DashboardPruducts = {
  crewInventoryQuantity: null,
  crewInventoryQuantityStatus: "loading",
  crewInventoryQuantityError: null,
  warehouseInventoryQuantity: null,
  warehouseInventoryQuantityStatus: "loading",
  warehouseInventoryQuantityError: null,
  tableOffset: 0,
  tabOption: 0,
  tablePage: 0,
};

export const getQuantityCrew = createAsyncThunk(
  "dashboardProductsSlice/getQuantityCrew",
  async (params: any, thunkAPI) => {
    const { request } = dashboardProductsAPI.single.getQuantityCrew(params);
    return request()
      .then((response: GetWareHouseAPIResponse) => {
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const getQuantityWarehouses = createAsyncThunk(
  "dashboardProductsSlice/getQuantityWarehouses",
  async (params: any, thunkAPI) => {
    const { request } =
      dashboardProductsAPI.single.getQuantityWarehouses(params);
    return request()
      .then((response: GetWareHouseAPIResponse) => {
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

const dashboardProductsSlice = createSlice({
  name: "dashboardProductsSlice",
  initialState: initialState,
  reducers: {
    setTabOption(state, action) {
      state.tabOption = action.payload;
    },

    setTableOffset(state, action) {
      state.tableOffset = action.payload;
    },

    setTablePage(state, action) {
      state.tablePage = action.payload;
    },
    resetTable(state) {
      state.crewInventoryQuantity = null;
      state.warehouseInventoryQuantity = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getQuantityCrew.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.crewInventoryQuantity = null;
        state.crewInventoryQuantity = action.payload;
        state.crewInventoryQuantityStatus = "succeeded";
      }
    );
    builder.addCase(getQuantityCrew.rejected, (state, action) => {
      state.crewInventoryQuantityStatus = "failed";
      state.crewInventoryQuantityError = action.error;
    });
    builder.addCase(
      getQuantityWarehouses.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.warehouseInventoryQuantity = null;
        state.warehouseInventoryQuantity = action.payload;
        state.warehouseInventoryQuantityStatus = "succeeded";
      }
    );
    builder.addCase(getQuantityWarehouses.rejected, (state, action) => {
      state.warehouseInventoryQuantityStatus = "failed";
      state.warehouseInventoryQuantityError = action.error;
    });
  },
});

export const { resetTable, setTableOffset, setTablePage, setTabOption } =
  dashboardProductsSlice.actions;

const { reducer } = dashboardProductsSlice;
export default reducer;
