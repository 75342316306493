import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Location = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={width ? width : "23"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_71_86)">
        <mask id="path-1-inside-1_71_86" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3245 14.5646C18.5999 13.0953 19.3718 11.1773 19.3718 9.07902C19.3718 4.4552 15.6234 0.706848 10.9996 0.706848C6.37579 0.706848 2.62744 4.4552 2.62744 9.07902C2.62744 11.1617 3.38794 13.0668 4.64638 14.5317C4.6464 14.5318 4.6464 14.5318 4.64638 14.5318C4.64636 14.5319 4.64636 14.5319 4.64638 14.5319L10.2094 21.6688C10.6098 22.1825 11.3865 22.1825 11.7869 21.6688L17.3245 14.5646Z"
          />
        </mask>
        <path
          d="M17.3245 14.5646L15.935 13.3584L15.9031 13.3951L15.8733 13.4334L17.3245 14.5646ZM10.2094 21.6688L8.75824 22.8L10.2094 21.6688ZM11.7869 21.6688L13.2381 22.8L11.7869 21.6688ZM4.64638 14.5319L3.19517 15.6631L4.64638 14.5319ZM4.64638 14.5317L3.25067 15.7307L4.64638 14.5317ZM17.5318 9.07902C17.5318 10.7175 16.9309 12.2111 15.935 13.3584L18.714 15.7708C20.2688 13.9796 21.2118 11.6371 21.2118 9.07902H17.5318ZM10.9996 2.54685C14.6072 2.54685 17.5318 5.4714 17.5318 9.07902H21.2118C21.2118 3.439 16.6396 -1.13315 10.9996 -1.13315V2.54685ZM4.46744 9.07902C4.46744 5.4714 7.392 2.54685 10.9996 2.54685V-1.13315C5.35959 -1.13315 0.787441 3.439 0.787441 9.07902H4.46744ZM6.0421 13.3328C5.05945 12.1889 4.46744 10.7054 4.46744 9.07902H0.787441C0.787441 11.6181 1.71642 13.9448 3.25067 15.7307L6.0421 13.3328ZM11.6607 20.5376L6.09759 13.4007L3.19517 15.6631L8.75824 22.8L11.6607 20.5376ZM15.8733 13.4334L10.3356 20.5376L13.2381 22.8L18.7757 15.6958L15.8733 13.4334ZM8.75824 22.8C9.89528 24.2587 12.101 24.2587 13.2381 22.8L10.3356 20.5376C10.6719 20.1062 11.3244 20.1062 11.6607 20.5376L8.75824 22.8ZM3.19956 13.395C2.68214 14.0536 2.66999 14.9893 3.19517 15.6631L6.09759 13.4007C6.62274 14.0744 6.61059 15.0101 6.09321 15.6686L3.19956 13.395ZM3.25067 15.7307C2.67795 15.0641 2.65687 14.0857 3.19956 13.395L6.09321 15.6686C6.63593 14.9779 6.61486 13.9995 6.0421 13.3328L3.25067 15.7307Z"
          fill={colors[color || "black"]}
          mask="url(#path-1-inside-1_71_86)"
        />
        <path
          d="M11.7044 6.11001C11.8316 5.57436 11.4968 5.02375 10.9463 5.03099C10.3529 5.03879 9.76425 5.17704 9.22363 5.44095C8.38195 5.85181 7.70877 6.5418 7.31879 7.39336C6.92881 8.24491 6.84616 9.20534 7.08492 10.111C7.32369 11.0167 7.86909 11.8115 8.6282 12.3601C9.38731 12.9088 10.3132 13.1772 11.248 13.1197C12.1828 13.0622 13.0688 12.6824 13.755 12.0449C14.4412 11.4074 14.8851 10.5517 15.0111 9.62357C15.092 9.02744 15.0385 8.42513 14.8597 7.85922C14.6939 7.33427 14.066 7.1892 13.5973 7.47804C13.1286 7.76687 13.0126 8.39186 13.0496 8.94116C13.0588 9.07852 13.0543 9.21727 13.0355 9.35538C12.9716 9.82642 12.7463 10.2607 12.398 10.5843C12.0498 10.9078 11.6001 11.1006 11.1256 11.1298C10.6512 11.159 10.1813 11.0227 9.796 10.7443C9.41072 10.4658 9.13391 10.0624 9.01273 9.60277C8.89155 9.14312 8.9335 8.65566 9.13143 8.22347C9.32935 7.79128 9.67101 7.44109 10.0982 7.23256C10.2234 7.17142 10.3538 7.12357 10.4871 7.08924C11.0202 6.95194 11.5772 6.64566 11.7044 6.11001Z"
          fill={colors[color || "black"]}
        />
      </g>
      <defs>
        <clipPath id="clip0_71_86">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0 0.270996)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Location;
