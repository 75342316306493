import React, { useEffect, useState } from "react";
import { TextField } from "../../../components";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import SelectComponent from "../../../components/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormData,
  selectTransferProductsToCrew,
} from "../../../slices/transferProductsToCrewSlice/selectors";
import {
  FormValues,
  getReceiptTransferProducts,
  updateFormValueCrew,
} from "../../../slices/transferProductsToCrewSlice/transferProductsToCrewSlice";
import { AppDispatch } from "../../../slices/store";

interface SearchBarProps {
  watch: UseFormWatch<FormValues>;
  control: Control<FormValues, any>;
  fields: FieldArrayWithId<FormValues, "products", "id">[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, "products">;
  setValue: UseFormSetValue<FormValues>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  watch,
  control,
  fields,
  remove,
  append,
  setValue,
}) => {
  const [previewsCompany, setPreviewsCompany] = useState<
    | {
        title: string;
        id: string;
      }
    | undefined
  >({ title: "", id: "" });

  const { fasis, location, companies, constructor_crew } = useSelector(
    selectTransferProductsToCrew
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setPreviewsCompany(watch("from"));
  }, [watch("from"), location]);
  const formData = useSelector(getFormData);
  useEffect(() => {
    if (formData) {
      setValue("fash", formData.fash);
      setValue("provider", formData.provider);
      setValue("from", formData.from);
      setValue("to_construction_crew", formData.to_construction_crew);
      setValue("date", formData.date);
      setValue("products", formData.products);
    }
  }, []);

  useEffect(() => {
    if (watch("fash") && watch("fash")?.title === "") return;
    if (
      watch("fash") === undefined ||
      watch("provider") === undefined ||
      watch("from") === undefined
    )
      return;
    const params = {
      fash: watch("fash")?.title,
      providerId: watch("provider")?.id,
      warehouse_id: watch("from")?.id,
    };
    dispatch(getReceiptTransferProducts(params));
  }, [watch, dispatch]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Controller
        control={control}
        name="provider"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Διάλεξε Πάροχο"
            placeholder="= Επέλεξε -"
            {...field}
            items={companies}
            value={watch("provider")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(
                updateFormValueCrew({
                  field: "provider",
                  value: value,
                })
              );
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="fash"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"180px"}
            required
            label="Διάλεξε Φάση"
            placeholder="= Επέλεξε -"
            {...field}
            items={fasis}
            value={watch("fash")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(
                updateFormValueCrew({
                  field: "fash",
                  value: value,
                })
              );
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="from"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Απο / Αποθήκη"
            placeholder="= Επέλεξε -"
            {...field}
            items={location}
            value={watch("from")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(
                updateFormValueCrew({
                  field: "from",
                  value: value,
                })
              );
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="to_construction_crew"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Προς / Συνεργείο"
            placeholder="= Επέλεξε -"
            {...field}
            items={constructor_crew}
            value={watch("to_construction_crew")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(
                updateFormValueCrew({
                  field: "to_construction_crew",
                  value: value,
                })
              );
            }}
          />
        )}
      />
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <TextField
            formToday
            maxwidth={200}
            label={"Ημερομηνία"}
            type="date"
            required
            placeholder="day"
            {...field}
            onChange={(value) => {
              field.onChange(value);
              dispatch(
                updateFormValueCrew({
                  field: "date",
                  value: value,
                })
              );
            }}
          />
        )}
      />
    </div>
  );
};

export default SearchBar;
