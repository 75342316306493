import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const IconListNumbers = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6H21"
        stroke={colors[color || "spaceShade"]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12H21"
        stroke={colors[color || "spaceShade"]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 18H21"
        stroke={colors[color || "spaceShade"]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.64941 8H2.74414V5.52148L2.75293 5.11426L2.76758 4.66895C2.61719 4.81934 2.5127 4.91797 2.4541 4.96484L1.96191 5.36035L1.52539 4.81543L2.90527 3.7168H3.64941V8Z"
        fill={colors[color || "spaceShade"]}
      />
      <path
        d="M4.40527 14H1.41113V13.3701L2.48633 12.2832C2.80469 11.957 3.0127 11.7314 3.11035 11.6064C3.20801 11.4795 3.27832 11.3623 3.32129 11.2549C3.36426 11.1475 3.38574 11.0361 3.38574 10.9209C3.38574 10.749 3.33789 10.6211 3.24219 10.5371C3.14844 10.4531 3.02246 10.4111 2.86426 10.4111C2.69824 10.4111 2.53711 10.4492 2.38086 10.5254C2.22461 10.6016 2.06152 10.71 1.8916 10.8506L1.39941 10.2676C1.61035 10.0879 1.78516 9.96094 1.92383 9.88672C2.0625 9.8125 2.21387 9.75586 2.37793 9.7168C2.54199 9.67578 2.72559 9.65527 2.92871 9.65527C3.19629 9.65527 3.43262 9.7041 3.6377 9.80176C3.84277 9.89941 4.00195 10.0361 4.11523 10.2119C4.22852 10.3877 4.28516 10.5889 4.28516 10.8154C4.28516 11.0127 4.25 11.1982 4.17969 11.3721C4.11133 11.5439 4.00391 11.7207 3.85742 11.9023C3.71289 12.084 3.45703 12.3428 3.08984 12.6787L2.53906 13.1973V13.2383H4.40527V14Z"
        fill={colors[color || "spaceShade"]}
      />
      <path
        d="M4.23828 16.6748C4.23828 16.9424 4.15723 17.1699 3.99512 17.3574C3.83301 17.5449 3.60547 17.6738 3.3125 17.7441V17.7617C3.6582 17.8047 3.91992 17.9102 4.09766 18.0781C4.27539 18.2441 4.36426 18.4688 4.36426 18.752C4.36426 19.1641 4.21484 19.4854 3.91602 19.7158C3.61719 19.9443 3.19043 20.0586 2.63574 20.0586C2.1709 20.0586 1.75879 19.9814 1.39941 19.8271V19.0566C1.56543 19.1406 1.74805 19.209 1.94727 19.2617C2.14648 19.3145 2.34375 19.3408 2.53906 19.3408C2.83789 19.3408 3.05859 19.29 3.20117 19.1885C3.34375 19.0869 3.41504 18.9238 3.41504 18.6992C3.41504 18.498 3.33301 18.3555 3.16895 18.2715C3.00488 18.1875 2.74316 18.1455 2.38379 18.1455H2.05859V17.4512H2.38965C2.72168 17.4512 2.96387 17.4082 3.11621 17.3223C3.27051 17.2344 3.34766 17.085 3.34766 16.874C3.34766 16.5498 3.14453 16.3877 2.73828 16.3877C2.59766 16.3877 2.4541 16.4111 2.30762 16.458C2.16309 16.5049 2.00195 16.5859 1.82422 16.7012L1.40527 16.0771C1.7959 15.7959 2.26172 15.6553 2.80273 15.6553C3.24609 15.6553 3.5957 15.7451 3.85156 15.9248C4.10938 16.1045 4.23828 16.3545 4.23828 16.6748Z"
        fill={colors[color || "spaceShade"]}
      />
    </svg>
  );
};

export default IconListNumbers;
