import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Add = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7632 11.5356C2.76566 11.4735 2.76882 11.4114 2.77268 11.3495C2.86959 9.79398 3.40628 8.28972 4.33093 7.01696C5.46741 5.45263 7.11762 4.33758 8.99301 3.86679C10.8684 3.396 12.8496 3.59944 14.5902 4.44154C15.844 5.04814 16.9155 5.95834 17.7138 7.07884L14.3184 7.07884C13.7971 7.07884 13.3737 7.49986 13.3708 8.02115C13.3677 8.55175 13.8009 8.98143 14.3315 8.97413L20.4252 8.89023L20.4848 2.79458C20.4899 2.27777 20.0749 1.85478 19.5581 1.84994C19.038 1.84507 18.6138 2.26533 18.6138 2.78543L18.6138 5.34499C17.7216 4.30264 16.6181 3.44677 15.3648 2.8404C13.2487 1.81661 10.84 1.56927 8.55994 2.14164C6.2799 2.71401 4.27361 4.06966 2.89192 5.97153C1.78449 7.49587 1.13479 9.29335 1.00306 11.1544C0.995744 11.2578 0.990024 11.3614 0.985914 11.4652C0.983383 11.529 0.981462 11.593 0.980154 11.6569C0.970109 12.148 1.39866 12.5186 1.88868 12.4851C2.32539 12.4552 2.66283 12.1143 2.74486 11.693C2.75488 11.6416 2.76108 11.589 2.7632 11.5356Z"
        fill={colors[color || "onyxDark"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9692 11.8035C18.9668 11.8656 18.9636 11.9276 18.9597 11.9896C18.8628 13.5451 18.3261 15.0493 17.4015 16.3221C16.265 17.8864 14.6148 19.0015 12.7394 19.4723C10.864 19.943 8.88282 19.7396 7.14225 18.8975C5.88842 18.2909 4.81695 17.3807 4.01862 16.2602L7.414 16.2602C7.93529 16.2602 8.35871 15.8392 8.36167 15.3179C8.36468 14.7873 7.9315 14.3576 7.40095 14.3649L1.30719 14.4488L1.24758 20.5445C1.24253 21.0613 1.6575 21.4843 2.1743 21.4891C2.69438 21.494 3.11859 21.0737 3.11859 20.5536L3.11859 17.9941C4.01083 19.0364 5.11429 19.8923 6.36761 20.4986C8.48375 21.5224 10.8924 21.7698 13.1725 21.1974C15.4525 20.625 17.4588 19.2694 18.8405 17.3675C19.9479 15.8432 20.5976 14.0457 20.7294 12.1846C20.7367 12.0812 20.7424 11.9776 20.7465 11.8739C20.749 11.81 20.751 11.7461 20.7523 11.6821C20.7623 11.191 20.3338 10.8204 19.8437 10.854C19.407 10.8838 19.0696 11.2248 18.9876 11.646C18.9775 11.6974 18.9713 11.7501 18.9692 11.8035Z"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default Add;
