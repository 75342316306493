import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFash,
  updateFormValue,
} from "../../slices/receiptSlice/receiptSlice";
import { AppDispatch } from "../../slices/store";
import { useForm, FormProvider } from "react-hook-form";
import TableComponent from "./Table";
import SearchBar from "./SearchBar/SearchBar";
import { getProducts, getStatus } from "../../slices/productsSlice/selectors";
import {
  getProductsByFash,
  Product,
} from "../../slices/productsSlice/productsSlice";
import { TextWorkSans } from "../../components/common.style";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getDataStatus,
  getFormData,
  selectFashDetails,
} from "../../slices/receiptSlice/selectors";
import { getDashboardDetails } from "../../slices/dashboardSlice/dashboardSlice";
import { selectDashboardProducts } from "../../slices/dashboardProductsSlice/selectors";

const ProductsPage: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // Initialize useForm and methods
  const methods = useForm<any>({
    defaultValues: {
      fash: undefined,
      company: undefined,
      iventoryType: undefined,
    },
  });

  const { watch, handleSubmit, setValue } = methods;

  const status = useSelector(getStatus);
  const dataStatus = useSelector(getDataStatus);

  useEffect(() => {
    dispatch(fetchFash());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboardDetails());
  }, [dispatch]);

  // const onSubmit = (data: any) => {
  //   history("/addReceipt/preview");
  // };

  // const onError = () => {
  //   console.log("ERROR SUBMITTED");
  // };

  const [previewPage, setPreviewPage] = useState<boolean>(false);
  const { tabOption, warehouseInventoryQuantity, crewInventoryQuantity } =
    useSelector(selectDashboardProducts);

  const [newProducts, setProducts] = useState<Product[]>([]);

  return (
    <FormProvider {...methods}>
      <form
        //onSubmit={handleSubmit(onSubmit, onError)}
        style={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          flexDirection: "column",
          padding: "40px",
          width: "100%",
          maxWidth: "1300px",
          marginTop: "40px",
          marginBottom: "40px",
          backgroundColor: "#F5F5F5",
          borderRadius: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginTop: "25px",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TextWorkSans fontSize={25} color={"secondary"}>
            ΠΡΟΙΟΝΤΑ
          </TextWorkSans>
        </div>

        {dataStatus === "loading" ? (
          <CircularProgress color={"primary"} size={50} />
        ) : (
          <>
            <SearchBar />
            <TableComponent tabOption={tabOption} />
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default ProductsPage;
