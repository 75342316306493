import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Phone = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6349 14.8536C17.2744 14.6185 15.4995 13.4794 15.1634 13.3116C14.8277 13.1441 14.5796 13.0515 14.2813 13.4052C13.9827 13.7588 13.1517 14.5369 12.8995 14.7665C12.6472 14.9963 12.4207 15.003 12.06 14.7679C11.6993 14.5329 10.5131 13.9886 9.20072 12.5193C8.17946 11.3757 7.55467 10.0359 7.37506 9.62777C7.19587 9.21931 7.43812 9.05747 7.63176 8.86641C8.10433 8.39948 8.58029 7.90772 8.73679 7.66717C8.8935 7.42645 8.85504 7.19578 8.78108 6.99351C8.70748 6.7913 8.15711 4.82177 7.92577 4.01951C7.70001 3.2387 7.37352 3.30348 7.13996 3.26503C6.91839 3.22852 6.66358 3.1972 6.40851 3.16814C6.15363 3.1391 5.72837 3.18748 5.33408 3.5306C4.93999 3.87356 3.84675 4.68609 3.63229 6.56848C3.41783 8.45087 4.58102 10.4255 4.7431 10.7025C4.90518 10.9795 6.97074 15.1278 10.6183 17.2212C11.4858 17.7196 12.1712 18.0358 12.7067 18.2754C13.5898 18.6708 14.4279 18.7247 15.0982 18.7014C15.8456 18.6752 17.466 18.0338 17.8868 17.177C18.3072 16.3199 18.3944 15.5543 18.3168 15.3837C18.2396 15.2133 17.9954 15.0887 17.6349 14.8536Z"
        stroke="#2B2E43"
        strokeWidth="1.84"
        strokeLinecap="round"
        fill={colors[color || "candyRed"]}
      />
    </svg>
  );
};

export default Phone;
