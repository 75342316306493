// MUIProvider.tsx
import React, { FC } from "react";
import { ThemeProvider as MuiThemeProvider } from "@emotion/react";
import theme from "./theme"; // Import your theme

interface MUIProviderProps {
  children: React.ReactNode;
}

const MUIProvider: FC<MUIProviderProps> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default MUIProvider;
