// components/ProtectedRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { AvailableRoles, useAuthService } from "../components/AuthService";

interface ProtectedRouteProps {
  requiredRole: AvailableRoles;
}

interface CustomJwtPayload {
  role: string;
  // Add other properties as needed
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredRole }) => {
  const auth = useAuthService();

  if (!auth.isLoggedIn()) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login" replace />;
  }
  const token = localStorage.getItem("jwt");

  // Decode the JWT token to check for the user's role
  const decodedToken = jwtDecode<CustomJwtPayload>(token!);

  if (decodedToken.role !== requiredRole) {
    // Redirect to the correct page based on the user's role
    if (decodedToken.role === AvailableRoles.ADMIN) {
      return <Navigate to="/" replace />;
    } else if (decodedToken.role === AvailableRoles.USER) {
      return <Navigate to="/myBuildings" replace />;
    } else {
      // Default redirect if no specific role matches
      return <Navigate to="/nothing" replace />;
    }
  }

  // If the role matches, allow access to the requested route
  return <Outlet />;
};

export default ProtectedRoute;
