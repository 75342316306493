import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useAuthService } from "../../components/AuthService";
import {
  LoginFormContainer,
  HeaderContainer,
  Divider,
  InputContainer,
} from "./Login.style";
import Button from "../../components/Button/Button";
import { TextWorkSans } from "../../components/common.style";
import { TextField } from "@mui/material";
import ErrorBox from "../../components/errorAlert/ErrorAlert";

interface LoginForm {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const { control, handleSubmit } = useForm<LoginForm>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const authService = useAuthService();

  const onSubmit = (data: any) => {
    authService.handleLogin(data);
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };

  return (
    <LoginFormContainer onSubmit={handleSubmit(onSubmit, onError)}>
      <HeaderContainer>
        <TextWorkSans fontSize={24} color="primary">
          ΣΥΝΔΕΣΗ
        </TextWorkSans>
        <Divider />
      </HeaderContainer>

      <InputContainer>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              required
              label="Όνομα Χρήστη"
              id="standard-required"
              variant="filled"
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              required
              id="filled-password-input"
              label="Κωδικός Πρόσβασης"
              type="password"
              autoComplete="current-password"
              variant="filled"
              {...field}
            />
          )}
        />
      </InputContainer>

      <Button
        padding={0}
        size="medium"
        backgroundColor="primary"
        color="white"
        type="submit"
        fontSize={12}
      >
        ΣΥΝΔΕΣΗ
      </Button>
    </LoginFormContainer>
  );
};

export default Login;
