import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Icon = ({ width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "45"}
      height={height ? height : "34"}
      fill="none"
      viewBox="0 0 45 34"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="21.8438"
        r="10.4182"
        cy="18.2032"
        strokeWidth="8.95078"
        stroke={colors.carinalTeal}
      />
      <circle
        cx="32.2776"
        r="8.43846"
        cy="12.8392"
        strokeWidth="6.99279"
        stroke={colors.carinalTeal}
      />
      <circle
        cx="5.7387"
        r="3.54557"
        cy="12.8393"
        strokeWidth="3.63625"
        stroke={colors.candyRed}
      />
    </svg>
  );
};

export default Icon;
