import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Play = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "30"}
      height={height ? height : "30"}
      fill="none"
      viewBox="0 0 65 71"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58 27.995c5.667 3.272 5.667 11.451 0 14.723L16.166 66.87c-5.667 3.271-12.75-.818-12.75-7.361V11.203c0-6.543 7.083-10.633 12.75-7.36L58 27.994Z"
        stroke={colors[color || "spacePure"]}
        strokeWidth={5}
      />
    </svg>
  );
};

export default Play;
