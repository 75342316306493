import React, { useEffect, useState } from "react";
import { TextField } from "../../../components";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import SelectComponent from "../../../components/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormData,
  selectTransferDetails,
} from "../../../slices/receiptTransferSlice/selectors";
import {
  FormValues,
  updateFormValue,
} from "../../../slices/receiptTransferSlice/receiptTransferSlice";

interface SearchBarProps {
  watch: UseFormWatch<FormValues>;
  control: Control<FormValues, any>;
  fields: FieldArrayWithId<FormValues, "products", "id">[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, "products">;
  setValue: UseFormSetValue<FormValues>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  watch,
  control,
  fields,
  remove,
  append,
  setValue,
}) => {
  const [previewsCompany, setPreviewsCompany] = useState<
    | {
        title: string;
        id: string;
      }
    | undefined
  >({ title: "", id: "" });

  const [fileterdCompanies, setFilteredCompanies] = useState<
    {
      title: string;
      id: string;
    }[]
  >([]);
  const { fasis, location, companies } = useSelector(selectTransferDetails);

  useEffect(() => {
    if (location.length > 0 && previewsCompany)
      setFilteredCompanies(
        location.filter((location) => location.id !== previewsCompany.id)
      );
  }, [previewsCompany]);

  useEffect(() => {
    setPreviewsCompany(watch("from"));
  }, [watch("from"), location]);
  const dispatch = useDispatch();
  const formData = useSelector(getFormData);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Controller
        control={control}
        name="provider"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Διάλεξε Πάροχο"
            placeholder="= Επέλεξε -"
            {...field}
            items={companies}
            value={watch("provider")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "provider", value: value }));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="fash"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"180px"}
            required
            label="Διάλεξε Φάση"
            placeholder="= Επέλεξε -"
            {...field}
            items={fasis}
            value={watch("fash")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "fash", value: value }));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="from"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Απο / Αποθήκη"
            placeholder="= Επέλεξε -"
            {...field}
            items={location}
            value={watch("from")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "from", value: value }));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="to"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Προς / Αποθήκη"
            placeholder="= Επέλεξε -"
            {...field}
            items={
              fileterdCompanies.length === 0 ? companies : fileterdCompanies
            }
            value={watch("to")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "to", value: value }));
            }}
          />
        )}
      />
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <TextField
            formToday
            maxwidth={200}
            label={"Ημερομηνία"}
            type="date"
            required
            placeholder="day"
            {...field}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "date", value: value }));
            }}
          />
        )}
      />
    </div>
  );
};

export default SearchBar;
