import { RootState } from "../store";
import { createSelector } from "reselect";

// Selector to get multiple parts of FashState as an object
export const __REDUX_STATE_KEY__ = "dashboardProductsSlice";
export const getReduxStateSlice = (state: RootState) =>
  state[__REDUX_STATE_KEY__];

export const getTabOption = (state: RootState) => {
  return getReduxStateSlice(state).tabOption;
};

export const getCrewInventoryQuantity = (state: RootState) =>
  getReduxStateSlice(state).crewInventoryQuantity;
export const getCrewInventoryQuantityStatus = (state: RootState) =>
  getReduxStateSlice(state).crewInventoryQuantityStatus;

export const getWarehouseInventoryQuantity = (state: RootState) =>
  getReduxStateSlice(state).warehouseInventoryQuantity;
export const getWarehouseInventoryQuantityStatus = (state: RootState) =>
  getReduxStateSlice(state).warehouseInventoryQuantityStatus;

export const getTableOffset = (state: RootState) =>
  getReduxStateSlice(state).tableOffset;

export const getTablePage = (state: RootState) =>
  getReduxStateSlice(state).tablePage;

export const selectDashboardProducts = createSelector(
  getTabOption,
  getCrewInventoryQuantity,
  getCrewInventoryQuantityStatus,
  getWarehouseInventoryQuantity,
  getWarehouseInventoryQuantityStatus,
  getTableOffset,
  getTablePage,
  (
    tabOption,
    crewInventoryQuantity,
    crewInventoryQuantityStatus,
    warehouseInventoryQuantity,
    warehouseInventoryQuantityStatus,
    tableOffset,
    tablePage
  ) => {
    return {
      tabOption,
      crewInventoryQuantity,
      crewInventoryQuantityStatus,
      warehouseInventoryQuantity,
      warehouseInventoryQuantityStatus,
      tableOffset,
      tablePage,
    };
  }
);
