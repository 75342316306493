import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Menu = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77344 5.06429C2.77344 4.55619 3.18534 4.14429 3.69344 4.14429H19.6135C20.1216 4.14429 20.5335 4.55619 20.5335 5.06429C20.5335 5.57239 20.1216 5.98429 19.6135 5.98429H3.69344C3.18534 5.98429 2.77344 5.57239 2.77344 5.06429Z"
        fill="#6E7EC3"
      />
      <path
        d="M2.77344 18.4979C2.77344 17.9898 3.18534 17.5779 3.69344 17.5779H19.6135C20.1216 17.5779 20.5335 17.9898 20.5335 18.4979C20.5335 19.006 20.1216 19.4179 19.6135 19.4179H3.69344C3.18534 19.4179 2.77344 19.006 2.77344 18.4979Z"
        fill="#6E7EC3"
      />
      <path
        d="M20.5332 11.7811C20.5332 11.273 20.1213 10.8611 19.6132 10.8611H3.69314C3.18503 10.8611 2.77314 11.273 2.77314 11.7811C2.77314 12.2892 3.18503 12.7011 3.69314 12.7011H19.6132C20.1213 12.7011 20.5332 12.2892 20.5332 11.7811Z"
        fill="#6E7EC3"
      />
    </svg>
  );
};

export default Menu;
