import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Facebook = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "11"}
      height={height ? height : "24"}
      viewBox="0 0 11 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.48532 23.3083H7.28604V11.6894H10.4883L10.9124 7.68602H7.28604L7.29126 5.6817C7.29126 4.63778 7.39043 4.07798 8.88845 4.07798H10.8902V0.0732422H7.68664C3.8385 0.0732422 2.48532 2.01624 2.48532 5.2824V7.68602H0.0869141V11.6908H2.48532V23.3083Z"
        fill={colors[color || "metallicPearlWhite"]}
      />
    </svg>
  );
};

export default Facebook;
