import axios, { Method, ResponseType } from "axios";
import { axiosPromiseResult } from "./common";

const GET = "get";
const POST = "post";
const PUT = "put";
const PATCH = "patch";
const DELETE = "delete";

export const METHODS = { GET, POST, PUT, DELETE, PATCH };

// Axios instance for backend API requests
export const nbsatAxiosBE = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api",
});

nbsatAxiosBE.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

let cancelTokenSource = axios.CancelToken.source();

export const request = (
  method: string,
  url: string,
  { params }: { params?: Record<string, any> } = {},
  withoutBase = true,
  headers: Record<string, string> = {},
  responseType: ResponseType = "json"
) => {
  headers = { ...headers };

  const config = {
    method: method as Method,
    url,
    cancelToken: cancelTokenSource.token,
    data: params,
    params: method === METHODS.GET ? params : undefined,
    headers,
    responseType,
  };

  const axiosInstance = withoutBase
    ? nbsatAxiosBE
    : axios.create({ baseURL: process.env.REACT_APP_FRONTEND_URL });
  return {
    request: () => axiosPromiseResult(axiosInstance.request(config)),
    cancelTokenSource,
  };
};

export const cancelPreviousRequests = () => {
  cancelTokenSource.cancel("Operation cancelled due to new request.");
  cancelTokenSource = axios.CancelToken.source();
};

export const setAxiosToken = (token: string): void => {
  const hasToken = token !== "";
  nbsatAxiosBE.defaults.headers.common.Authorization = hasToken
    ? `Bearer ${token}`
    : "";
};

export const deleteAxiosToken = (): void => {
  delete nbsatAxiosBE.defaults.headers.common["Authorization"];
};
