import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Cancel = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      fill="none"
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 18.9941C15.5492 18.9941 19.2371 15.3062 19.2371 10.757C19.2371 6.20771 15.5492 2.51981 11 2.51981C6.45075 2.51981 2.76285 6.20771 2.76285 10.757C2.76285 15.3062 6.45075 18.9941 11 18.9941ZM11 20.8341C16.5655 20.8341 21.0771 16.3224 21.0771 10.757C21.0771 5.1915 16.5655 0.67981 11 0.67981C5.43454 0.67981 0.922852 5.1915 0.922852 10.757C0.922852 16.3224 5.43454 20.8341 11 20.8341ZM15.0087 6.7479C15.3211 7.06031 15.3211 7.56684 15.0087 7.87927L12.1311 10.757L15.0086 13.6346C15.321 13.947 15.321 14.4536 15.0085 14.766C14.6961 15.0784 14.1896 15.0784 13.8772 14.7659L10.9998 11.8884L8.12234 14.7659C7.80993 15.0784 7.3034 15.0784 6.99097 14.766C6.67854 14.4536 6.67853 13.947 6.99094 13.6346L9.86841 10.757L6.99082 7.87927C6.67841 7.56684 6.67842 7.06031 6.99085 6.7479C7.30328 6.43549 7.80981 6.4355 8.12222 6.74793L10.9998 9.6256L13.8773 6.74793C14.1897 6.4355 14.6962 6.43549 15.0087 6.7479Z"
        fill={colors[color || "white"]}
      />
    </svg>
  );
};

export default Cancel;
