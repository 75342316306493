import styled from "@emotion/styled";
import theme from "../../../../theme/theme";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 3px solid ${theme.colors.primary};
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    border-color: ${theme.colors.hoverColor};
    transform: scale(1.05);
  }
`;
