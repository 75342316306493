import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Notice = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      fill="none"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="11.0001"
        cy="11.0829"
        r="9.10203"
        stroke={colors[color || "carinalTeal"]}
        strokeWidth="1.84"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1876 12.2992C10.2 12.7241 10.5481 13.0621 10.9732 13.0621C11.3977 13.0621 11.7454 12.7251 11.7587 12.3008L11.9164 7.26635C11.933 6.73684 11.5082 6.29858 10.9784 6.29858C10.4494 6.29858 10.025 6.73562 10.0404 7.26439L10.1876 12.2992ZM11.4667 15.5111C11.3367 15.6498 11.172 15.7191 10.9727 15.7191C10.782 15.7191 10.6174 15.6498 10.4787 15.5111C10.3487 15.3725 10.2837 15.2121 10.2837 15.0301C10.2837 14.8308 10.3487 14.6661 10.4787 14.5361C10.6174 14.3975 10.782 14.3281 10.9727 14.3281C11.172 14.3281 11.3367 14.3975 11.4667 14.5361C11.6054 14.6661 11.6747 14.8308 11.6747 15.0301C11.6747 15.2121 11.6054 15.3725 11.4667 15.5111Z"
        fill={colors[color || "carinalTeal"]}
      />
    </svg>
  );
};

export default Notice;
