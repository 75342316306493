import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  genericModal: undefined,
  loginModal: false,
  modalButtonLoader: false,
  loader: false,
  data: null,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openGenericModal(state, action) {
      state.genericModal = action.payload;
    },
    closeGenericModal(state) {
      state.genericModal = undefined;
    },
    enableLoaderModal(state) {
      state.loader = true;
    },
    disableLoaderModal(state) {
      state.loader = false;
    },
    openLoginModal(state, action) {
      state.data = action.payload;
      state.loginModal = true;
    },
    closeLoginModal(state) {
      state.loginModal = false;
    },
    resetLoginModal(state) {
      return initialState;
    },
    enableModalButtonLoader(state) {
      state.modalButtonLoader = true;
    },
    disableModalButtonLoader(state) {
      state.modalButtonLoader = false;
    },
  },
});

export const {
  openGenericModal,
  closeGenericModal,
  enableLoaderModal,
  disableLoaderModal,
  openLoginModal,
  closeLoginModal,
  resetLoginModal,
  enableModalButtonLoader,
  disableModalButtonLoader,
} = modalsSlice.actions;

export default modalsSlice.reducer;
