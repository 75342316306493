import styled from "@emotion/styled";

export const WrapperComponent = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding: 30px 50px 0px 50px;
  gap: 20px;
  border-radius: 0px 0px 12px 12px;
`;
