import { RootState } from "../store";

// Selector to get multiple parts of FashState as an object
export const __REDUX_STATE_KEY__ = "products";
export const getReduxStateSlice = (state: RootState) =>
  state[__REDUX_STATE_KEY__];
export const getProducts = (state: RootState) =>
  getReduxStateSlice(state).products;
export const getStatus = (state: RootState) => getReduxStateSlice(state).status;
export const getError = (state: RootState) => getReduxStateSlice(state).error;
