import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const QrCode = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      fill="none"
      viewBox="0 0 155 154"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.0625 0.435303H0.9375V6.5603H7.0625V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M13.1875 0.435303H7.0625V6.5603H13.1875V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M19.3125 0.435303H13.1875V6.5603H19.3125V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 0.435303H19.3125V6.5603H25.4375V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 0.435303H25.4375V6.5603H31.5625V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 0.435303H31.5625V6.5603H37.6875V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 0.435303H37.6875V6.5603H43.8125V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 0.435303H49.9375V6.5603H56.0625V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 0.435303H62.1875V6.5603H68.3125V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 0.435303H68.3125V6.5603H74.4375V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 0.435303H86.6875V6.5603H92.8125V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 0.435303H98.9375V6.5603H105.062V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 0.435303H111.188V6.5603H117.312V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 0.435303H117.312V6.5603H123.437V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 0.435303H123.437V6.5603H129.562V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 0.435303H129.562V6.5603H135.688V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 0.435303H135.688V6.5603H141.812V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 0.435303H141.812V6.5603H147.937V0.435303Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 0.435303H147.937V6.5603H154.062V0.435303Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 6.5603H0.9375V12.6853H7.0625V6.5603Z" fill="#DEDEFF" />
      <path
        d="M43.8125 6.5603H37.6875V12.6853H43.8125V6.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 6.5603H62.1875V12.6853H68.3125V6.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 6.5603H68.3125V12.6853H74.4375V6.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 6.5603H74.4375V12.6853H80.5625V6.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 6.5603H92.8125L92.8125 12.6853H98.9375V6.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 6.5603H111.188V12.6853H117.312V6.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 6.5603H147.937V12.6853H154.062V6.5603Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 12.6853H0.9375V18.8103H7.0625V12.6853Z" fill="#DEDEFF" />
      <path
        d="M19.3125 12.6853H13.1875V18.8103H19.3125V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 12.6853H19.3125V18.8103H25.4375V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 12.6853H25.4375V18.8103H31.5625V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 12.6853H37.6875V18.8103H43.8125V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 12.6853H49.9375V18.8103H56.0625V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 12.6853H74.4375V18.8103H80.5625V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 12.6853H80.5625V18.8103H86.6875V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 12.6853H86.6875V18.8103H92.8125L92.8125 12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 12.6853H92.8125L92.8125 18.8103H98.9375V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 12.6853H111.188V18.8103H117.312V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 12.6853H123.437V18.8103H129.562V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 12.6853H129.562V18.8103H135.688V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 12.6853H135.688V18.8103H141.812V12.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 12.6853H147.937V18.8103H154.062V12.6853Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 18.8103H0.9375V24.9353H7.0625V18.8103Z" fill="#DEDEFF" />
      <path
        d="M19.3125 18.8103H13.1875V24.9353H19.3125V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 18.8103H19.3125V24.9353H25.4375V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 18.8103H25.4375V24.9353H31.5625V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 18.8103H37.6875V24.9353H43.8125V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 18.8103H56.0625V24.9353H62.1875V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 18.8103H86.6875V24.9353H92.8125V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 18.8103H111.188V24.9353H117.312V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 18.8103H123.437V24.9353H129.562V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 18.8103H129.562V24.9353H135.688V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 18.8103H135.688V24.9353H141.812V18.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 18.8103H147.937V24.9353H154.062V18.8103Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 24.9353H0.9375V31.0603H7.0625V24.9353Z" fill="#DEDEFF" />
      <path
        d="M19.3125 24.9353H13.1875V31.0603H19.3125V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 24.9353H19.3125V31.0603H25.4375V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 24.9353H25.4375V31.0603H31.5625V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 24.9353H37.6875V31.0603H43.8125V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 24.9353H49.9375V31.0603H56.0625V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 24.9353H56.0625V31.0603H62.1875V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 24.9353H68.3125V31.0603H74.4375V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 24.9353H74.4375V31.0603H80.5625V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 24.9353H80.5625V31.0603H86.6875V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 24.9353H86.6875V31.0603H92.8125V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 24.9353H92.8125V31.0603H98.9375V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 24.9353H98.9375V31.0603H105.062V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 24.9353H111.188V31.0603H117.312V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 24.9353H123.437V31.0603H129.562V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 24.9353H129.562V31.0603H135.688V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 24.9353H135.688V31.0603H141.812V24.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 24.9353H147.937V31.0603H154.062V24.9353Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 31.0603H0.9375V37.1853H7.0625V31.0603Z" fill="#DEDEFF" />
      <path
        d="M43.8125 31.0603H37.6875V37.1853H43.8125V31.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 31.0603H68.3125V37.1853H74.4375V31.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 31.0603H86.6875V37.1853H92.8125V31.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 31.0603H98.9375V37.1853H105.062V31.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 31.0603H111.188V37.1853H117.312V31.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 31.0603H147.937V37.1853H154.062V31.0603Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 37.1853H0.9375V43.3103H7.0625V37.1853Z" fill="#DEDEFF" />
      <path
        d="M13.1875 37.1853H7.0625V43.3103H13.1875V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M19.3125 37.1853H13.1875V43.3103H19.3125V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 37.1853H19.3125V43.3103H25.4375V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 37.1853H25.4375V43.3103H31.5625V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 37.1853H31.5625V43.3103H37.6875V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 37.1853H37.6875V43.3103H43.8125V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 37.1853H49.9375V43.3103H56.0625V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 37.1853H62.1875V43.3103H68.3125V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 37.1853H74.4375V43.3103H80.5625V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 37.1853H86.6875V43.3103H92.8125V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 37.1853H98.9375V43.3103H105.062V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 37.1853H111.188V43.3103H117.312V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 37.1853H117.312V43.3103H123.437V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 37.1853H123.437V43.3103H129.562V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 37.1853H129.562V43.3103H135.688V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 37.1853H135.688V43.3103H141.812V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 37.1853H141.812V43.3103H147.937V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 37.1853H147.937V43.3103H154.062V37.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 43.3103H68.3125V49.4353H74.4375V43.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 43.3103H80.5625V49.4353H86.6875V43.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M13.1875 49.4353H7.0625V55.5603H13.1875V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 49.4353H25.4375V55.5603H31.5625V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 49.4353H37.6875V55.5603H43.8125V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 49.4353H68.3125V55.5603H74.4375V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 49.4353H74.4375V55.5603H80.5625V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 49.4353H86.6875V55.5603H92.8125V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 49.4353H92.8125V55.5603H98.9375V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 49.4353H105.062V55.5603H111.188V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 49.4353H123.437V55.5603H129.562V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 49.4353H135.688V55.5603H141.812V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 49.4353H147.937V55.5603H154.062V49.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M19.3125 55.5603H13.1875V61.6853H19.3125V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 55.5603H19.3125V61.6853H25.4375V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 55.5603H31.5625V61.6853H37.6875V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 55.5603H49.9375V61.6853H56.0625V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 55.5603H62.1875V61.6853H68.3125V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 55.5603H68.3125V61.6853H74.4375V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 55.5603H74.4375V61.6853H80.5625V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 55.5603H80.5625V61.6853H86.6875V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 55.5603H86.6875V61.6853H92.8125V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 55.5603H111.188V61.6853H117.312V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 55.5603H129.562V61.6853H135.688V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 55.5603H141.812V61.6853H147.937V55.5603Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 55.5603H147.937V61.6853H154.062V55.5603Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 61.6853H0.9375V67.8103H7.0625V61.6853Z" fill="#DEDEFF" />
      <path
        d="M13.1875 61.6853H7.0625V67.8103H13.1875V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 61.6853H19.3125V67.8103H25.4375V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 61.6853H37.6875V67.8103H43.8125V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M49.9375 61.6853H43.8125V67.8103H49.9375V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 61.6853H49.9375V67.8103H56.0625V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 61.6853H62.1875V67.8103H68.3125V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 61.6853H86.6875V67.8103H92.8125V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 61.6853H105.062V67.8103H111.188V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 61.6853H111.188V67.8103H117.312V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 61.6853H123.437V67.8103H129.562V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 61.6853H129.562V67.8103H135.688V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 61.6853H141.812V67.8103H147.937V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 61.6853H147.937V67.8103H154.062V61.6853Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 67.8103H19.3125V73.9353H25.4375V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 67.8103H31.5625V73.9353H37.6875V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M49.9375 67.8103H43.8125V73.9353H49.9375V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 67.8103H49.9375V73.9353H56.0625V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 67.8103H74.4375V73.9353H80.5625V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 67.8103H80.5625V73.9353H86.6875V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 67.8103H92.8125L92.8125 73.9353H98.9375V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 67.8103H105.062V73.9353H111.188V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 67.8103H111.188V73.9353H117.312V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 67.8103H123.437V73.9353H129.562V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 67.8103H129.562V73.9353H135.688V67.8103Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 67.8103H141.812V73.9353H147.937V67.8103Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 73.9353H0.9375V80.0603H7.0625V73.9353Z" fill="#DEDEFF" />
      <path
        d="M13.1875 73.9353H7.0625V80.0603H13.1875V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 73.9353H19.3125V80.0603H25.4375V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 73.9353H37.6875V80.0603H43.8125V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 73.9353H49.9375V80.0603H56.0625V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 73.9353H62.1875V80.0603H68.3125V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 73.9353H98.9375V80.0603H105.062V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 73.9353H105.062V80.0603H111.188V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 73.9353H111.188V80.0603H117.312V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 73.9353H117.312V80.0603H123.437V73.9353Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 73.9353H147.937V80.0603H154.062V73.9353Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 80.0603H0.9375V86.1853H7.0625V80.0603Z" fill="#DEDEFF" />
      <path
        d="M37.6875 80.0603H31.5625V86.1853H37.6875V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M49.9375 80.0603H43.8125V86.1853H49.9375V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 80.0603H49.9375V86.1853H56.0625V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 80.0603H56.0625V86.1853H62.1875V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 80.0603H68.3125V86.1853H74.4375V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 80.0603H86.6875V86.1853H92.8125V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 80.0603H105.062V86.1853H111.188V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 80.0603H117.312V86.1853H123.437V80.0603Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 80.0603H135.688V86.1853H141.812V80.0603Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 86.1853H0.9375V92.3103H7.0625V86.1853Z" fill="#DEDEFF" />
      <path
        d="M25.4375 86.1853H19.3125V92.3103H25.4375V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 86.1853H25.4375V92.3103H31.5625V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 86.1853H31.5625V92.3103H37.6875V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 86.1853H37.6875V92.3103H43.8125V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M49.9375 86.1853H43.8125V92.3103H49.9375V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 86.1853H56.0625V92.3103H62.1875V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 86.1853H74.4375V92.3103H80.5625V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 86.1853H111.188V92.3103H117.312V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 86.1853H117.312V92.3103H123.437V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 86.1853H123.437V92.3103H129.562V86.1853Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 86.1853H129.562V92.3103H135.688V86.1853Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 92.3103H0.9375V98.4353H7.0625V92.3103Z" fill="#DEDEFF" />
      <path
        d="M13.1875 92.3103L7.0625 92.3103V98.4353H13.1875V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M19.3125 92.3103L13.1875 92.3103V98.4353H19.3125V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 92.3103H19.3125V98.4353H25.4375V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 92.3103H25.4375V98.4353H31.5625V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 92.3103H31.5625V98.4353H37.6875V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M49.9375 92.3103H43.8125V98.4353H49.9375V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 92.3103H49.9375V98.4353H56.0625V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 92.3103L68.3125 92.3103V98.4353H74.4375V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 92.3103L80.5625 92.3103V98.4353H86.6875V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 92.3103H92.8125V98.4353H98.9375V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 92.3103L105.062 92.3103V98.4353H111.188V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 92.3103H111.188V98.4353H117.312V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 92.3103H117.312V98.4353H123.437V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 92.3103H129.562V98.4353H135.688V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 92.3103H147.937V98.4353H154.062V92.3103Z"
        fill="#DEDEFF"
      />
      <path
        d="M13.1875 98.4353H7.0625V104.56H13.1875V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M19.3125 98.4353H13.1875V104.56H19.3125V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 98.4353H31.5625V104.56H37.6875V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 98.4353H37.6875V104.56H43.8125V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 98.4353H49.9375V104.56H56.0625V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 98.4353H56.0625V104.56H62.1875V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 98.4353H62.1875V104.56H68.3125V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 98.4353H68.3125V104.56H74.4375V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 98.4353H80.5625V104.56H86.6875V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 98.4353H86.6875V104.56H92.8125L92.8125 98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 98.4353H98.9375V104.56H105.062V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 98.4353H105.062V104.56H111.188V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 98.4353H111.188V104.56H117.312V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 98.4353H117.312V104.56H123.437V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 98.4353H123.437V104.56H129.562V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 98.4353H129.562V104.56H135.688V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 98.4353H135.688V104.56H141.812V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 98.4353H141.812V104.56H147.937V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 98.4353H147.937V104.56H154.062V98.4353Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 104.56H62.1875V110.685H68.3125V104.56Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 104.56H68.3125V110.685H74.4375V104.56Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 104.56H74.4375V110.685H80.5625V104.56Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 104.56H80.5625V110.685H86.6875V104.56Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 104.56H92.8125L92.8125 110.685H98.9375V104.56Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 104.56H98.9375V110.685H105.062V104.56Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 104.56H123.437V110.685H129.562V104.56Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 104.56H147.937V110.685H154.062V104.56Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 110.685H0.9375V116.81H7.0625V110.685Z" fill="#DEDEFF" />
      <path
        d="M13.1875 110.685H7.0625V116.81H13.1875V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M19.3125 110.685H13.1875V116.81H19.3125V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 110.685H19.3125V116.81H25.4375V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 110.685H25.4375V116.81H31.5625V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 110.685H31.5625V116.81H37.6875V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 110.685H37.6875V116.81H43.8125V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 110.685H56.0625V116.81H62.1875V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 110.685H62.1875V116.81H68.3125V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 110.685H74.4375V116.81H80.5625V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 110.685H80.5625V116.81H86.6875V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 110.685H98.9375V116.81H105.062V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 110.685H111.188V116.81H117.312V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 110.685H123.437V116.81H129.562V110.685Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 110.685H147.937V116.81H154.062V110.685Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 116.81H0.9375V122.935H7.0625V116.81Z" fill="#DEDEFF" />
      <path
        d="M43.8125 116.81H37.6875V122.935H43.8125V116.81Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 116.81H62.1875V122.935H68.3125V116.81Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 116.81H86.6875V122.935H92.8125V116.81Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 116.81H98.9375V122.935H105.062V116.81Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 116.81H123.437V122.935H129.562V116.81Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 116.81H129.562V122.935H135.688V116.81Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 116.81H135.688V122.935H141.812V116.81Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 116.81H147.937V122.935H154.062V116.81Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 122.935H0.9375V129.06H7.0625V122.935Z" fill="#DEDEFF" />
      <path
        d="M19.3125 122.935H13.1875V129.06H19.3125V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 122.935H19.3125V129.06H25.4375V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 122.935H25.4375V129.06H31.5625V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 122.935H37.6875V129.06H43.8125V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 122.935H49.9375V129.06H56.0625V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 122.935H62.1875V129.06H68.3125V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 122.935H98.9375V129.06H105.062V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 122.935H105.062V129.06H111.188V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 122.935H111.188V129.06H117.312V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 122.935H117.312V129.06H123.437V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 122.935H123.437V129.06H129.562V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M135.688 122.935H129.562V129.06H135.688V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 122.935H135.688V129.06H141.812V122.935Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 122.935H141.812V129.06H147.937V122.935Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 129.06H0.9375V135.185H7.0625V129.06Z" fill="#DEDEFF" />
      <path
        d="M19.3125 129.06H13.1875V135.185H19.3125V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 129.06H19.3125V135.185H25.4375V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 129.06H25.4375V135.185H31.5625V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 129.06H37.6875V135.185H43.8125V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 129.06H62.1875V135.185H68.3125V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 129.06H86.6875V135.185H92.8125V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 129.06H92.8125V135.185H98.9375V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 129.06H105.062V135.185H111.188V129.06Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 129.06H123.437V135.185H129.562V129.06Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 135.185H0.9375V141.31H7.0625V135.185Z" fill="#DEDEFF" />
      <path
        d="M19.3125 135.185H13.1875V141.31H19.3125V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 135.185H19.3125V141.31H25.4375V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 135.185H25.4375V141.31H31.5625V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 135.185H37.6875V141.31H43.8125V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 135.185H56.0625V141.31H62.1875V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 135.185H62.1875V141.31H68.3125V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 135.185H86.6875V141.31H92.8125V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 135.185H111.188V141.31H117.312V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 135.185H123.437V141.31H129.562V135.185Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 135.185H135.688V141.31H141.812V135.185Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 141.31H0.9375V147.435H7.0625V141.31Z" fill="#DEDEFF" />
      <path
        d="M43.8125 141.31H37.6875V147.435H43.8125V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M56.0625 141.31H49.9375V147.435H56.0625V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 141.31H56.0625V147.435H62.1875V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 141.31H62.1875V147.435H68.3125V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M80.5625 141.31H74.4375V147.435H80.5625V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 141.31H80.5625V147.435H86.6875V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 141.31H86.6875V147.435H92.8125V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M111.188 141.31H105.062V147.435H111.188V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 141.31H111.188V147.435H117.312V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M123.437 141.31H117.312V147.435H123.437V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 141.31H123.437V147.435H129.562V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M147.937 141.31H141.812V147.435H147.937V141.31Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 141.31H147.937V147.435H154.062V141.31Z"
        fill="#DEDEFF"
      />
      <path d="M7.0625 147.435H0.9375V153.56H7.0625V147.435Z" fill="#DEDEFF" />
      <path
        d="M13.1875 147.435H7.0625V153.56H13.1875V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M19.3125 147.435H13.1875V153.56H19.3125V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M25.4375 147.435H19.3125V153.56H25.4375V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M31.5625 147.435H25.4375V153.56H31.5625V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M37.6875 147.435H31.5625V153.56H37.6875V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M43.8125 147.435H37.6875V153.56H43.8125V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M62.1875 147.435H56.0625V153.56H62.1875V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M68.3125 147.435H62.1875V153.56H68.3125V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M74.4375 147.435H68.3125V153.56H74.4375V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M86.6875 147.435H80.5625V153.56H86.6875V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M92.8125 147.435H86.6875V153.56H92.8125V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M98.9375 147.435H92.8125V153.56H98.9375V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M105.062 147.435H98.9375V153.56H105.062V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M117.312 147.435H111.188V153.56H117.312V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M129.562 147.435H123.437V153.56H129.562V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M141.812 147.435H135.688V153.56H141.812V147.435Z"
        fill="#DEDEFF"
      />
      <path
        d="M154.062 147.435H147.937V153.56H154.062V147.435Z"
        fill="#DEDEFF"
      />
    </svg>
  );
};

export default QrCode;
