import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import SelectComponent from "../../../components/Select/Select";
import { selectFashDetails } from "../../../slices/receiptSlice/selectors";
import { useDispatch, useSelector } from "react-redux";
import { updateFormValue } from "../../../slices/receiptSlice/receiptSlice";
import { selectTransferProductsToCrew } from "../../../slices/transferProductsToCrewSlice/selectors";
import { Tab, Tabs } from "@mui/material";
import { TextWorkSans } from "../../../components/common.style";
import { selectDashboardProducts } from "../../../slices/dashboardProductsSlice/selectors";
import {
  getQuantityCrew,
  getQuantityWarehouses,
  resetTable,
  setTabOption,
} from "../../../slices/dashboardProductsSlice/dashboardProductsSlice";
import { AppDispatch } from "../../../slices/store";
import { selectBuildingsDetails } from "../../../slices/dashboardSlice/selectors";

const SearchBar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { fasis, location, companies } = useSelector(selectFashDetails);
  const { construction_crews } = useSelector(selectBuildingsDetails);
  const { control, getValues, watch, reset } = useFormContext<any>(); // Fetching from FormProvider
  const {
    tabOption,
    crewInventoryQuantity,
    crewInventoryQuantityStatus,
    warehouseInventoryQuantity,
    warehouseInventoryQuantityStatus,
    tableOffset,
    tablePage,
  } = useSelector(selectDashboardProducts);

  useEffect(() => {
    return () => {
      dispatch(resetTable());
    };
  }, []);
  useEffect(() => {
    //dispatch(setTableOffset(0));
    ///dispatch(setTablePage(0));

    if (tabOption === 0) {
      console.log("something");
      const payload = {
        ...(watch("fash") && {
          fash_id: watch("fash")?.title,
        }),
        ...(watch("company") && {
          supplier_id: watch("company")?.id,
        }),
        ...(watch("iventoryType") && {
          warehouse_id: watch("iventoryType")?.id,
        }),
      };
      if (!watch("iventoryType")) return;
      dispatch(getQuantityWarehouses(payload));
    }

    if (tabOption === 1) {
      const crewQuantityParams = {
        ...(watch("fash") && {
          fash_id: watch("fash")?.title,
        }),
        ...(watch("company") && {
          supplier_id: watch("company")?.id,
        }),
        ...(watch("iventoryType") && {
          construction_crew_id: watch("iventoryType")?.id,
        }),
      };
      if (!watch("iventoryType")) return;
      dispatch(getQuantityCrew(crewQuantityParams));
    }
  }, [watch("fash"), watch("company"), watch("iventoryType"), tabOption]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    reset();
    dispatch(resetTable());
    dispatch(setTabOption(newValue));
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "50px",
      }}
    >
      <Tabs
        value={tabOption}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab
          label={<TextWorkSans color="primary">ΑΠΟΘΗΚΕΣ</TextWorkSans>}
          {...a11yProps(0)}
        />{" "}
        <Tab
          label={<TextWorkSans color="primary">ΣΥΝΕΡΓΕΙΑ</TextWorkSans>}
          {...a11yProps(0)}
        />{" "}
      </Tabs>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          width: "100%",
        }}
      >
        {tabOption === 1 ? (
          <Controller
            control={control}
            name="iventoryType"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Προς / Συνεργείο"
                placeholder="= Επέλεξε -"
                {...field}
                items={construction_crews}
                value={watch("iventoryType")}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name="iventoryType"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Αποθήκη"
                placeholder="= Επέλεξε -"
                {...field}
                items={location}
                value={watch("iventoryType")}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="fash"
          render={({ field }) => (
            <SelectComponent
              maxWidth={"180px"}
              required
              label="Διάλεξε Φάση"
              placeholder="= Επέλεξε -"
              {...field}
              items={fasis}
              value={watch("fash")}
              onChange={(value) => {
                field.onChange(value);
                dispatch(updateFormValue({ field: "fash", value: value }));
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="company"
          render={({ field }) => (
            <SelectComponent
              maxWidth={"300px"}
              required
              label="Διάλεξε Εταιρεία"
              placeholder="= Επέλεξε -"
              {...field}
              items={companies}
              value={watch("company")}
              onChange={(value) => {
                field.onChange(value);
                dispatch(updateFormValue({ field: "company", value: value }));
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SearchBar;
