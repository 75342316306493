import { RootState } from "../store";
import { createSelector } from "reselect";

// Selector to get multiple parts of CrewDashboardState as an object
export const REDUX_STATE_KEY = "crewDashboardSlice";

// Selectors to get statuses
export const getBuildingsStatus = (state: RootState) =>
  state.crewDashboardSlice.buildingsStatus;

export const getProductsStatus = (state: RootState) =>
  state.crewDashboardSlice.productsStatus;

export const getUpdateBuildingStatus = (state: RootState) =>
  state.crewDashboardSlice.updateBuildingStatus;

// Selectors to get errors
export const getBuildingsError = (state: RootState) =>
  state.crewDashboardSlice.error;

export const getProductsError = (state: RootState) =>
  state.crewDashboardSlice.error;

export const getUpdateBuildingError = (state: RootState) =>
  state.crewDashboardSlice.error;

// Selectors to get data
export const getBuildingsData = (state: RootState) =>
  state.crewDashboardSlice.buildings;

export const getTotalBuildings = (state: RootState) =>
  state.crewDashboardSlice.totalBuildings;

export const getProductsData = (state: RootState) =>
  state.crewDashboardSlice.products;

// Selectors for pagination
export const getBuildingsOffset = (state: RootState) =>
  state[REDUX_STATE_KEY].buildingsOffset;

export const getBuildingsLimit = (state: RootState) =>
  state[REDUX_STATE_KEY].buildingsLimit;

export const getBuildingsPage = (state: RootState) =>
  state[REDUX_STATE_KEY].buildingsPage;

export const selectCrewDashboardDetails = createSelector(
  getBuildingsData,
  getTotalBuildings,
  getProductsData,
  (buildings, totalBuildings, products) => {
    return {
      buildings,
      totalBuildings,
      products,
    };
  }
);
