// navbarSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type DesktopModeState = {
  desktopNavBarVisible: boolean | undefined;
};

const initialState: DesktopModeState = {
  desktopNavBarVisible: undefined,
};

const desktopModeSlice = createSlice({
  name: "desktopMode",
  initialState,
  reducers: {
    setDesktopMode: (state, action: PayloadAction<boolean | undefined>) => {
      state.desktopNavBarVisible = action.payload;
    },
  },
});

export const { setDesktopMode } = desktopModeSlice.actions;

export default desktopModeSlice.reducer;
