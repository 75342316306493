import { CancelTokenSource } from "axios";
import { METHODS, request } from "../../slices/axiosInstances";

export default {
  getReceiptTransferDetails: (): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getReceiptTransferDetails.php", {}),
  getReceiptTransferProducts: (params?: {
    fash: string;
    providerId: number | string;
    warehouse_id: number | string;
  }): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getTransferProducts.php", { params }),
  transferProducts: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } =>
    request(
      METHODS.POST,
      "/updateInventory.php",
      { params },
      true,
      { "Content-Type": "application/pdf" },
      "blob"
    ),
};
