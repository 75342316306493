import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import { css } from "@emotion/react";
import theme from "../../theme/theme";
import { bodyTextLarge, customScrollbars } from "../common.style";

type TextInputProps = {
  maxwidth?: number;
  minWidth?: number;
  border?: string;
  borderBottom?: string;
  padding?: string;
  borderRadius?: string;
  heightFlag?: boolean;
  fontSize?: string;
};

const maxWidthDecoration = ({ maxwidth }: TextInputProps) => css`
  ${maxwidth && `max-width:${maxwidth}px`}
`;

export const TextFieldWrapper = styled.div<{
  label?: string;
}>`
  display: flex;
  flex-direction: column;

  ${({ label }) =>
    !label &&
    css`
      justify-content: flex-end;
    `};
`;

export const TextFieldLabel = styled.div`
  margin-bottom: 16px;
`;
export const TextInputWrapper = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  align-items: ${(props) => (props.direction ? "center" : "")};
  gap: ${(props) => (props.direction ? "20px" : "")};
`;

export const TextInput = styled(InputBase)<TextInputProps>`
  && {
    ${(props) =>
      props.borderBottom
        ? `border-bottom: ${props.borderBottom};`
        : props.border
        ? `border: ${props.border};`
        : `border: 3px solid ${theme.colors.metallicDark};`} // Default border if neither borderBottom nor border is specified
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "12px"};
    padding: 5px ${(props) => (props.padding ? props.padding : "18px")};
    ${(props) => props.borderBottom && `border-bottom: ${props.borderBottom}`}

    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "12px"};

    width: 100%;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
    ${(props: TextInputProps) =>
      maxWidthDecoration({ maxwidth: props.maxwidth })}
    & input, textarea {
      background-color: transparent;
      ${(props) =>
        bodyTextLarge({
          color: "metallicPearlWhite",
          fontSize: props.fontSize,
        })};
      &::placeholder {
        color: ${theme.colors.metallicDark};
      }
    }
  }
  ${(props: TextInputProps) =>
    props.heightFlag &&
    `& textarea {
      height: 123px;
      ${customScrollbars};
    }`}
`;

export const DeleteIconWrapper = styled.span`
  margin: 0 16px 0 0;
  cursor: pointer;
  display: flex;
`;
