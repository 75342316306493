import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { AppDispatch } from "../../../slices/store";
import {
  addBuilding,
  getBuildingDetails,
  uploadBuildingExcel,
} from "../../../slices/dashboardSlice/dashboardSlice";
import {
  DisableComponent,
  TextWorkSans,
} from "../../../components/common.style";
import Button from "../../../components/Button/Button";
import SelectComponent from "../../../components/Select/Select";
import { TextField } from "../../../components";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { selectAddBuildingDetails } from "../../../slices/dashboardSlice/selectors";
import FileInput from "../../../components/uploadFile/uploadFile";

const AddBuilding: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const methods = useForm<any>({
    defaultValues: {
      address: undefined,
      number: undefined,
      city: undefined,
      supplier: undefined,
      provider: undefined,
      iron_pipe: "BP",
      file: "",
    },
  });

  const { control, watch, setValue, handleSubmit, getValues, resetField } =
    methods;

  const { cities, suppliers, providers } = useSelector(
    selectAddBuildingDetails
  );

  useEffect(() => {
    dispatch(getBuildingDetails());
  }, [dispatch]);

  const onSubmit = () => {
    if (watch("file") !== "") {
      const params = {
        file: watch("file") as File,
        navigate: history,
      };
      dispatch(uploadBuildingExcel(params));
    } else {
      const params = {
        id_city: Number(getValues("city").id),
        supplier_id: Number(getValues("supplier").id),
        provider_id: Number(getValues("provider").id),
        number: getValues("number"),
        address: getValues("address"),
        iron_pipe_status:
          getValues("iron_pipe") === "BP"
            ? 1
            : getValues("iron_pipe") === "Ironpipe"
            ? 2
            : getValues("iron_pipe") === "BCP"
            ? 3
            : undefined,
      };

      dispatch(
        addBuilding({
          data: params,
          navigate: history,
        })
      );
    }
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };

  const [previewPage, setPreviewPage] = useState<boolean>(false);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        style={{
          display: "flex",
          gap: "40px",
          justifyContent: "center",
          flexDirection: "column",
          height: "auto", // Changed from "100%" to "auto"
          padding: "40px",
          width: "100%",
          maxWidth: "1300px",
          marginTop: "40px",
          marginBottom: "40px",
          backgroundColor: "#F5F5F5",
          borderRadius: "20px",
          boxSizing: "border-box", // Ensures padding is included in the element's total width and height
        }}
      >
        <div
          style={{
            marginTop: "25px",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextWorkSans fontSize={25} color={"secondary"}>
            {previewPage
              ? " ΠΡΟΣΘΗΚΗ ΠΟΛΥΚΑΤΟΙΚΙΑΣ "
              : " ΠΡΟΣΘΗΚΗ ΠΟΛΥΚΑΤΟΙΚΙΑΣ"}
          </TextWorkSans>
          <div style={{ marginTop: "auto" }}>
            <Button
              padding={0}
              size="medium"
              backgroundColor="primary"
              color="white"
              type="submit"
              fontSize={12}
            >
              {previewPage ? " ΠΡΟΣΘΗΚΗ ΠΑΡΑΛΑΒΗΣ " : " ΠΡΟΣΘΗΚΗ ΠΟΛΥΚΑΤΟΙΚΙΑΣ"}
            </Button>
          </div>
        </div>

        <DisableComponent disabled={watch("file") !== "" ? true : false}>
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Controller
              control={control}
              name="city"
              render={({ field }) => (
                <SelectComponent
                  maxWidth={"300px"}
                  required={watch("file") === "" ? true : false}
                  label="Πόλη"
                  placeholder="= Επέλεξε -"
                  {...field}
                  items={cities}
                  value={watch("city")}
                />
              )}
            />

            <Controller
              control={control}
              name="supplier"
              render={({ field }) => (
                <SelectComponent
                  maxWidth={"300px"}
                  required={watch("file") === "" ? true : false}
                  label="Προμηθευτής"
                  placeholder="= Επέλεξε -"
                  {...field}
                  items={suppliers}
                  value={watch("supplier")}
                />
              )}
            />

            <Controller
              control={control}
              name="provider"
              render={({ field }) => (
                <SelectComponent
                  maxWidth={"300px"}
                  required={watch("file") === "" ? true : false}
                  label="Πάροχος"
                  placeholder="= Επέλεξε -"
                  {...field}
                  items={providers}
                  value={watch("provider")}
                />
              )}
            />

            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  formToday
                  maxwidth={200}
                  label={"Διεύθυνση"}
                  type="text"
                  required={watch("file") === "" ? true : false}
                  placeholder="Διεύθυνση"
                  {...field}
                />
              )}
            />

            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <TextField
                  formToday
                  maxwidth={200}
                  label={"Αριθμός"}
                  type="number"
                  required={watch("file") === "" ? true : false}
                  placeholder="Αριθμός"
                  {...field}
                />
              )}
            />

            <Controller
              name={"iron_pipe"}
              control={control}
              render={({ field }) => {
                return (
                  <RadioGroup
                    style={{ paddingTop: "30px" }}
                    row
                    defaultValue={"BP"}
                    {...field}
                  >
                    <FormControlLabel
                      value="BP"
                      control={<Radio />}
                      label="BP"
                    />
                    <FormControlLabel
                      value="Ironpipe"
                      control={<Radio />}
                      label="Σιδηροσωλήνας"
                    />
                    <FormControlLabel
                      value="BCP"
                      control={<Radio />}
                      label="BCP"
                    />
                  </RadioGroup>
                );
              }}
            />
          </div>
        </DisableComponent>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Controller
            name="file"
            control={control}
            render={({ field }) => <FileInput name="file" />}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default AddBuilding;
