import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { GenericModalProperties } from "../modalSlice/types";
import { closeGenericModal, openGenericModal } from "../modalSlice/modalSlice";
import {
  DashboardAdmin,
  DashboardDetailsAPI,
  GetWareHouseAPIResponse,
} from "./types";
import dashboardAPI from "../../providers/dashboardAPI";
import dashboardAdminAPI from "../../providers/dashboardAdminAPI";

export const initialState: DashboardAdmin = {
  wareHouseLogData: null,
  wareHouseLogStatus: "loading",
  wareHouseLogError: null,
  receiptsLogData: null,
  receiptsLogStatus: "loading",
  receiptsLogError: null,
  transferLogData: null,
  transferLogStatus: "loading",
  transferLogError: null,
  productQuantityStatus: "loading",
  productQuantityData: null,
  tableOffset: 0,
  tabOption: 0,
  tablePage: 0,
  expandedRow: -1,
};

export const generatePDFWarehouses = createAsyncThunk(
  "dashboardAdminSlice/generatePDFWarehouses",
  async (params: any, thunkAPI) => {
    const { request } = dashboardAdminAPI.single.generatePDFWarehouses(params);
    const modalParamsLoader: GenericModalProperties = {
      title: "ΑΝΑΜΟΝΗ",
      enableProgressBar: true,
    };
    thunkAPI.dispatch(openGenericModal(modalParamsLoader));
    return request()
      .then((response: any) => {
        console.log(response);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "transfer_document.pdf";
        link.click();
        thunkAPI.dispatch(closeGenericModal());

        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η μεταφορά έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            //params.navigate("/");
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));

        return response;
      })
      .catch((error) => {
        console.log(error);
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.response?.data?.error || error.message,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(
          error.response?.data?.error || error.message
        );
      });
  }
);

export const generatePDFAddReceipt = createAsyncThunk(
  "dashboardAdminSlice/generatePDFAddReceipt",
  async (params: any, thunkAPI) => {
    const { request } = dashboardAdminAPI.single.generatePDFAddReceipt(params);
    const modalParamsLoader: GenericModalProperties = {
      title: "ΑΝΑΜΟΝΗ",
      enableProgressBar: true,
    };
    thunkAPI.dispatch(openGenericModal(modalParamsLoader));
    return request()
      .then((response: any) => {
        console.log(response);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "transfer_document.pdf";
        link.click();
        thunkAPI.dispatch(closeGenericModal());

        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η μεταφορά έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            //params.navigate("/");
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));

        return response;
      })
      .catch((error) => {
        console.log(error);
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.response?.data?.error || error.message,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(
          error.response?.data?.error || error.message
        );
      });
  }
);

export const generatePDFConstructionCrew = createAsyncThunk(
  "dashboardAdminSlice/generatePDFConstructionCrew",
  async (params: any, thunkAPI) => {
    const { request } =
      dashboardAdminAPI.single.generatePDFConstructionCrew(params);
    const modalParamsLoader: GenericModalProperties = {
      title: "ΑΝΑΜΟΝΗ",
      enableProgressBar: true,
    };
    thunkAPI.dispatch(openGenericModal(modalParamsLoader));
    return request()
      .then((response: any) => {
        console.log(response);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "transfer_document.pdf";
        link.click();
        thunkAPI.dispatch(closeGenericModal());

        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η μεταφορά έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            //params.navigate("/");
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));

        return response;
      })
      .catch((error) => {
        console.log(error);
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.response?.data?.error || error.message,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(
          error.response?.data?.error || error.message
        );
      });
  }
);

export const getWarehouseLog = createAsyncThunk(
  "dashboardAdminSlice/getWarehouseLog",
  async (params: any, thunkAPI) => {
    const { request } = dashboardAdminAPI.single.getWarehouseLog(params);
    return request()
      .then((response: GetWareHouseAPIResponse) => {
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const getReceiptsLog = createAsyncThunk(
  "dashboardAdminSlice/getReceiptsLog",
  async (params: any, thunkAPI) => {
    const { request } = dashboardAdminAPI.single.getReceiptsLog(params);

    return request()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.error);
      });
  }
);

export const getTransferConstructionCrewLogs = createAsyncThunk(
  "dashboardAdminSlice/getTransferConstructionCrewLogs",
  async (params: any, thunkAPI) => {
    const { request } =
      dashboardAdminAPI.single.getTransferConstructionCrewLogs(params);
    return request()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message:
            error.message || "Κάτι πήγε στραβά κατά την αποστολή του αρχείου.",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const cancelTransfer = createAsyncThunk(
  "dashboardAdminSlice/cancelTransfer",
  async (params: any, thunkAPI) => {
    const { request } = dashboardAdminAPI.single.cancelTransfer(params);
    return request()
      .then((response: any) => {
        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η διαγραφή έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            //params.navigate("/");
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));

        //return response;
      })
      .catch((error) => {
        console.log(error);
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.message || "",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);
export const getTransferWarehouseLogs = createAsyncThunk(
  "dashboardAdminSlice/getTransferWarehouseLogs",
  async (params: any, thunkAPI) => {
    const { request } =
      dashboardAdminAPI.single.getTransferWarehouseLogs(params);
    return request()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message:
            error.message || "Κάτι πήγε στραβά κατά την αποστολή του αρχείου.",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const getDashboardDetails = createAsyncThunk(
  "dashboardAdminSlice/getDashboardDetails",
  async (params, thunkAPI) => {
    const { request } = dashboardAPI.single.getDashboardDetails(params);
    return request()
      .then((response: DashboardDetailsAPI) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

const dashboardAdminSlice = createSlice({
  name: "dashboardAdminSlice",
  initialState: initialState,
  reducers: {
    setExpandedRow(state, action) {
      state.expandedRow = action.payload;
    },

    setTabOption(state, action) {
      state.tabOption = action.payload;
    },

    setTableOffset(state, action) {
      state.tableOffset = action.payload;
    },

    setTablePage(state, action) {
      state.tablePage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getWarehouseLog.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.wareHouseLogData = action.payload;
        state.wareHouseLogStatus = "succeeded";
      }
    );
    builder.addCase(getWarehouseLog.rejected, (state, action) => {
      state.wareHouseLogStatus = "failed";
      state.wareHouseLogError = action.error;
    });
    builder.addCase(
      getReceiptsLog.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.receiptsLogData = action.payload;
        state.receiptsLogStatus = "succeeded";
      }
    );
    builder.addCase(getReceiptsLog.rejected, (state, action) => {
      state.receiptsLogStatus = "failed";
      state.receiptsLogError = action.error;
    });
    builder.addCase(
      getTransferConstructionCrewLogs.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.transferLogData = action.payload;
        state.transferLogStatus = "succeeded";
      }
    );
    builder.addCase(
      getTransferConstructionCrewLogs.rejected,
      (state, action) => {
        state.transferLogStatus = "failed";
        state.transferLogError = action.error;
      }
    );
    builder.addCase(
      getTransferWarehouseLogs.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.transferLogData = action.payload;
        state.transferLogStatus = "succeeded";
      }
    );
    builder.addCase(getTransferWarehouseLogs.rejected, (state, action) => {
      state.transferLogStatus = "failed";
      state.transferLogError = action.error;
    });
  },
});

export const { setExpandedRow, setTableOffset, setTablePage, setTabOption } =
  dashboardAdminSlice.actions;

const { reducer } = dashboardAdminSlice;
export default reducer;
