import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { GenericModalProperties } from "../modalSlice/types";
import { closeGenericModal, openGenericModal } from "../modalSlice/modalSlice";
import warehouseAPI from "../../providers/warehouseAPI";
import { Product } from "../productsSlice/productsSlice";

export interface FormValues {
  fash?: { title: string; id: string };
  from?: { title: string; id: string };
  to?: { title: string; id: string };
  provider?: { title: string; id: string };
  products: { productId: number; quantity: string }[];
  date?: string;
}

export interface ApiResponse {
  fasi: { id: string; title: string }[];
  location: { id: string; title: string }[];
  types: { title: string }[];
  companies: { title: string; id: string }[];
}

export interface ReceiptTransferDetails {
  fasis: { title: string }[];
  location: { id: string; title: string }[];
  typos: { title: string }[];
  companies: { title: string; id: string }[];
  products: Product[];
  productsStatus: "idle" | "loading" | "succeeded" | "failed";
  productsError: SerializedError | null;
  formValues: FormValues;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
}

export const initialState: ReceiptTransferDetails = {
  fasis: [],
  location: [],
  typos: [],
  companies: [],
  products: [],
  productsStatus: "loading",
  productsError: null,
  status: "loading",
  error: null,
  formValues: {
    fash: { title: "", id: "" },
    from: { title: "", id: "" },
    to: { title: "", id: "" },
    provider: { title: "", id: "" },
    products: [{ productId: -1, quantity: "" }],
    date: "",
  },
};

export const getReceiptTransferDetails = createAsyncThunk(
  "receiptTransfer/getReceiptTransferDetails",
  async (_, thunkAPI) => {
    const { request } = warehouseAPI.single.getReceiptTransferDetails();
    return request()
      .then((response: ApiResponse) => {
        return response;
      })

      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const transferProductsAPI = createAsyncThunk(
  "receiptTransfer/transferProducts",
  async (params: { transferData: any; navigate: any }, thunkAPI) => {
    const { request } = warehouseAPI.single.transferProducts(
      params.transferData
    );
    const modalParamsLoader: GenericModalProperties = {
      title: "ΑΝΑΜΟΝΗ",
      enableProgressBar: true,
    };
    thunkAPI.dispatch(openGenericModal(modalParamsLoader));
    return request()
      .then((response: any) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "μεταφορά_σε_αποθήκη.pdf";
        link.click();
        thunkAPI.dispatch(closeGenericModal());
        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η μεταφορά έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            params.navigate("/");
            // You can now use the `someOtherProp` here
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.error);
      });
  }
);

export const getReceiptTransferProducts = createAsyncThunk(
  "receiptTransfer/getReceiptTransferProducts",
  async (params: any, thunkAPI) => {
    const { request } = warehouseAPI.single.getReceiptTransferProducts(params);
    return request()
      .then((response: Product[]) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

const receiptTransferSlice = createSlice({
  name: "receiptTransfer",
  initialState: initialState,
  reducers: {
    updateFormValue: (
      state,
      action: PayloadAction<{ field: keyof FormValues; value: any }>
    ) => {
      state.formValues![action.payload.field] = action.payload.value;
    },
    setFormValues: (state, action: PayloadAction<FormValues>) => {
      state.formValues = { ...state.formValues, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReceiptTransferDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      getReceiptTransferDetails.fulfilled,
      (state, action: PayloadAction<ApiResponse>) => {
        state.status = "succeeded";
        state.fasis = action.payload.fasi;
        state.location = action.payload.location;
        state.typos = action.payload.types;
        state.companies = action.payload.companies;
      }
    );
    builder.addCase(getReceiptTransferDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error;
    });

    builder.addCase(
      getReceiptTransferProducts.fulfilled,
      (state, action: PayloadAction<Product[]>) => {
        state.products = action.payload;
        state.productsStatus = "succeeded";
      }
    );
    builder.addCase(getReceiptTransferProducts.rejected, (state, action) => {
      state.productsStatus = "failed";
      state.productsError = action.error;
    });

    builder.addCase(
      transferProductsAPI.fulfilled,
      (state, action: PayloadAction<ApiResponse>) => {
        state.formValues = initialState.formValues;
        state.products = initialState.products;
      }
    );
  },
});

const { actions, reducer } = receiptTransferSlice;
export const { setFormValues, updateFormValue } = actions;
export default reducer;
