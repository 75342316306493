import styled from "@emotion/styled";
import { Select, MenuItem, Tooltip } from "@mui/material";
import theme from "../../theme/theme";
import { bodyTextLarge } from "../common.style";

type Props = {
  active?: boolean;
  maxWidth?: string | number;
  minWidth?: string | number;
  width?: number | string;
};

export const ToolTipComponent = styled(Tooltip)`
  font-size: 80px;
`;

export const WrapperStyledSelect = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: ${(props) => (props.active ? "16px" : "0px")};
  width: ${(props) => (props.width ? `${props.width}` : "auto")};
`;

export const StyledSelect = styled(Select)<Props>`
  ${() => bodyTextLarge({ color: "metallicPearlWhite" })};
  margin-top: ${(props) => (props.active ? "16px" : "0px")};
  padding: 0px;

  width: 100%;
  max-width: ${(props) =>
    props.maxWidth
      ? typeof props.maxWidth === "string"
        ? props.maxWidth
        : `${props.maxWidth}px`
      : "unset"}; /* Adjusts the maximum width based on props */

  .MuiSelect-icon {
    display: none;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  font-family: "Work Sans";
  top: 0;
  /* Iris/Dark - 80 */
`;

export const ListItem = styled.div`
  dispalt: flex;
  margin-right: 19px;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  color: ${theme.colors.primary};
`;

export const ItemIcon = styled.div`
  color: ${theme.colors.primary};
  img {
    max-width: 200px;
    max-height: 100px;
  }
`;

export const ItemTitle = styled.span<{
  metallicPearl?: boolean;
  marginLeft?: string;
}>`
margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "8px")};
  && {
    ${(props) =>
      props.metallicPearl
        ? `  font-family: 'Work Sans';
  font-weight: 700;
  font-size: 15px;
    color: ${theme.colors.primary};

   `
        : `
  color: ${props.theme.colors.primary};
  `}
`;
