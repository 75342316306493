import React, { useEffect, useRef, useState } from "react";
import {
  StyledSelect,
  StyledMenuItem,
  ItemTitle,
  ToolTipComponent,
  WrapperStyledSelect,
} from "./Select.style";
import Icon from "../Icon/Icon";
import { H4 } from "../common.style";
import TextField from "../TextField/TextField";

export type SelectProps = {
  id?: string;
  label?: string;
  value?: any;
  metallicPearl?: boolean;
  defaultValue?: any;
  required?: boolean;
  minWidth?: number | string;
  placeholder?: string;
  noneOption?: boolean;
  style?: React.CSSProperties;
  matchValue?: string;
  width?: number | string;
  maxWidth?: number | string;
  border?: string;
  padding?: string;
  borderRadius?: string;
  infoText?: string;
  itemTitleMarginLeft?: string;
  items?: {
    title: string;
    shortName?: string;
    icon?: JSX.Element;
    data?: any;
    id?: number | string;
  }[];
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

const SelectComponent: React.FC<SelectProps> = ({
  id,
  label,
  items,
  itemTitleMarginLeft,
  placeholder,
  required,
  matchValue,
  value,
  defaultValue,
  border,
  maxWidth,
  padding,
  infoText,
  width,
  borderRadius,
  minWidth,
  onChange,
  noneOption = false,
  metallicPearl = false,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (value: any) => {
    const newValue = value.title;
    setSelectedValue(newValue);
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (value || value === "") {
      setSelectedValue(value.title);
    }
  }, [value]);

  const [selectedValue, setSelectedValue] = useState(value || "");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  const initialDate = new Date();
  initialDate.setHours(0, 0, 0, 0);

  const dropdownRef = useRef<HTMLElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !isDatePickerActive
    ) {
      setShowSubMenu(false);
    }
  };
  // use effect hook to add and remove the event listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isDatePickerActive, setIsDatePickerActive] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        maxWidth: `${maxWidth}`,
      }}
    >
      <div>
        {label && <H4 color="secondary">{label}</H4>}
        {label && required && <H4 color="candyRed"> *</H4>}
      </div>
      <WrapperStyledSelect active={label != undefined || label != null}>
        <StyledSelect
          style={{ flex: 1 }}
          required={required}
          id={id}
          ref={dropdownRef}
          onChange={handleChange}
          width={width}
          maxWidth={maxWidth}
          minWidth={minWidth}
          value={selectedValue}
          renderValue={(selected: any) => {
            if (
              !selected ||
              selected == "" ||
              value === null ||
              value === undefined
            ) {
              setSelectedValue("");
              return (
                <ItemTitle
                  marginLeft={itemTitleMarginLeft}
                  metallicPearl={placeholder ? true : false}
                >
                  {placeholder ? placeholder : "KANENA"}
                </ItemTitle>
              );
            } else {
              let title = "";
              if (items) {
                for (const item of items) {
                  if (
                    //@ts-ignore
                    (matchValue && item[matchValue] === selected.toString()) ||
                    (item.shortName &&
                      item.shortName === selected.toString()) ||
                    item.title === selected.toString()
                  ) {
                    title = item.title;
                    break;
                  }
                }
                return (
                  <ItemTitle
                    marginLeft={itemTitleMarginLeft}
                    metallicPearl={metallicPearl}
                  >
                    {title}
                  </ItemTitle>
                );
              }
              return (
                <ItemTitle
                  marginLeft={itemTitleMarginLeft}
                  metallicPearl={metallicPearl}
                >
                  {title}
                </ItemTitle>
              );
            }
          }}
          displayEmpty
          input={
            <TextField
              padding={padding}
              border={border}
              iconRight="chevron-down"
              iconColor="metallicDark"
            />
          }
          {...rest}
        >
          {noneOption && (
            <StyledMenuItem style={{ width: "100%" }} value={""}>
              <ItemTitle
                marginLeft={itemTitleMarginLeft}
                metallicPearl={metallicPearl}
              >
                KANENA
              </ItemTitle>
            </StyledMenuItem>
          )}
          {items?.map((item: any, key: any) => (
            <StyledMenuItem
              id={`${item.title}`}
              key={key}
              style={{
                width: "100%",
                backgroundColor:
                  selectedValue &&
                  (selectedValue === item.title ||
                    selectedValue === item.shortName)
                    ? "rgba(25, 118, 210, 0.08)"
                    : "transparent",
              }}
              value={
                matchValue
                  ? item[matchValue]
                  : item.shortName
                  ? item.shortName
                  : item.title
                  ? item
                  : undefined
              }
            >
              {/* {item.icon && <ItemIcon>{item.icon}</ItemIcon>} */}
              <ItemTitle
                marginLeft={itemTitleMarginLeft}
                metallicPearl={metallicPearl}
              >
                {item.title}
              </ItemTitle>
            </StyledMenuItem>
          ))}
        </StyledSelect>
        {infoText && (
          <div style={{ zIndex: "10" }}>
            <ToolTipComponent
              placement="top-end"
              title={<span style={{ fontSize: "13px" }}>{infoText} </span>}
            >
              <div style={{ display: "flex" }}>
                <Icon name="information" color="irisLight" />
              </div>
            </ToolTipComponent>
          </div>
        )}
      </WrapperStyledSelect>
    </div>
  );
};

export default React.memo(SelectComponent);
