import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Back = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill={colors[color || "onyxDark"]}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.62599 6.41332C7.94117 6.01479 7.87359 5.43621 7.47505 5.12103C7.07652 4.80585 6.49794 4.87343 6.18276 5.27197L1.80288 10.8103C1.54268 11.1393 1.53798 11.6027 1.79147 11.9369L6.17135 17.7119C6.47839 18.1167 7.05548 18.196 7.46032 17.8889C7.86516 17.5819 7.94444 17.0048 7.6374 16.6L4.46666 12.4193H19.1388C19.6469 12.4193 20.0588 12.0074 20.0588 11.4993C20.0588 10.9912 19.6469 10.5793 19.1388 10.5793H4.3314L7.62599 6.41332Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62599 6.41332C7.94117 6.01479 7.87359 5.43621 7.47505 5.12103C7.07652 4.80585 6.49794 4.87343 6.18276 5.27197L1.80288 10.8103C1.54268 11.1393 1.53798 11.6027 1.79147 11.9369L6.17135 17.7119C6.47839 18.1167 7.05548 18.196 7.46032 17.8889C7.86516 17.5819 7.94444 17.0048 7.6374 16.6L4.46666 12.4193H19.1388C19.6469 12.4193 20.0588 12.0074 20.0588 11.4993C20.0588 10.9912 19.6469 10.5793 19.1388 10.5793H4.3314L7.62599 6.41332Z"
        fill={colors[color || "onyxDark"]}
      />
      <path
        d="M7.47505 5.12103L6.85475 5.90539L6.85475 5.90539L7.47505 5.12103ZM7.62599 6.41332L6.84163 5.79302V5.79302L7.62599 6.41332ZM6.18276 5.27197L6.96712 5.89227V5.89227L6.18276 5.27197ZM1.80288 10.8103L2.58724 11.4306H2.58724L1.80288 10.8103ZM1.79147 11.9369L2.58823 11.3326H2.58823L1.79147 11.9369ZM6.17135 17.7119L5.37458 18.3161H5.37458L6.17135 17.7119ZM7.46032 17.8889L8.06461 18.6857L8.06461 18.6857L7.46032 17.8889ZM7.6374 16.6L6.84063 17.2043L7.6374 16.6ZM4.46666 12.4193V11.4193H2.45316L3.66989 13.0236L4.46666 12.4193ZM4.3314 10.5793L3.54704 9.95898L2.26565 11.5793H4.3314V10.5793ZM6.85475 5.90539C6.8201 5.87799 6.81422 5.82768 6.84163 5.79302L8.41035 7.03362C9.06811 6.2019 8.92708 4.99443 8.09535 4.33667L6.85475 5.90539ZM6.96712 5.89227C6.93972 5.92692 6.88941 5.9328 6.85475 5.90539L8.09536 4.33667C7.26363 3.67891 6.05616 3.81994 5.3984 4.65166L6.96712 5.89227ZM2.58724 11.4306L6.96712 5.89227L5.3984 4.65166L1.01852 10.19L2.58724 11.4306ZM2.58823 11.3326C2.61028 11.3617 2.60987 11.402 2.58724 11.4306L1.01852 10.19C0.475482 10.8766 0.465688 11.8437 0.994701 12.5412L2.58823 11.3326ZM6.96812 17.1076L2.58823 11.3326L0.994701 12.5412L5.37458 18.3161L6.96812 17.1076ZM6.85603 17.0922C6.89124 17.0655 6.94142 17.0724 6.96812 17.1076L5.37458 18.3161C6.01536 19.161 7.21973 19.3265 8.06461 18.6857L6.85603 17.0922ZM6.84063 17.2043C6.81393 17.1691 6.82083 17.1189 6.85603 17.0922L8.06461 18.6857C8.90949 18.0449 9.07495 16.8406 8.43417 15.9957L6.84063 17.2043ZM3.66989 13.0236L6.84063 17.2043L8.43417 15.9957L5.26342 11.815L3.66989 13.0236ZM19.1388 11.4193H4.46666V13.4193H19.1388V11.4193ZM19.0588 11.4993C19.0588 11.4551 19.0946 11.4193 19.1388 11.4193V13.4193C20.1992 13.4193 21.0588 12.5597 21.0588 11.4993H19.0588ZM19.1388 11.5793C19.0946 11.5793 19.0588 11.5435 19.0588 11.4993H21.0588C21.0588 10.4389 20.1992 9.57928 19.1388 9.57928V11.5793ZM4.3314 11.5793H19.1388V9.57928H4.3314V11.5793ZM6.84163 5.79302L3.54704 9.95898L5.11577 11.1996L8.41035 7.03362L6.84163 5.79302Z"
        fill={colors[color || "onyxDark"]}
        mask="url(#path-1-inside-1)"
      />
    </svg>
  );
};

export default Back;
