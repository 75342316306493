import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Information = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.07424 5.91032C9.91495 4.60886 12.1973 4.09192 14.4191 4.47323C16.641 4.85453 18.6203 6.10284 19.9218 7.94355C21.2233 9.78426 21.7402 12.0666 21.3589 14.2884C20.9776 16.5103 19.7293 18.4896 17.8886 19.7911C16.0479 21.0926 13.7655 21.6095 11.5437 21.2282C9.32184 20.8469 7.34247 19.5986 6.04101 17.7579C4.73955 15.9172 4.22261 13.6348 4.60392 11.413C4.98522 9.19115 6.23354 7.21178 8.07424 5.91032ZM14.7574 2.50204C12.0128 2.03102 9.19343 2.66959 6.91961 4.27728C4.6458 5.88496 3.10376 8.33007 2.63273 11.0747C2.16171 13.8193 2.80028 16.6387 4.40797 18.9125C6.01565 21.1863 8.46076 22.7283 11.2054 23.1994C13.95 23.6704 16.7694 23.0318 19.0432 21.4241C21.317 19.8165 22.859 17.3713 23.3301 14.6267C23.8011 11.8821 23.1625 9.06274 21.5548 6.78892C19.9472 4.5151 17.502 2.97307 14.7574 2.50204ZM12.9814 11.0628C12.4292 11.0628 11.9814 11.5106 11.9814 12.0628V18.0448C11.9814 18.5971 12.4292 19.0448 12.9814 19.0448C13.5337 19.0448 13.9814 18.5971 13.9814 18.0448V12.0628C13.9814 11.5106 13.5337 11.0628 12.9814 11.0628ZM14.3193 8.23606C14.3193 7.4969 13.7201 6.89769 12.981 6.89769C12.2418 6.89769 11.6426 7.4969 11.6426 8.23606C11.6426 8.97523 12.2418 9.57444 12.981 9.57444C13.7201 9.57444 14.3193 8.97523 14.3193 8.23606Z"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default Information;
