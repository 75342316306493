import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Schedule = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        d="M24 6V4h-2v2H12V4h-2v2H7v20h20V6h-3zm1 18H9V12h16v12zM9 10V8h1v1h2V8h10v1h2V8h1v2H9zm4 6h-2v-2h4v8h-2v-6zm10-2h-6v8h6v-8zm-2 6h-2v-4h2v4zM6 27h19v2H4V8h2v19z"
        style={{
          fill: colors[color || "metallicPearlWhite"],
        }}
      />
    </svg>
  );
};

export default Schedule;
