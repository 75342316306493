import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Euro = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    // <svg
    //   width={width ? width : '17'}
    //   height={height ? height : '17'}
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fill-rule="evenodd"
    //     clip-rule="evenodd"
    //     d="M7.25 10.5a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm-1.543 9.207a1 1 0 0 1-1.414-1.414l14-14a1 1 0 1 1 1.414 1.414l-14 14zM13 17.25a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0zM7.25 8.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm11.25 8.75a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0z"
    //     fill={colors[color || 'metallicPearlWhite']}
    //   />
    // </svg>

    // <svg
    // fill={colors[color || 'metallicPearlWhite']}
    //   width={width ? width : '17'}
    //   height={height ? height : '17'}
    //   viewBox="0 0 32 32"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path d="M3,23H6.65A16,16,0,0,0,29,29.86a1,1,0,0,0,.47-.61,1.05,1.05,0,0,0-.1-.76l-2-3.46A1,1,0,0,0,26,24.66,9.91,9.91,0,0,1,13.92,23H21a1,1,0,0,0,1-1V18a1,1,0,0,0-1-1H11.06a8.36,8.36,0,0,1,0-2H23a1,1,0,0,0,1-1V10a1,1,0,0,0-1-1H13.92A9.91,9.91,0,0,1,26,7.34a1,1,0,0,0,.76.1A1,1,0,0,0,27.37,7l2-3.46a1.05,1.05,0,0,0,.1-.76A1.06,1.06,0,0,0,29,2.14,16,16,0,0,0,6.65,9H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H5c0,.35,0,.68,0,1s0,.65,0,1H3a1,1,0,0,0-1,1v4A1,1,0,0,0,3,23Zm18,5a12,12,0,0,0,5.11-1.14l1,1.73A14,14,0,0,1,8.91,23h2.37A12,12,0,0,0,21,28ZM21,2a13.94,13.94,0,0,1,6.12,1.41l-1,1.73A12,12,0,0,0,11.28,9H8.91A14,14,0,0,1,21,2ZM4,11H22v2H4Zm3,5c0-.32,0-.64,0-1h2c0,.34-.05.67-.05,1s0,.66.05,1h-2C7,16.64,7,16.32,7,16ZM4,19H20v2H4Z" />
    // </svg>

    //     <svg
    //       version="1.1"
    //       id="_x32_"
    //       xmlns="http://www.w3.org/2000/svg"
    //           fill={colors[color || 'metallicPearlWhite']}
    //       width={width ? width : '17'}
    //       height={height ? height : '17'}
    //       viewBox="0 0 512 512"
    //     >
    //       <g>
    //         <path
    //           d="M433.156,104.281l-12.969-5.469c-21.297-9-43.453-13.5-65.844-13.5c-64.766,0-124.234,37.531-152.813,95.047
    //  h216l-13.328,64.484H184c-0.266,3.656-0.422,7.406-0.422,11.156c0,9.219,0.813,18.594,2.375,28.031h210.266l-13.25,64.5H211.063
    //  c31.344,48.734,84.719,78.25,143.281,78.25c19.859,0,39.781-3.625,59.188-10.875l15.469-5.75v90.734l-8.484,2.266
    //  c-21.75,5.875-44.031,8.844-66.172,8.844c-105.422,0-200.266-65.344-238.375-163.469H61.422v-64.5h38.688
    //  c-1.188-9.875-1.766-19.094-1.766-28.031c0-3.688,0.094-7.5,0.25-11.156H61.422v-64.484h48.672C143.141,73.75,242.313,0,354.344,0
    //  c29.75,0,58.969,5.234,86.938,15.438l9.297,3.438L433.156,104.281z"
    //         />
    //       </g>
    //     </svg>

    <svg
      fill={colors[color || "metallicPearlWhite"]}
      width={width ? width : "17"}
      height={height ? height : "17"}
      viewBox="-3.5 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m16.64 20.097.597 2.71c.011.039.017.084.017.13 0 .093-.025.18-.07.255l.001-.002c-.064.114-.164.201-.286.246l-.004.001-.086.017c-.051.025-.111.045-.174.059l-.005.001q-.11.026-.273.08t-.366.094q-.205.042-.434.086t-.511.086-.571.08-.622.051q-.333.017-.656.017c-.066.001-.144.002-.223.002-2.542 0-4.888-.836-6.779-2.248l.03.021c-1.952-1.467-3.387-3.529-4.039-5.914l-.018-.077h-1.619c-.3-.004-.542-.246-.546-.545v-1.927c.004-.3.246-.542.545-.546h1.125q-.034-.971.017-1.79h-1.142c-.006 0-.014 0-.021 0-.29 0-.525-.235-.525-.525 0-.007 0-.015 0-.022v.001-1.943c0-.006 0-.014 0-.021 0-.29.235-.525.525-.525h.022-.001 1.67c.748-2.379 2.204-4.356 4.118-5.738l.033-.022c1.851-1.368 4.179-2.19 6.698-2.19.044 0 .088 0 .132.001h-.007.066c1.151 0 2.268.143 3.335.412l-.094-.02c.142.046.26.136.339.254l.001.002c.043.078.069.17.069.269 0 .05-.006.098-.019.144l.001-.004-.733 2.71c-.03.143-.118.261-.238.331l-.002.001c-.071.043-.158.069-.25.069-.057 0-.111-.01-.162-.028l.003.001-.068-.017q-.068-.017-.196-.042l-.298-.06-.383-.06-.443-.051-.494-.042-.503-.017c-.03 0-.065-.001-.1-.001-1.393 0-2.69.407-3.78 1.109l.028-.017c-1.127.726-2.008 1.748-2.543 2.957l-.017.043h7.978c.306.004.553.253.553.56 0 .031-.003.062-.007.091v-.003l-.409 1.943c-.028.252-.239.446-.496.446-.018 0-.035-.001-.052-.003h.002-8.32c-.017.267-.026.58-.026.895s.009.627.028.937l-.002-.043h7.824.007c.17 0 .321.08.419.204l.001.001c.072.093.116.211.116.339 0 .043-.005.085-.014.125l.001-.004-.409 1.909c-.051.25-.267.437-.527.443h-.001-6.593c1.036 2.558 3.5 4.33 6.378 4.33h.069-.003q.307 0 .614-.026t.571-.06.503-.08.418-.086l.315-.08.205-.051.086-.034c.055-.022.119-.035.186-.035.095 0 .183.026.259.071l-.002-.001c.134.077.23.208.258.362v.003z" />
    </svg>
  );
};

export default Euro;
