import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Camera = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.238 3.387a.16.16 0 0 0-.16.16A2.194 2.194 0 0 1 6.884 5.74H4.125c-.64 0-1.16.52-1.16 1.16V17.16c0 .64.52 1.16 1.16 1.16h15.17c.64 0 1.16-.52 1.16-1.16V6.9c0-.64-.52-1.16-1.16-1.16h-2.759a2.195 2.195 0 0 1-2.194-2.194.16.16 0 0 0-.16-.16H9.238Zm-2 .16a2 2 0 0 1 2-2h4.944a2 2 0 0 1 2 2c0 .196.159.354.354.354h2.759a3 3 0 0 1 3 3V17.16a3 3 0 0 1-3 3H4.125a3 3 0 0 1-3-3V6.9a3 3 0 0 1 3-3h2.759a.354.354 0 0 0 .354-.354Zm9.71 7.899c-.005-.54-.528-.886-1.057-.782-.53.104-.858.624-.914 1.16a3.287 3.287 0 0 1-3.503 2.938 3.284 3.284 0 0 1-.75-6.408c.514-.162.957-.59.953-1.129-.004-.539-.449-.982-.978-.878a5.237 5.237 0 1 0 6.249 5.099Zm-2.863-4.878c-.442-.225-.95.048-1.096.522-.147.474.13.968.55 1.232a3.445 3.445 0 0 1 .84.744c.314.385.837.598 1.29.395.453-.204.661-.741.384-1.152a5.233 5.233 0 0 0-1.968-1.74Z"
          fill={colors[color || "onyxDark"]}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill={colors[color || "onyxDark"]}
            transform="translate(.71 .236)"
            d="M0 0h22v22H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Camera;
