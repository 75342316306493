import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Quote = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.359 18.2703L17.1891 10.9189L19.1659 10.4247C18.96 10.8777 18.6923 11.2484 18.3628 11.5367C18.0746 11.825 17.7039 11.9691 17.2509 11.9691C16.386 11.9691 15.5829 11.619 14.8416 10.9189C14.1415 10.1776 13.7914 9.27156 13.7914 8.20077C13.7914 7.04762 14.1827 6.0592 14.9652 5.23552C15.7888 4.41184 16.7773 4 17.9304 4C19.0012 4 19.9484 4.41184 20.7721 5.23552C21.5958 6.0592 22.0076 7.04762 22.0076 8.20077C22.0076 8.6538 21.9047 9.20978 21.6988 9.86873C21.534 10.4865 21.184 11.3102 20.6486 12.3398L16.5713 20L13.359 18.2703ZM1.99219 18.2703L5.8223 10.9189L7.79914 10.4247C7.59322 10.8777 7.32552 11.2484 6.99605 11.5367C6.70776 11.825 6.3371 11.9691 5.88408 11.9691C5.01921 11.9691 4.21613 11.619 3.47481 10.9189C2.77468 10.1776 2.42462 9.27156 2.42462 8.20077C2.42462 7.04762 2.81587 6.0592 3.59837 5.23552C4.42205 4.41184 5.41046 4 6.56362 4C7.6344 4 8.58163 4.41184 9.40532 5.23552C10.229 6.0592 10.6408 7.04762 10.6408 8.20077C10.6408 8.6538 10.5379 9.20978 10.332 9.86873C10.1672 10.4865 9.81716 11.3102 9.28176 12.3398L5.20454 20L1.99219 18.2703Z"
        fill={colors[color || "spaceShade"]}
      />
    </svg>
  );
};

export default Quote;
