import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Cart = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 24 24"
      fill={colors[color || "spaceShade"]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99991 1.54565C1.49783 1.54565 1.09082 1.95267 1.09082 2.45475C1.09082 2.95682 1.49783 3.36384 1.99991 3.36384H4.89111L5.64714 7.14118C5.65117 7.16705 5.65629 7.19256 5.66245 7.21766L7.18112 14.8054C7.30594 15.4329 7.64741 15.9965 8.1458 16.3977C8.64191 16.7971 9.26207 17.0102 9.89868 17.0002H18.7193C19.3559 17.0102 19.9761 16.7971 20.4722 16.3977C20.9708 15.9963 21.3124 15.4324 21.4371 14.8046L22.8929 7.1705C22.9437 6.90436 22.8731 6.62946 22.7004 6.4207C22.5277 6.21195 22.2708 6.09111 21.9999 6.09111H7.29121L6.52769 2.27633C6.44265 1.85146 6.06957 1.54565 5.63627 1.54565H1.99991ZM8.96431 14.4504L7.65512 7.90929H20.9011L19.6531 14.4533C19.6111 14.6614 19.4975 14.8482 19.3321 14.9814C19.1659 15.1152 18.9579 15.1863 18.7446 15.1822L18.7272 15.182H9.89082L9.8734 15.1822C9.66008 15.1863 9.45212 15.1152 9.28592 14.9814C9.11972 14.8476 9.00588 14.6596 8.96431 14.4504ZM7.45462 20.6366C7.45462 19.6324 8.26865 18.8184 9.2728 18.8184C10.277 18.8184 11.091 19.6324 11.091 20.6366C11.091 21.6407 10.277 22.4548 9.2728 22.4548C8.26865 22.4548 7.45462 21.6407 7.45462 20.6366ZM17.4545 20.6366C17.4545 19.6324 18.2685 18.8184 19.2726 18.8184C20.2768 18.8184 21.0908 19.6324 21.0908 20.6366C21.0908 21.6407 20.2768 22.4548 19.2726 22.4548C18.2685 22.4548 17.4545 21.6407 17.4545 20.6366Z"
        fill="#6E7EC3"
      />
    </svg>
  );
};

export default Cart;
