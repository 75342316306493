import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Transfer = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      fill="none"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.3184 8.82069L19.9689 9.47122C20.3282 9.11194 20.3282 8.52943 19.9689 8.17015L19.3184 8.82069ZM11.6896 16.4495L10.7696 16.4495C10.7696 16.8216 10.9937 17.157 11.3375 17.2994C11.6813 17.4418 12.077 17.3631 12.3401 17.1L11.6896 16.4495ZM11.6896 1.19189L12.3401 0.541356C12.077 0.278238 11.6813 0.199527 11.3375 0.341925C10.9937 0.484323 10.7696 0.819789 10.7696 1.19189L11.6896 1.19189ZM11.6896 5.7155L11.7369 6.63429C12.2259 6.60911 12.6096 6.20523 12.6096 5.7155L11.6896 5.7155ZM1.2063 18.8265L0.286296 18.8265C0.286296 19.2911 0.632688 19.6828 1.0938 19.7396C1.55491 19.7965 1.98602 19.5006 2.09878 19.0498L1.2063 18.8265ZM11.6896 11.8345L12.6096 11.8345C12.6096 11.353 12.2383 10.9529 11.7581 10.9171L11.6896 11.8345ZM18.6678 8.17015L11.039 15.7989L12.3401 17.1L19.9689 9.47122L18.6678 8.17015ZM11.039 1.84243L18.6678 9.47122L19.9689 8.17015L12.3401 0.541356L11.039 1.84243ZM12.6096 5.7155L12.6096 1.19189L10.7696 1.19189L10.7696 5.7155L12.6096 5.7155ZM2.1263 18.8265C2.1263 14.6123 3.34203 11.64 5.105 9.69741C6.87018 7.7524 9.25323 6.76211 11.7369 6.63429L11.6423 4.79672C8.72802 4.94671 5.86944 6.11717 3.74246 8.46085C1.61326 10.807 0.286296 14.2512 0.286296 18.8265L2.1263 18.8265ZM11.7581 10.9171C10.0408 10.7889 7.63051 11.0108 5.41785 12.111C3.17167 13.2279 1.15484 15.2419 0.313808 18.6032L2.09878 19.0498C2.79657 16.261 4.42467 14.6597 6.23707 13.7586C8.08298 12.8407 10.1449 12.6417 11.6211 12.752L11.7581 10.9171ZM12.6096 16.4495L12.6096 11.8345L10.7696 11.8345L10.7696 16.4495L12.6096 16.4495Z"
        fill={colors[color || "metallicDark"]}
      />
    </svg>
  );
};

export default Transfer;
