import React, { useEffect } from "react";
import { TextField } from "../../../components";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import SelectComponent from "../../../components/Select/Select";
import {
  getFormData,
  selectFashDetails,
} from "../../../slices/receiptSlice/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  FormValues,
  updateFormValue,
} from "../../../slices/receiptSlice/receiptSlice";
import TextAreaComponent from "../../../components/TextArea/TextArea";

interface SearchBarProps {
  watch: UseFormWatch<FormValues>;
  control: Control<FormValues, any>;
  fields: FieldArrayWithId<FormValues, "products", "id">[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, "products">;
  setValue: UseFormSetValue<FormValues>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  watch,
  control,
  fields,
  remove,
  append,
  setValue,
}) => {
  const { fasis, location, brands, companies } = useSelector(selectFashDetails);
  const dispatch = useDispatch();
  const formData = useSelector(getFormData);
  useEffect(() => {
    if (formData) {
      setValue("fash", formData.fash);
      setValue("location", formData.location);
      setValue("provider", formData.provider);
      setValue("company", formData.company);
      setValue("couponNumber", formData.couponNumber);
      setValue("pickupDate", formData.pickupDate);
      setValue("couponDate", formData.couponDate);
      setValue("products", formData.products);
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Controller
        control={control}
        name="provider"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Διάλεξε Πάροχο"
            placeholder="= Επέλεξε -"
            {...field}
            items={companies}
            value={watch("provider")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "provider", value: value }));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="fash"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"180px"}
            required
            label="Διάλεξε Φάση"
            placeholder="= Επέλεξε -"
            {...field}
            items={fasis}
            value={watch("fash")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "fash", value: value }));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="company"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Διάλεξε Εταιρεία"
            placeholder="= Επέλεξε -"
            {...field}
            items={brands}
            value={watch("company")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "company", value: value }));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="location"
        render={({ field }) => (
          <SelectComponent
            maxWidth={"300px"}
            required
            label="Διάλεξε Τόπο Παραλαβή"
            placeholder="= Επέλεξε -"
            {...field}
            items={location}
            value={watch("location")}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "location", value: value }));
            }}
          />
        )}
      />

      <Controller
        name="couponNumber"
        control={control}
        render={({ field }) => (
          <TextField
            required
            maxwidth={280}
            maxLength={10}
            type="text"
            label="Αρ. Δελτίου"
            placeholder="Αριθμός Δελτίου"
            {...field}
            onChange={(value) => {
              field.onChange(value);
              dispatch(
                updateFormValue({ field: "couponNumber", value: value })
              );
            }}
          />
        )}
      />
      <Controller
        name="pickupDate"
        control={control}
        render={({ field }) => (
          <TextField
            formToday
            maxwidth={200}
            label={"Ημερομηνία Παραλαβής"}
            type="date"
            required
            placeholder="day"
            {...field}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "pickupDate", value: value }));
            }}
          />
        )}
      />
      <Controller
        name="couponDate"
        control={control}
        render={({ field }) => (
          <TextField
            formToday
            maxwidth={200}
            label={"Ημερομηνία Δελτίου"}
            type="date"
            required
            placeholder="day"
            {...field}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "couponDate", value: value }));
            }}
          />
        )}
      />
      <Controller
        name="work"
        control={control}
        render={({ field }) => (
          <TextField
            required
            maxwidth={280}
            maxLength={10}
            type="text"
            label="Έργο"
            placeholder="Έργο"
            {...field}
            onChange={(value) => {
              field.onChange(value);
              dispatch(updateFormValue({ field: "work", value: value }));
            }}
          />
        )}
      />
      <Controller
        name="notes"
        control={control}
        render={({ field }) => (
          <TextAreaComponent
            maxwidth={600}
            minWidth={500}
            height="150px"
            label={"Παρατηρήσεις"}
            type="text"
            required
            placeholder="Παρατηρήσεις"
            {...field}
            onChange={(event) => {
              field.onChange(event.target.value);
              console.log(event.target.value);
              dispatch(
                updateFormValue({ field: "notes", value: event.target.value })
              );
            }}
          />
        )}
      />
    </div>
  );
};

export default SearchBar;
