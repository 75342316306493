import React from "react";
import { Modal, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Title,
  Overlay,
  ModalWrapper,
  PrimaryButton,
  ButtonsWrapper,
  ModalContainer,
  SecondaryButton,
  ModalMessageContainer,
  FormValidationWrapper,
  CircularProgressWrapper,
} from "./ModalContent.style";
import { getDesktopNavbarVisibility } from "../../slices/desktopMode/selectors";
import { GenericModalProperties } from "../../slices/modalSlice/types";
import { getModalButtonLoader } from "../../slices/modalSlice/selectors";
import Link from "../atoms/Link/Link";

interface Props {
  params: GenericModalProperties;
}

const ModalContent: React.FC<Props> = ({ params }) => {
  const dispatch = useDispatch();
  const desktopMode = useSelector(getDesktopNavbarVisibility);
  // const enableLoaderSelector = useSelector(getLoaderStatusModal);

  const {
    title = "",
    message = "",
    onClose,
    primaryLabel,
    primaryOnPress,
    secondaryLabel,
    isNotice = false,
    secondaryOnPress,
    simpleOnPress,
    color = "primary",
    enableLoader = false,
    formComponent,
    disableBackdropClick = false,
    enableProgressBar = false,
  } = params;

  const ModalButtonLoader = useSelector(getModalButtonLoader);

  const infoModal = primaryLabel === undefined || secondaryLabel === undefined; // info modal is the modal with no primary&secondary buttons hence a Close button is automatically displayed

  const closeModal = () => {
    if (!disableBackdropClick) {
      dispatch(closeGenericModalAction());
      if (onClose) {
        onClose();
      }
    } else {
      if (primaryOnPress) primaryOnPress();
    }
  };

  const modalContent = (
    <ModalWrapper data-test="global_modal">
      {enableLoader ? (
        <div>
          <CircularProgress size={80} />
        </div>
      ) : (
        <>
          <Overlay onClick={closeModal} />
          <ModalContainer
            disableTextAlign={params.disableTextAlign}
            width={params.width}
          >
            <Title color="metallicPearlWhite">{title}</Title>
            {isNotice ? (
              <Link
                color={color === "candyRed" ? color : "primary"}
                icon="notice"
                margin={7}
              >
                {message}
              </Link>
            ) : (
              <ModalMessageContainer
                color={color === "candyRed" ? color : "secondary"}
              >
                {message}
              </ModalMessageContainer>
            )}
            {formComponent && (
              <FormValidationWrapper>{formComponent}</FormValidationWrapper>
            )}
            {enableProgressBar && (
              <CircularProgressWrapper>
                <CircularProgress size={44} />
              </CircularProgressWrapper>
            )}
            {!enableProgressBar && (
              <ButtonsWrapper>
                {infoModal ? (
                  <div style={{ maxWidth: 320 }}>
                    <PrimaryButton
                      data-test="global_modal_close_button"
                      size="small"
                      variant="contained"
                      fullWidth
                      onClick={simpleOnPress || closeModal}
                      backgroundColor={color || "secondary"}
                      colour="metallicPearlWhite"
                    >
                      {primaryLabel}
                    </PrimaryButton>
                  </div>
                ) : (
                  <>
                    <SecondaryButton
                      data-test="global_modal_close_button"
                      size="medium"
                      variant="outlined"
                      color="primary"
                      onClick={secondaryOnPress || closeModal}
                    >
                      {secondaryLabel}
                    </SecondaryButton>
                    <PrimaryButton
                      size="small"
                      variant="contained"
                      backgroundColor={color}
                      colour={
                        color === "candyRed"
                          ? "metallicPearlWhite"
                          : "metallicDark"
                      }
                      onClick={() =>
                        ModalButtonLoader
                          ? null
                          : primaryOnPress && primaryOnPress()
                      }
                    >
                      {ModalButtonLoader ? (
                        <CircularProgress color={"inherit"} size={22} />
                      ) : (
                        primaryLabel
                      )}
                    </PrimaryButton>
                  </>
                )}
              </ButtonsWrapper>
            )}
          </ModalContainer>
        </>
      )}
    </ModalWrapper>
  );

  return (
    <Modal
      open={true}
      onClose={closeModal}
      hideBackdrop
      disableRestoreFocus={true}
    >
      {modalContent}
    </Modal>
  );
};

export default ModalContent;
function closeGenericModalAction(): any {
  throw new Error("Function not implemented.");
}
