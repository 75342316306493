import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Level2 = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1H4ZM8.07031 18H16.0547V15.9688H11.0781V15.8594L12.5469 14.4766C13.526 13.5807 14.2083 12.8906 14.5938 12.4062C14.9844 11.9219 15.2708 11.4505 15.4531 10.9922C15.6406 10.5286 15.7344 10.0339 15.7344 9.50781C15.7344 8.90365 15.5833 8.36719 15.2812 7.89844C14.9792 7.42969 14.5547 7.0651 14.0078 6.80469C13.4609 6.54427 12.8307 6.41406 12.1172 6.41406C11.5755 6.41406 11.0859 6.46875 10.6484 6.57812C10.2109 6.68229 9.80729 6.83333 9.4375 7.03125C9.06771 7.22917 8.60156 7.56771 8.03906 8.04688L9.35156 9.60156C9.80469 9.22656 10.2396 8.9375 10.6562 8.73438C11.0729 8.53125 11.5026 8.42969 11.9453 8.42969C12.3672 8.42969 12.7031 8.54167 12.9531 8.76562C13.2083 8.98958 13.3359 9.33073 13.3359 9.78906C13.3359 10.0964 13.2786 10.3932 13.1641 10.6797C13.0495 10.9661 12.862 11.2786 12.6016 11.6172C12.3411 11.9505 11.7865 12.5521 10.9375 13.4219L8.07031 16.3203V18Z"
        fill={colors[color || "spaceShade"]}
      />
    </svg>
  );
};

export default Level2;
