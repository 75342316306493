import { RootState } from "../store";
import { createSelector } from "reselect";

// Selector to get multiple parts of FashState as an object
export const __REDUX_STATE_KEY__ = "dashboardAdminSlice";
export const getReduxStateSlice = (state: RootState) =>
  state[__REDUX_STATE_KEY__];

export const getTabOption = (state: RootState) => {
  return getReduxStateSlice(state).tabOption;
};

export const getWareHouseLogData = (state: RootState) =>
  getReduxStateSlice(state).wareHouseLogData;
export const getWareHouseLogStatus = (state: RootState) =>
  getReduxStateSlice(state).wareHouseLogStatus;

export const getReceiptsLogData = (state: RootState) =>
  getReduxStateSlice(state).receiptsLogData;
export const getReceiptsLogStatus = (state: RootState) =>
  getReduxStateSlice(state).receiptsLogStatus;
export const getTransferLogData = (state: RootState) =>
  getReduxStateSlice(state).transferLogData;
export const getTransferLogStatus = (state: RootState) =>
  getReduxStateSlice(state).transferLogStatus;

export const getTableOffset = (state: RootState) =>
  getReduxStateSlice(state).tableOffset;

export const getTablePage = (state: RootState) =>
  getReduxStateSlice(state).tablePage;

export const selectDashboardData = createSelector(
  getTabOption,
  getWareHouseLogData,
  getWareHouseLogStatus,
  getReceiptsLogData,
  getReceiptsLogStatus,
  getTransferLogData,
  getTransferLogStatus,
  getTableOffset,
  getTablePage,
  (
    tabOption,
    wareHouseLogData,
    wareHouseLogStatus,
    receiptsLogData,
    receiptsLogStatus,
    transferLogData,
    transferLogStatus,
    tableOffset,
    tablePage
  ) => {
    return {
      tabOption,
      wareHouseLogData,
      wareHouseLogStatus,
      receiptsLogData,
      receiptsLogStatus,
      transferLogData,
      transferLogStatus,
      tableOffset,
      tablePage,
    };
  }
);
