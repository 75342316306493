import { RootState } from "../store";
import { createSelector } from "reselect";

// Selector to get multiple parts of FashState as an object
export const __REDUX_STATE_KEY__ = "dashboard";
export const getReduxStateSlice = (state: RootState) =>
  state[__REDUX_STATE_KEY__];
export const getBuildings = (state: RootState) => {
  return getReduxStateSlice(state).buildings;
};

export const getTotalBuildings = (state: RootState) => {
  return getReduxStateSlice(state).totalBuildings;
};
export const getExpandedRow = (state: RootState) => {
  return getReduxStateSlice(state).expandedRow;
};

export const getBuildingsPage = (state: RootState) => {
  return getReduxStateSlice(state).buildingsPage;
};

export const getDashboardDetails = (state: RootState) => {
  return getReduxStateSlice(state).dashboardDetails;
};

export const getAddBuildingDetails = (state: RootState) => {
  return getReduxStateSlice(state).addBuildingDetails;
};

export const getBuildingOffset = (state: RootState) => {
  return getReduxStateSlice(state).getBuildingsOffset;
};

export const addBuildingStatus = (state: RootState) =>
  getReduxStateSlice(state).addBuildingStatus;
export const addBuildingError = (state: RootState) =>
  getReduxStateSlice(state).addBuildingError;
export const getDashboardDetailsStatus = (state: RootState) =>
  getReduxStateSlice(state).getDashboardDetailsStatus;
export const getDashboardDetailsError = (state: RootState) =>
  getReduxStateSlice(state).getDashboardDetailsError;
export const getBuildingsStatus = (state: RootState) =>
  getReduxStateSlice(state).getBuildingsStatus;
export const getBuildingsError = (state: RootState) =>
  getReduxStateSlice(state).getBuildingsError;
export const updateBuildingStatus = (state: RootState) =>
  getReduxStateSlice(state).updateBuildingStatus;
export const updateBuildingError = (state: RootState) =>
  getReduxStateSlice(state).updateBuildingError;

export const selectBuildingsDetails = createSelector(
  getBuildings,
  getDashboardDetails,
  getBuildingOffset,
  getTotalBuildings,
  (buildings, dashboardDetails, getBuildingOffset, totalBuildings) => {
    return {
      buildings: buildings,
      cities: dashboardDetails.cities,
      construction_crews: dashboardDetails.construction_crew,
      statuses: dashboardDetails.statuses,
      offset: getBuildingOffset,
      totalBuildings: totalBuildings,
    };
  }
);

export const selectAddBuildingDetails = createSelector(
  getAddBuildingDetails,
  (details) => {
    return {
      suppliers: details.suppliers,
      cities: details.cities,
      providers: details.providers,
    };
  }
);
