import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { GenericModalProperties } from "../modalSlice/types";
import { closeGenericModal, openGenericModal } from "../modalSlice/modalSlice";
import warehouseAPI from "../../providers/warehouseAPI";
import { Product } from "../productsSlice/productsSlice";
import contructionCrewAPI from "../../providers/contructionCrewAPI";

export interface FormValues {
  fash?: { title: string; id: string };
  from?: { title: string; id: string };
  to_construction_crew?: { title: string; id: string };
  provider?: { title: string; id: string };
  products: { productId: number; quantity: string }[];
  date?: string;
}

export interface ApiResponse {
  fasi: { id: string; title: string }[];
  location: { id: string; title: string }[];
  types: { title: string }[];
  companies: { title: string; id: string }[];
  constructor_crew: { title: string; id: string }[];
}

export interface ReceiptTransferDetails {
  fasis: { title: string }[];
  location: { id: string; title: string }[];
  typos: { title: string }[];
  companies: { title: string; id: string }[];
  constructor_crew: { title: string; id: string }[];
  products: Product[];
  productsStatus: "idle" | "loading" | "succeeded" | "failed";
  productsError: SerializedError | null;
  formValues: FormValues;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
}

export const initialState: ReceiptTransferDetails = {
  fasis: [],
  location: [],
  typos: [],
  companies: [],
  products: [],
  constructor_crew: [],
  productsStatus: "loading",
  productsError: null,
  status: "loading",
  error: null,
  formValues: {
    fash: { title: "", id: "" },
    from: { title: "", id: "" },
    to_construction_crew: { title: "", id: "" },
    provider: { title: "", id: "" },
    products: [],
    date: "",
  },
};

export const getReceiptTranserProductsDetails = createAsyncThunk(
  "transferProuctsToCrew/getReceiptTranserProductsDetails",
  async (_, thunkAPI) => {
    const { request } =
      contructionCrewAPI.single.getReceiptTranserProductsDetails();
    return request()
      .then((response: ApiResponse) => {
        return response;
      })

      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const transferProductsToContrustionCrew = createAsyncThunk(
  "transferProuctsToCrew/transferProductsToContrustionCrew",
  async (params: { transferData: any; navigate: any }, thunkAPI) => {
    const { request } =
      contructionCrewAPI.single.transferProductsToContrustionCrew(
        params.transferData
      );
    const modalParamsLoader: GenericModalProperties = {
      title: "ΑΝΑΜΟΝΗ",
      enableProgressBar: true,
    };
    thunkAPI.dispatch(openGenericModal(modalParamsLoader));
    return request()
      .then((response: any) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "transfer_document.pdf";
        link.click();
        thunkAPI.dispatch(closeGenericModal());

        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η μεταφορά έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            params.navigate("/");
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));

        return response;
      })
      .catch((error) => {
        console.log(error);
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.response?.data?.error || error.message,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(
          error.response?.data?.error || error.message
        );
      });
  }
);

export const getReceiptTransferProducts = createAsyncThunk(
  "transferProuctsToCrew/getReceiptTransferProducts",
  async (params: any, thunkAPI) => {
    const { request } = warehouseAPI.single.getReceiptTransferProducts(params);
    return request()
      .then((response: Product[]) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

const transferProuctsToCrewSlice = createSlice({
  name: "transferProuctsToCrew",
  initialState: initialState,
  reducers: {
    clearProducts(state) {
      state.products = [];
    },
    updateFormValueCrew: (
      state,
      action: PayloadAction<{ field: keyof FormValues; value: any }>
    ) => {
      state.formValues![action.payload.field] = action.payload.value;
    },
    setFormValues: (state, action: PayloadAction<FormValues>) => {
      state.formValues = { ...state.formValues, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReceiptTranserProductsDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      getReceiptTranserProductsDetails.fulfilled,
      (state, action: PayloadAction<ApiResponse>) => {
        state.status = "succeeded";
        state.fasis = action.payload.fasi;
        state.location = action.payload.location;
        state.typos = action.payload.types;
        state.companies = action.payload.companies;
        state.constructor_crew = action.payload.constructor_crew;
      }
    );
    builder.addCase(
      getReceiptTranserProductsDetails.rejected,
      (state, action) => {
        state.status = "failed";
        state.error = action.error;
      }
    );
    builder.addCase(getReceiptTransferProducts.pending, (state) => {
      state.productsStatus = "loading";
    });
    builder.addCase(
      getReceiptTransferProducts.fulfilled,
      (state, action: PayloadAction<Product[]>) => {
        state.products = action.payload;
        state.productsStatus = "succeeded";
      }
    );
    builder.addCase(getReceiptTransferProducts.rejected, (state, action) => {
      state.productsStatus = "failed";
      state.productsError = action.error;
    });
    builder.addCase(
      transferProductsToContrustionCrew.fulfilled,
      (state, action) => {
        state.formValues = initialState.formValues;
        state.products = initialState.products;
      }
    );
  },
});

const { actions, reducer } = transferProuctsToCrewSlice;
export const { setFormValues, updateFormValueCrew } = actions;
export default reducer;
