/** @jsxImportSource @emotion/react */
import React, { useRef, useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  DeleteIcon,
  FileBtn,
  FileNameStyle,
  SelectedFileStyle,
  UploadIcon,
} from "./uploadFile.style";
import Icon from "../Icon/Icon";

interface FileInputProps {
  name: string;
}

const FileInput: React.FC<FileInputProps> = ({ name }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { control, setValue, clearErrors } = useFormContext();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (selectedFile) {
      setValue(name, selectedFile);
      clearErrors(name);
    }
  }, [selectedFile, setValue, clearErrors, name]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const onChooseFile = () => {
    inputRef.current?.click();
  };

  const removeFile = () => {
    setSelectedFile(null);
    setValue(name, "");
  };

  return (
    <div style={{ maxWidth: "250px" }}>
      <input
        type="file"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />

      <FileBtn type="button" onClick={onChooseFile}>
        <Icon name="upload-icon"></Icon> Ανέβασμα Αρχείου
      </FileBtn>

      {selectedFile && (
        <SelectedFileStyle>
          <FileNameStyle>{selectedFile.name}</FileNameStyle>
          <DeleteIcon type="button" onClick={removeFile}>
            <Icon color="primary" name="cancel"></Icon>
          </DeleteIcon>
        </SelectedFileStyle>
      )}
    </div>
  );
};

export default FileInput;
