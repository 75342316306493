import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Link = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.391 4.49388C17.5936 2.69649 14.679 2.69813 12.8836 4.49754L10.7819 6.60402C10.423 6.96371 10.4237 7.54622 10.7833 7.9051C11.143 8.26397 11.7255 8.26332 12.0844 7.90363L14.1862 5.79715C15.2632 4.71769 17.0117 4.71671 18.09 5.79496L18.4474 6.15238C19.3274 7.03237 19.3274 8.45913 18.4474 9.33912C18.0881 9.6984 18.0881 10.2809 18.4474 10.6402C18.8067 10.9995 19.3892 10.9995 19.7484 10.6402C21.347 9.04164 21.347 6.44986 19.7484 4.8513L19.391 4.49388ZM17.5045 12.8841C17.8638 12.5248 17.8638 11.9423 17.5045 11.583C17.1453 11.2238 16.5627 11.2238 16.2035 11.583L15.6963 12.0902C14.6181 13.1684 12.87 13.1684 11.7917 12.0902C11.4325 11.731 10.85 11.731 10.4907 12.0902C10.1314 12.4495 10.1314 13.032 10.4907 13.3913C12.2874 15.1881 15.2006 15.1881 16.9973 13.3913L17.5045 12.8841ZM4.7205 19.1351C6.51789 20.9324 9.43254 20.9308 11.2279 19.1314L13.3296 17.0249C13.6885 16.6652 13.6879 16.0827 13.3282 15.7238C12.9685 15.365 12.386 15.3656 12.0271 15.7253L9.92536 17.8318C8.84832 18.9112 7.09983 18.9122 6.02158 17.834C4.94419 16.7566 4.94419 15.0098 6.02158 13.9324L8.41527 11.5387C9.49347 10.4605 11.2416 10.4605 12.3198 11.5387C12.6791 11.898 13.2616 11.898 13.6209 11.5387C13.9801 11.1794 13.9801 10.5969 13.6209 10.2376C11.8241 8.44085 8.91096 8.44086 7.11419 10.2376L4.7205 12.6313C2.92455 14.4273 2.92455 17.3391 4.7205 19.1351Z"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default Link;
