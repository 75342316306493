import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "./components/Card/Card";
import { CardData } from "./components/Card/types";
import { Wrapper } from "./Home.style";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const cardData: CardData[] = [
    {
      title: "ΠΡΟΣΘΗΚΗ ΠΑΡΑΛΑΒΗΣ",
      icon: "receipt",
      path: "/addReceipt",
    },
    {
      title: "ΜΕΤΑΚΙΝΗΣΗ ΠΡΟΙΟΝΤΩΝ",
      icon: "transferInventory",
      path: "/transferProduct",
    },
    {
      title: "ΜΕΤΑΚΙΝΗΣΗ ΠΡΟΣ ΣΥΝΕΡΓΕΙΟ",
      icon: "van",
      path: "/transferToConstructionCrew",
    },
    {
      title: "ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΣ",
      icon: "schedule",
      path: "/dashboard",
    },
    {
      title: "ΠΙΝΑΚΑΣ ΕΛΕΓΧΟΥ",
      icon: "monitor",
      path: "/dashboard/monitor",
    },
    {
      title: "ΠΡΟΙΟΝΤΑ",
      icon: "dashboard",
      path: "/dashboard/products",
    },
  ];

  return (
    <Wrapper>
      {cardData.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          icon={card.icon}
          onClick={() => navigate(card.path)}
        />
      ))}
    </Wrapper>
  );
};

export default Home;
