import { css } from "@emotion/react";
import styled from "@emotion/styled";
import ButtonBase from "@mui/material/ButtonBase";
import { SizesType, ModesType } from "./Button";
import { ColorsType } from "../../theme/types";

const CTButtonSizeWidthResolver = (size: SizesType) => {
  switch (size) {
    case "auto":
      return css`
        width: auto;
      `;
    case "mini":
      return css`
        width: 90px;
      `;
    case "small":
      return css`
        width: 70px;
      `;
    case "medium":
      return css`
        width: 152px;
      `;
    case "stretch":
    case "stretch-small":
      return css`
        width: auto;
      `;
    case "large":
      return css`
        width: 100%;
      `;
    case "semi-large":
      return css`
        max-width: 280px;
      `;
  }
};

const CTButtonSizeHeightResolver = (size: SizesType) => {
  switch (size) {
    case "none":
      return "0px";

    case "small":
    case "stretch-small":
      return "40px";
    case "mini":
    case "large":
    case "medium":
    case "stretch":
      return "48px";
  }
};

type StyledButtonType = {
  size: SizesType;
  backgroundColor: ColorsType;
  mode: ModesType;
  color: ColorsType;
  gap?: number;
  fontSize?: number;
  disabled?: boolean;
  padding?: number;
  isMobileEnabled?: boolean;
  justifyContent?: string;
  border?: string;
  borderRadius?: number;
  theme?: any;
};

const ButtonStyleModeResolver = ({
  mode,
  color,
  backgroundColor,
  theme,
}: StyledButtonType) => {
  switch (mode) {
    case "contained":
      return css`
        background: ${theme.colors[backgroundColor]};
        color: ${theme.colors[color]};
      `;
    case "outlined":
      return css`
        border: 3px solid ${theme.colors[backgroundColor]};
        color: ${theme.colors[color]};
      `;
  }
};

export const StyledButton = styled(ButtonBase)<StyledButtonType>`
  && {
    ${(props: StyledButtonType) =>
      props.disabled &&
      `opacity: 0.4; pointer-events: none; background-color: ${props.backgroundColor};`}
    ${(props: StyledButtonType) =>
      ButtonStyleModeResolver({ ...props, theme: props.theme })};
    ${(props: StyledButtonType) => CTButtonSizeWidthResolver(props.size)};
    height: ${(props: StyledButtonType) =>
      CTButtonSizeHeightResolver(props.size)};
    gap: ${(props: StyledButtonType) => (props.gap ? props.gap : "0")}px;
    ${(props) => props.border && `border: ${props.border}`};
    border-radius: ${(props: StyledButtonType) =>
      props.borderRadius ? props.borderRadius : "10"}px;
    justify-content: ${(props: StyledButtonType) =>
      props.justifyContent ? props.justifyContent : "none"};
    font-family: "DM Sans", sans-serif;
    font-size: ${(props: StyledButtonType) =>
      props.fontSize ? props.fontSize : "12"}px;
    font-weight: 700;
    cursor: pointer;
    line-height: 21px;
    padding: ${(props: StyledButtonType) =>
        Number.isInteger(props.padding) ? `${props.padding}` : "15"}px
      0px;
    ${(props) =>
      props.isMobileEnabled &&
      `@media only screen and (max-width: ${props.theme.breakPoints.EXTRA_SMALL_VIEWPORT_IPHONE_X_PLUS}) {
      width: 70px;
    }`};
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  jusify-content: center;
  flex-direction: verical;
`;
