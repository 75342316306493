import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import crewDashboardAPI from "../../providers/crewDashboardAPI";
import { GenericModalProperties } from "../modalSlice/types";
import { closeGenericModal, openGenericModal } from "../modalSlice/modalSlice";

// Define TypeScript interfaces for responses
export interface Product {
  product_id: number;
  kay: string;
  product_name: string;
  mm: string;
  fash: string;
  typos: string;
  supplier_id: number;
}

export interface Building {
  id: number;
  address: string;
  number: string;
  timestamp: string;
  city: {
    id: string;
    title: string;
  };
  construction_crew: {
    name: string;
  };
  status: {
    overall_status: string;
  };
}

// Interface for Thunk Responses
interface GetBuildingsAsCrewResponse {
  success: boolean;
  data: Building[];
  totalBuildings: number;
}

interface GetProductsAsCrewResponse {
  success: boolean;
  products: Product[];
  status: number;
}

// Interface for Slice State
interface CrewDashboardState {
  buildings: Building[];
  totalBuildings: number;
  products: Product[];
  buildingsStatus: "idle" | "loading" | "succeeded" | "failed";
  productsStatus: "idle" | "loading" | "succeeded" | "failed";
  updateBuildingStatus: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
  buildingsOffset: number;
  buildingsLimit: number;
  buildingsPage: number;
}

const initialState: CrewDashboardState = {
  buildings: [],
  totalBuildings: 0,
  products: [],
  buildingsStatus: "idle",
  productsStatus: "idle",
  updateBuildingStatus: "idle",
  error: null,
  buildingsOffset: 0,
  buildingsLimit: 10, // Default limit
  buildingsPage: 1, // Default page
};

export const getBuildingsAsCrew = createAsyncThunk(
  "crewDashboardSlice/getBuildingsAsCrew",
  async (params: { offset: number; limit: number }, thunkAPI) => {
    const { request } = crewDashboardAPI.single.getBuildingsAsCrew(params);
    return request()
      .then((response: GetBuildingsAsCrewResponse) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const getProductsAsCrew = createAsyncThunk(
  "crewDashboardSlice/getProductsAsCrew",
  async (_, thunkAPI) => {
    const { request } = crewDashboardAPI.single.getProductsAsCrew();
    return request()
      .then((response: GetProductsAsCrewResponse) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const updateBuildingStatusAsCrew = createAsyncThunk(
  "crewDashboardSlice/updateBuildingStatusAsCrew",
  async (
    statusData: {
      building_id: string | undefined;
      products: any[];
    },
    thunkAPI
  ) => {
    const { request } =
      crewDashboardAPI.single.updateBuildingStatusAsCrew(statusData);

    return request()
      .then((response: any) => {
        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η Αλλαγή της Πολυκατοικίας έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: async () => {
            thunkAPI.dispatch(closeGenericModal());
            await thunkAPI.dispatch(getProductsAsCrew());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.error);
      });
  }
);

const crewDashboardSlice = createSlice({
  name: "crewDashboardSlice",
  initialState,
  reducers: {
    setBuildingsOffset(state, action: PayloadAction<number>) {
      state.buildingsOffset = action.payload;
    },
    setBuildingsLimit(state, action: PayloadAction<number>) {
      state.buildingsLimit = action.payload;
    },
    setBuildingsPage(state, action: PayloadAction<number>) {
      state.buildingsPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBuildingsAsCrew.pending, (state) => {
      state.buildingsStatus = "loading";
    });
    builder.addCase(
      getBuildingsAsCrew.fulfilled,
      (state, action: PayloadAction<GetBuildingsAsCrewResponse>) => {
        state.buildingsStatus = "succeeded";
        state.buildings = action.payload.data;
        state.totalBuildings = action.payload.totalBuildings;
      }
    );
    builder.addCase(getBuildingsAsCrew.rejected, (state, action) => {
      state.buildingsStatus = "failed";
      state.error = action.error;
    });

    builder.addCase(getProductsAsCrew.pending, (state) => {
      state.productsStatus = "loading";
    });
    builder.addCase(
      getProductsAsCrew.fulfilled,
      (state, action: PayloadAction<GetProductsAsCrewResponse>) => {
        state.productsStatus = "succeeded";
        state.products = action.payload.products;
      }
    );
    builder.addCase(getProductsAsCrew.rejected, (state, action) => {
      state.productsStatus = "failed";
      state.error = action.error;
    });

    builder.addCase(updateBuildingStatusAsCrew.pending, (state) => {
      state.updateBuildingStatus = "loading";
    });
    builder.addCase(
      updateBuildingStatusAsCrew.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateBuildingStatus = "succeeded";
      }
    );
    builder.addCase(updateBuildingStatusAsCrew.rejected, (state, action) => {
      state.updateBuildingStatus = "failed";
      state.error = action.error;
    });
  },
});

export const { setBuildingsOffset, setBuildingsLimit, setBuildingsPage } =
  crewDashboardSlice.actions;

export default crewDashboardSlice.reducer;
