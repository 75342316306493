import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const RetrieveTicket = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      fill="none"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.61035 5.29361C2.9697 5.29361 2.45035 5.81296 2.45035 6.45361V8.00193C3.90569 8.1246 5.04862 9.34472 5.04862 10.8318V12.8448C5.04862 14.3319 3.90569 15.552 2.45035 15.6747V17.223C2.45035 17.8637 2.9697 18.383 3.61035 18.383H19.6103C20.251 18.383 20.7703 17.8637 20.7703 17.223V15.6548C19.3984 15.4541 18.3449 14.2725 18.3449 12.8448V10.8318C18.3449 9.40412 19.3984 8.22249 20.7703 8.02183V6.45361C20.7703 5.81296 20.251 5.29361 19.6103 5.29361H3.61035ZM3.61035 3.45361C1.9535 3.45361 0.610352 4.79676 0.610352 6.45361V8.83179C0.610352 9.38407 1.05807 9.83179 1.61035 9.83179H2.20862C2.7609 9.83179 3.20862 10.2795 3.20862 10.8318V12.8448C3.20862 13.3971 2.7609 13.8448 2.20862 13.8448H1.61035C1.05807 13.8448 0.610352 14.2926 0.610352 14.8448V17.223C0.610352 18.8799 1.9535 20.223 3.61035 20.223H19.6103C21.2672 20.223 22.6103 18.8799 22.6103 17.223V14.8448C22.6103 14.2926 22.1626 13.8448 21.6103 13.8448H21.1849C20.6326 13.8448 20.1849 13.3971 20.1849 12.8448V10.8318C20.1849 10.2795 20.6326 9.83179 21.1849 9.83179H21.6103C22.1626 9.83179 22.6103 9.38407 22.6103 8.83179V6.45361C22.6103 4.79676 21.2672 3.45361 19.6103 3.45361H3.61035Z"
        fill={colors[color || "candyRed"]}
      />
      <rect
        x="4.73975"
        y="1.854"
        width="1.84"
        height="23.9105"
        rx="0.92"
        transform="rotate(-30 4.73975 1.854)"
        fill={colors[color || "candyRed"]}
      />
    </svg>
  );
};

export default RetrieveTicket;
