import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Apple = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      fill="none"
      viewBox="0 0 22 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1155 14.3602C18.1522 18.254 21.577 19.5497 21.6149 19.5663C21.5859 19.6577 21.0677 21.4129 19.8106 23.2259C18.7238 24.7933 17.596 26.355 15.8192 26.3873C14.0734 26.4191 13.512 25.3657 11.516 25.3657C9.52068 25.3657 8.89696 26.355 7.24435 26.4191C5.52935 26.4831 4.22339 24.7241 3.12763 23.1624C0.888591 19.9679 -0.822501 14.1355 1.47506 10.1986C2.61644 8.24347 4.65618 7.00542 6.87013 6.97367C8.55421 6.94197 10.1438 8.09177 11.1733 8.09177C12.2022 8.09177 14.1338 6.70903 16.1645 6.9121C17.0147 6.94702 19.401 7.25099 20.9333 9.4644C20.8098 9.53993 18.0859 11.1048 18.1155 14.3602V14.3602ZM14.8345 4.79867C15.745 3.71103 16.3578 2.19694 16.1906 0.69043C14.8782 0.742484 13.2912 1.55349 12.3498 2.64053C11.5061 3.60315 10.7673 5.14389 10.9666 6.62059C12.4295 6.73228 13.9239 5.887 14.8345 4.79867"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default Apple;
