import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { AppDispatch } from "../../slices/store";
import {
  FormValues,
  fetchFash,
  updateFormValue,
} from "../../slices/receiptSlice/receiptSlice";
import { getProducts, getStatus } from "../../slices/productsSlice/selectors";
import { getProductsByFash } from "../../slices/productsSlice/productsSlice";
import {
  getDataStatus,
  getFormData,
  selectFashDetails,
} from "../../slices/receiptSlice/selectors";
import {
  getBuildingsAsCrew,
  getProductsAsCrew,
} from "../../slices/crewDashboardSlice/crewDashboardSlice";
import Button from "../../components/Button/Button";
import { TextWorkSans } from "../../components/common.style";
import TableComponent from "./Table";
import {
  getBuildingsOffset,
  getBuildingsStatus,
} from "../../slices/crewDashboardSlice/selectors";
import CircularProgress from "@mui/material/CircularProgress";

const CrewDashboard: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { control, watch, setValue, handleSubmit, getValues, resetField } =
    useForm<FormValues>({
      defaultValues: {
        fash: undefined,
        provider: undefined,
        company: undefined,
        location: undefined,
        couponNumber: "",
        products: undefined,
        pickupDate: "",
        couponDate: "",
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const watchProducts = useWatch({ control, name: "products" }) as {
    productId: number;
    quantity: string;
  }[];

  const status = useSelector(getBuildingsStatus);
  const getProductsArray = useSelector(getProducts);
  const formData = useSelector(getFormData);
  const dataStatus = useSelector(getBuildingsStatus);
  const offset = useSelector(getBuildingsOffset);
  const limit = 10;

  useEffect(() => {
    const params = {
      // ...(city && { id_city: city?.id }),
      // ...(status && { overall_status_id: status?.id }),
      // ...(crew && { id_construction_crew: crew?.id }),
      offset: offset,
      limit: limit,
    };
    dispatch(getBuildingsAsCrew(params));
  }, []);

  useEffect(() => {
    //dispatch(fetchFash());
    dispatch(getProductsAsCrew());
  }, [dispatch]);

  useEffect(() => {
    const allValues = getValues();
    dispatch(
      updateFormValue({
        field: "products",
        value: allValues.products
          ? allValues.products.map((product) => ({ ...product }))
          : [],
      })
    );
  }, [watchProducts]);

  useEffect(() => {
    if (status === "succeeded") {
      updateFieldsBasedOnSelection();
    }
  }, [status]);

  useEffect(() => {
    if (watch("fash") && watch("fash").title === "") return;
    if (watch("fash") === undefined || watch("provider") === undefined) return;
    const params = {
      fash: watch("fash").title,
      providerId: watch("provider").id,
    };
    dispatch(getProductsByFash(params));
  }, [watch("fash"), watch("provider")]);

  const updateFieldsBasedOnSelection = () => {
    resetField("products");
    getProductsArray.forEach((product) => {
      const matchingProduct = formData.products.find(
        (p) => p.productId === product.product_id
      );
      const quantityToAppend = matchingProduct ? matchingProduct.quantity : "";
      append({
        productId: product.product_id,
        quantity: quantityToAppend,
      });
    });
  };

  //   const allFieldsFilled = (): boolean => {
  //     const values = watch();
  //     return (
  //       values.fash &&
  //       values.provider &&
  //       values.location &&
  //       values.company &&
  //       values.couponNumber &&
  //       values.products.length > 0 &&
  //       values.pickupDate &&
  //       values.couponDate
  //     );
  //   };

  const onSubmit = (data: any) => {
    history("/addReceipt/preview");
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };

  const [previewPage, setPreviewPage] = useState<boolean>(false);
  const { fasis, location, brands, companies } = useSelector(selectFashDetails);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      style={{
        display: "flex",
        gap: "40px",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",

        padding: "40px",
        width: "100%",
        maxWidth: "1300px",
        marginTop: "40px",
        marginBottom: "40px",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
      }}
    >
      <div
        style={{
          marginTop: "25px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextWorkSans fontSize={25} color={"secondary"}>
          {" ΔΙΑΧΕΙΡΙΣΗ ΠΟΛΥΚΑΤΟΙΚΙΩΝ "}
        </TextWorkSans>
        <div style={{ marginTop: "auto" }}>
          <Button
            padding={0}
            size="medium"
            backgroundColor="primary"
            color="white"
            //disabled={!allFieldsFilled()}
            type="submit"
            fontSize={12}
          >
            {previewPage ? " ΠΡΟΣΘΗΚΗ ΠΑΡΑΛΑΒΗΣ " : " ΕΠΙΘΕΩΡΗΣΗ"}
          </Button>
        </div>
      </div>

      {dataStatus === "loading" ? (
        <CircularProgress color={"primary"} size={50} />
      ) : (
        <>
          <TableComponent
            watch={watch}
            control={control}
            fields={fields}
            remove={remove}
            append={append}
            setValue={setValue}
            getValues={getValues}
          />
        </>
      )}
    </form>
  );
};

export default CrewDashboard;
