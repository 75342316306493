import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const CheckMark = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "17"}
      height={height ? height : "17"}
      fill="none"
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1821 10.757C19.1821 15.2758 15.5189 18.939 11.0001 18.939C6.48125 18.939 2.81803 15.2758 2.81803 10.757C2.81803 6.23815 6.48125 2.57492 11.0001 2.57492C15.5189 2.57492 19.1821 6.23815 19.1821 10.757ZM21.0221 10.757C21.0221 16.292 16.5351 20.779 11.0001 20.779C5.46504 20.779 0.978027 16.292 0.978027 10.757C0.978027 5.22194 5.46504 0.734924 11.0001 0.734924C16.5351 0.734924 21.0221 5.22194 21.0221 10.757ZM15.9723 7.47739C16.2172 7.10968 16.1177 6.61302 15.75 6.36807C15.3823 6.12313 14.8856 6.22265 14.6407 6.59036L10.2219 13.2239L6.89186 9.89244C6.57951 9.57995 6.07297 9.57984 5.76049 9.89219C5.448 10.2045 5.44789 10.7111 5.76024 11.0236L9.78055 15.0456L10.4708 15.7362L11.0122 14.9235L15.9723 7.47739Z"
        fill={colors[color || "white"]}
      />
    </svg>
  );
};

export default CheckMark;
