import React from "react";

import { TouchableContainer, SVGWrapper } from "./Link.style";
import { ColorsType } from "../../../theme/types";
import { IconType } from "../../Icon/Icon.interface";
import { Icon } from "../..";
import { TextWorkSans } from "../../common.style";

type Props = {
  color?: ColorsType;
  icon?: IconType;
  margin?: number;
  width?: number;
  height?: number;
  onPress?: () => void;
  disabled?: boolean;
  children?: any;
  regularFontSize?: boolean;
};
const Link: React.FC<Props> = ({
  color = "primary",
  margin: marginRight = 0,
  width = 22,
  height = 22,
  icon,
  onPress: handlePress,
  disabled = false,
  children,
  regularFontSize,
}) => {
  return (
    <TouchableContainer disabled={disabled}>
      {icon && (
        <SVGWrapper style={{ marginRight }} onClick={handlePress}>
          <Icon name={icon} width={width} height={height} color={color}></Icon>
        </SVGWrapper>
      )}
      {regularFontSize ? (
        <TextWorkSans color={color} onClick={handlePress}>
          {children}
        </TextWorkSans>
      ) : (
        <TextWorkSans color={color} onClick={handlePress}>
          {children}
        </TextWorkSans>
      )}
    </TouchableContainer>
  );
};

export default Link;
