import React from "react";
import Gif from "./assets/gif";
import Copy from "./assets/copy";
import Play from "./assets/play";
import Edit from "./assets/edit";
import Share from "./assets/share";
import Email from "./assets/email";
import Timer from "./assets/timer";
import Apple from "./assets/apple";
import Error from "./assets/error";
import Delete from "./assets/delete";
import Logout from "./assets/logout";
import Wallet from "./assets/wallet";
import ZoomIn from "./assets/zoomIn";
import LogoIcon from "./assets/icon";
import Google from "./assets/google";
import GoBack from "./assets/goBack";
import Redeem from "./assets/redeem";
import Van from "./assets/van";
import Schedule from "./assets/schedule";
import Profile from "./assets/profile";
import Monitor from "./assets/monitor";

import ZoomOut from "./assets/zoomOut";
import SellNft from "./assets/sellNft";
import Download from "./assets/download";
import Facebook from "./assets/facebook";
import Withdraw from "./assets/withdraw";
import CancelCircle from "./assets/cancelCircle";
import Spaceship from "./assets/spaceship";
import UnSellNft from "./assets/unSellNft";
import PinchArrow from "./assets/pinchArrow";
import RightArrow from "./assets/rightArrow";
import GooglePlaystore from "./assets/googlePlay";
import Magic from "./assets/magic";
import Ticket from "./assets/ticket";
import Trasnfer from "./assets/transfer";
import GoogleMaps from "./assets/googleMaps";
import RetrieveTicket from "./assets/retrieveTicket";
import EventCalendar from "./assets/eventCalendar";
import Help from "./assets/help";
import QrCode from "./assets/qrCode";
import Cancel from "./assets/cancel";
import CheckMark from "./assets/checkMark";
import Notice from "./assets/notice";
import CTHorOrange from "./assets/ctLogoHorizontalOrange";
import CTVerOrange from "./assets/ctLogoVerticalOrange";
import CTHorBlack from "./assets/ctlogoHorizontalBlack";
import Location from "./assets/location";
import { IconProps, IconType } from "./Icon.interface";
import LeftArrow from "./assets/leftArrow";
import SVGMyTickets from "./assets/myTickets";
import SVGProfile from "./assets/profile";
import Camera from "./assets/camera";
import Close from "./assets/close";
import Dashboard from "./assets/dashboard";
import Back from "./assets/back";
import Add from "./assets/add";
import Search from "./assets/search";
import FaceDetector from "./assets/faceDetector";
import SVGDiscover from "./assets/discover";
import Circle from "./assets/circle";
import DownArrow from "./assets/downArrow";
import UpArrow from "./assets/upArrow";
import Phone from "./assets/phone";
import Link from "./assets/link";
import StripeSuccess from "./assets/stripe-success";
import GoogleWallet from "./assets/google_wallet";
import EOS from "./assets/eos";
import Refresh from "./assets/refresh";
import AlignCenter from "./assets/alignCenter";
import Underline from "./assets/underline";
import AlignJustify from "./assets/alignJustify";
import AlignLeft from "./assets/alignLeft";
import Italic from "./assets/italic";
import AlignRight from "./assets/alignRight";
import Bold from "./assets/bold";
import Code from "./assets/code";
import Quote from "./assets/quote";
import IconListBullets from "./assets/iconListBullets";
import IconListNumbers from "./assets/iconListNumbers";
import Level1 from "./assets/level1";
import Level2 from "./assets/level2";
import AppleWallet from "./assets/apple_wallet";
import DownloadArrow from "./assets/downloadArrow";
import Dots3 from "./assets/dots3";
import Info from "./assets/info";
import Percentage from "./assets/percentage";
import Menu from "./assets/menu";
import Euro from "./assets/euro";
import Information from "./assets/information";
import Cart from "./assets/cart";
import Pdf from "./assets/pdf";
import Eye from "./assets/eye";
import Receipt from "./assets/receipt";
import TransferIntentory from "./assets/transferInventory";
import UploadIcon from "./assets/updateIcon";

const Icon: React.FC<IconProps & { name: IconType }> = ({ name, ...props }) => {
  switch (name) {
    case "align-justify": {
      return <AlignJustify {...props} />;
    }
    case "van": {
      return <Van {...props} />;
    }
    case "schedule": {
      return <Schedule {...props} />;
    }
    case "profile": {
      return <Profile {...props} />;
    }
    case "receipt": {
      return <Receipt {...props} />;
    }
    case "link": {
      return <Link {...props} />;
    }
    case "monitor": {
      return <Monitor {...props} />;
    }
    case "eye": {
      return <Eye {...props} />;
    }
    case "cart": {
      return <Cart {...props} />;
    }
    case "transferInventory": {
      return <TransferIntentory {...props} />;
    }
    case "cancel-circle": {
      return <CancelCircle {...props} />;
    }
    case "search": {
      return <Search {...props} />;
    }
    case "menu": {
      return <Menu {...props} />;
    }
    case "italic": {
      return <Italic {...props} />;
    }
    case "information": {
      return <Information {...props} />;
    }
    case "upload-icon": {
      return <UploadIcon {...props} />;
    }
    case "quote": {
      return <Quote {...props} />;
    }
    case "underline": {
      return <Underline {...props} />;
    }
    case "align-center": {
      return <AlignCenter {...props} />;
    }
    case "align-left": {
      return <AlignLeft {...props} />;
    }
    case "align-right": {
      return <AlignRight {...props} />;
    }
    case "bold": {
      return <Bold {...props} />;
    }

    case "code": {
      return <Code {...props} />;
    }

    case "level1": {
      return <Level1 {...props} />;
    }
    case "level2": {
      return <Level2 {...props} />;
    }

    case "icon-list-bullets": {
      return <IconListBullets {...props} />;
    }
    case "icon-list-numbers": {
      return <IconListNumbers {...props} />;
    }
    case "location": {
      return <Location {...props} />;
    }
    case "3dots": {
      return <Dots3 {...props} />;
    }
    case "download-arrow": {
      return <DownloadArrow {...props} />;
    }
    case "dashboard": {
      return <Dashboard {...props} />;
    }
    case "apple-wallet": {
      return <AppleWallet {...props} />;
    }

    case "camera": {
      return <Camera {...props} />;
    }

    case "close": {
      return <Close {...props} />;
    }
    case "back": {
      return <Back {...props} />;
    }

    case "add": {
      return <Add {...props} />;
    }
    case "face-detector": {
      return <FaceDetector {...props} />;
    }

    case "phone": {
      return <Phone {...props} />;
    }
    case "logo-hor-orange": {
      return <CTHorOrange {...props} />;
    }

    case "logo-ver-orange": {
      return <CTVerOrange {...props} />;
    }

    case "info": {
      return <Info {...props} />;
    }

    case "logo-hor-black": {
      return <CTHorBlack {...props} />;
    }

    case "download": {
      return <Download {...props} />;
    }

    case "magic": {
      return <Magic {...props} />;
    }

    case "ticket": {
      return <Ticket {...props} />;
    }

    case "share": {
      return <Share {...props} />;
    }

    case "sellNFT": {
      return <SellNft {...props} />;
    }

    case "withdraw": {
      return <Withdraw {...props} />;
    }

    case "unSellNft": {
      return <UnSellNft {...props} />;
    }

    case "copy": {
      return <Copy {...props} />;
    }

    case "google-playstore": {
      return <GooglePlaystore {...props} />;
    }

    case "pinch-arrow": {
      return <PinchArrow {...props} />;
    }

    case "zoom-out": {
      return <ZoomOut {...props} />;
    }

    case "zoom-in": {
      return <ZoomIn {...props} />;
    }

    case "edit": {
      return <Edit {...props} />;
    }

    case "logout": {
      return <Logout {...props} />;
    }

    case "delete": {
      return <Delete {...props} />;
    }

    case "gif": {
      return <Gif {...props} />;
    }

    case "right-arrow": {
      return <RightArrow {...props} />;
    }

    case "left-arrow": {
      return <LeftArrow {...props} />;
    }

    case "go-back": {
      return <GoBack {...props} />;
    }

    case "wallet": {
      return <Wallet {...props} />;
    }

    case "play": {
      return <Play {...props} />;
    }

    case "redeem": {
      return <Redeem {...props} />;
    }

    case "icon": {
      return <LogoIcon {...props} />;
    }

    case "apple": {
      return <Apple {...props} />;
    }

    case "error": {
      return <Error {...props} />;
    }

    case "google": {
      return <Google {...props} />;
    }

    case "facebook": {
      return <Facebook {...props} />;
    }

    case "spaceship": {
      return <Spaceship {...props} />;
    }

    case "email": {
      return <Email {...props} />;
    }

    case "timer": {
      return <Timer {...props} />;
    }
    case "transfer": {
      return <Trasnfer {...props} />;
    }
    case "google-maps": {
      return <GoogleMaps {...props} />;
    }
    case "retrieve-ticket": {
      return <RetrieveTicket {...props} />;
    }
    case "event-calendar": {
      return <EventCalendar {...props} />;
    }
    case "help": {
      return <Help {...props} />;
    }
    case "qr-code": {
      return <QrCode {...props} />;
    }
    case "cancel": {
      return <Cancel {...props} />;
    }
    case "check-mark": {
      return <CheckMark {...props} />;
    }
    case "notice": {
      return <Notice {...props} />;
    }
    case "SVGMyTickets": {
      return <SVGMyTickets {...props} />;
    }
    case "SVGProfile": {
      return <SVGProfile {...props} />;
    }
    case "SVGLogout": {
      return <Notice {...props} />;
    }
    case "SVGDiscover": {
      return <SVGDiscover {...props} />;
    }
    case "circle": {
      return <Circle {...props} />;
    }
    case "upArrow": {
      return <UpArrow {...props} />;
    }
    case "downArrow": {
      return <DownArrow {...props} />;
    }
    case "stripeSuccess": {
      return <StripeSuccess {...props} />;
    }
    case "eos": {
      return <EOS {...props} />;
    }
    case "refresh": {
      return <Refresh {...props} />;
    }
    case "google-wallet": {
      return <GoogleWallet {...props} />;
    }
    case "percentage": {
      return <Percentage {...props} />;
    }
    case "euro": {
      return <Euro {...props} />;
    }
    case "pdf": {
      return <Pdf {...props} />;
    }
    default:
      return null;
  }
};

export default Icon;
