import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const EventCalendar = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      fill="none"
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7085 1.65608C4.7085 1.14798 5.12039 0.736084 5.6285 0.736084C6.1366 0.736084 6.5485 1.14798 6.5485 1.65608V2.29541H13.3175V1.65608C13.3175 1.14798 13.7294 0.736084 14.2375 0.736084C14.7456 0.736084 15.1575 1.14798 15.1575 1.65608V2.29541H16.4591C18.1159 2.29541 19.4591 3.63856 19.4591 5.29541V15.2823C19.4591 16.9392 18.1159 18.2823 16.4591 18.2823H3.55566C1.89881 18.2823 0.555664 16.9392 0.555664 15.2823V5.29541C0.555664 3.63856 1.89881 2.29541 3.55566 2.29541H4.7085V1.65608ZM13.3175 4.13541V4.84444C13.3175 5.35254 13.7294 5.76444 14.2375 5.76444C14.7456 5.76444 15.1575 5.35254 15.1575 4.84444V4.13541H16.4591C17.0997 4.13541 17.6191 4.65476 17.6191 5.29541V7.39657H16.0258C15.5177 7.39657 15.1058 7.80847 15.1058 8.31657C15.1058 8.82467 15.5177 9.23657 16.0258 9.23657H17.6191V15.2823C17.6191 15.923 17.0997 16.4423 16.4591 16.4423H3.55566C2.91501 16.4423 2.39566 15.923 2.39566 15.2823V9.23657H12.7355C13.2436 9.23657 13.6555 8.82467 13.6555 8.31657C13.6555 7.80847 13.2436 7.39657 12.7355 7.39657H2.39566V5.29541C2.39566 4.65476 2.91501 4.13541 3.55566 4.13541H4.7085V4.84444C4.7085 5.35254 5.12039 5.76444 5.6285 5.76444C6.1366 5.76444 6.5485 5.35254 6.5485 4.84444V4.13541H13.3175Z"
        fill={colors[color || "candyRed"]}
      />
    </svg>
  );
};

export default EventCalendar;
