import React from "react";
import { TextWorkSans } from "../../../components/common.style";
import { CustomTableCell } from "../Table";
import { Product } from "../../../slices/dashboardProductsSlice/types";

const CustomTableRow: React.FC<{
  product: Product;
}> = ({ product }) => {
  const productRow = [
    {
      key: "id_product",
      value: <TextWorkSans color="black">{product.product_id}</TextWorkSans>,
    },
    {
      key: "kay",
      value: <TextWorkSans color="black">{product.kay}</TextWorkSans>,
    },
    {
      key: "name_product",
      value: <TextWorkSans color="black">{product.product_name}</TextWorkSans>,
    },
    {
      key: "mm",
      value: <TextWorkSans color="black">{product.mm}</TextWorkSans>,
    },
    {
      key: "fash",
      value: <TextWorkSans color="black">{product.fash}</TextWorkSans>,
    },
    {
      key: "typos",
      value: <TextWorkSans color="black">{product.typos}</TextWorkSans>,
    },
    {
      key: "supplier",
      value: (
        <TextWorkSans color="black">
          {product.supplier.supplier_name}
        </TextWorkSans>
      ),
    },
    {
      key: "posothta",
      value: <TextWorkSans color="black">{product.quantity}</TextWorkSans>,
    },
  ];
  return (
    <tr>
      {productRow.map((row, key) => (
        <CustomTableCell key={key}>{row.value}</CustomTableCell>
      ))}
    </tr>
  );
};

export default CustomTableRow;
