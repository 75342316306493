import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Level1 = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 24 24"
      fill={colors[color || "spaceShade"]}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1H4ZM11.625 18H14.0391V6.57812H12.0547L8.375 9.50781L9.53906 10.9609L10.8516 9.90625C11.0078 9.78125 11.2865 9.51823 11.6875 9.11719L11.6484 10.3047L11.625 11.3906V18Z"
        fill={colors[color || "spaceShade"]}
      />
    </svg>
  );
};

export default Level1;
