import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import MUIProvider from "./theme/MUIProvider";
import Layout from "./pages/Layout";
import { PreviewPageReceipt, AddReceipt } from "./pages";
import GenericModal from "./components/GenericModal/GenericModal";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./pages/ProtectedRoute";
import { AvailableRoles } from "./components/AuthService";
import TransferInventory from "./pages/Transfer/TransferInventory";
import PreviewTransfer from "./pages/PreviewTransfer/PreviewTransfer";
import TransferToCrew from "./pages/TransferToCrew/TransferToCrew";
import PreviewTransferToCrew from "./pages/PreviewTransferToCrew/PreviewTransferToCrew";
import Dashboard from "./pages/Dashboard/Dashboard";
import AddBuilding from "./pages/Dashboard/pages/AddBulding";
import CrewDashboard from "./pages/CrewDashboard/CrewDashboard";
import CrewProducts from "./pages/CrewProducts/CrewProducts";
import DashboardAdmin from "./pages/DashboardAdmin/DashboardAdmin";
import ProductsPage from "./pages/PruductsPage/ProductsPage";

const App: React.FC = () => {
  return (
    <MUIProvider>
      <GenericModal />
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* Admin routes */}
            <Route
              element={<ProtectedRoute requiredRole={AvailableRoles.ADMIN} />}
            >
              <Route path="/" element={<Home />} />
              <Route path="/addReceipt" element={<AddReceipt />} />
              <Route
                path="/addReceipt/preview"
                element={<PreviewPageReceipt />}
              />
              <Route path="/transferProduct" element={<TransferInventory />} />
              <Route
                path="/transferProduct/preview"
                element={<PreviewTransfer />}
              />
              <Route
                path="/transferToConstructionCrew"
                element={<TransferToCrew />}
              />
              <Route
                path="/transferToConstructionCrew/preview"
                element={<PreviewTransferToCrew />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/newBuilding" element={<AddBuilding />} />
              <Route path="/dashboard/monitor" element={<DashboardAdmin />} />
              <Route path="/dashboard/products" element={<ProductsPage />} />
            </Route>
            {/* User routes */}
            <Route
              element={<ProtectedRoute requiredRole={AvailableRoles.USER} />}
            >
              <Route path="/myBuildings" element={<CrewDashboard />} />
            </Route>
            <Route
              element={<ProtectedRoute requiredRole={AvailableRoles.USER} />}
            >
              <Route path="/myBuildings/:id" element={<CrewProducts />} />
            </Route>
          </Routes>
        </Layout>
      </Router>
    </MUIProvider>
  );
};

export default App;
