import { css } from "@emotion/react";
import { ColorsType } from "../../../theme/types";
import styled from "@emotion/styled";

export type TextProps = {
  color?: ColorsType;
  bold?: boolean;
  center?: boolean;
  onClick?: () => void;
  opacity?: number | null;
  textDecorationLine?:
    | "underline"
    | "overline"
    | "line-through"
    | "blink"
    | "undefined";
  theme?: any;
};
type TouchableContainerProps = {
  disabled: boolean;
};
export const TouchableContainer = styled.div<TouchableContainerProps>`
  display: flex;
  align-items: center;
  ${(props: TouchableContainerProps) =>
    props.disabled && "opacity: 0.4; pointer-events: none"}
`;

export const SVGWrapper = styled.span<TextProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props: TextProps) => commonStyles({ onClick: props.onClick })}
`;

const fontFamily = css`
  font-family: "Work Sans", sans-serif;
`;
const commonStyles = ({ onClick }: TextProps) => css`
  ${fontFamily};
  ${onClick && `cursor: pointer;`}
`;
