export const baseColors = {
  white: "#ffffff",
  lightPurple: "#a699f1",
  black: "#000000",
  spaceShade: "#6E7EC3",
  orangeLogo: "#F26635",
  yellowLogo: "#F9EA08",
  blue: "rgb(0,103,187)",
  blueGradient: "linear-gradient(91.85deg, #24BDFF -5.72%, #7737FF 106.82%);",
  greenLight: "#D7FB63",
  redLight: "#E53C4D",
  grey: "#808080",
  orange: "#FF652F",
  lightGrey: "#B4CFF2",
  purple: "#200A40",
  vividPink: "#D53693",
  irisDark: "#7879F1",
  irisLight: "#8390C6",
  irisPure: "#DEDEFF",
  metallicDark: "#2B2E43",
  metallicPearlWhite: "#FDFDFD",
  obsidian: "#14141d",
  candyOrange: "#F2994A",
  candyYellow: "#FFC213",
  candyRed: "#CC5500",
  metallicLight: "#91919F",
  metallicLight2: "#7D7DA3",
  horizonalLine: "#e1e1e1",
  facebookBlue: "#275EA4",
  googleRed: "#FA7373",
  onyxDark: "#10121F",
  carinalTeal: "#24bdff",
  spacePure: "#D7DEFF",
  dark: "#14151D",
  bgCard: "#14141d",
  lineChartorange: "#FF7800",
  lineChartDarkyellow: "#FABC13",
  lineChartlavender: "#DEDEFF",
  lineChartdarkRed: "#A31E1E",
  lineChartyellow: "#FFF500",
  lineChartred: "#D74F31",
  lineChartlightBrown: "#B1700D",
  lineChartdarkGreen: "#65743F",
  lineChartlighGreen: "#CCFF8B",
  lineChartmediumGreen: "#45AF69",
  lineChartGreen: "#0C953A",
  lineChartlighGreen2: "#57FC54",
  lineChartpink: "#FF52EE",
  lineChartlightBlue: "#00F0FF",
  lineChartdarkBlue: "#1456FF",
  lineChartblue: "#2F8FFE",
  lineChartlightPurple: "#7742A0",
  lineChartpurple: "#6B37FF",
  lineChartdarkPink: "#B77EFF",
  lineChartlightPink: "#E801B8",
  dividerDark: "#1E2235",
  dividerLight: "#a7a7a7",
};
