import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "../../theme/theme";
import { bodyTextLarge, customScrollbars } from "../common.style";

type TextAreaProps = {
  maxwidth?: number;
  minWidth?: number;
  border?: string;
  borderBottom?: string;
  padding?: string;
  borderRadius?: string;
  height?: string;
  fontSize?: string;
};

const maxWidthDecoration = ({ maxwidth }: TextAreaProps) => css`
  ${maxwidth && `max-width:${maxwidth}px`}
`;

export const TextArea = styled.textarea<TextAreaProps>`
  ${(props) =>
    props.borderBottom
      ? `border-bottom: ${props.borderBottom};`
      : props.border
      ? `border: ${props.border};`
      : `border: 3px solid ${theme.colors.metallicDark};`} // Default border if neither borderBottom nor border is specified

  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "12px"};
  padding: 5px ${(props) => (props.padding ? props.padding : "18px")};
  ${(props) => props.borderBottom && `border-bottom: ${props.borderBottom}`}
  ${(props) => props.height && `height: ${props.height};`}

  width: 100%;
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
  ${(props: TextAreaProps) => maxWidthDecoration({ maxwidth: props.maxwidth })}

  background-color: transparent;
  ${(props) =>
    bodyTextLarge({
      color: "metallicPearlWhite",
      fontSize: props.fontSize,
    })};
  &::placeholder {
    color: ${theme.colors.metallicDark};
  }

  ${customScrollbars}; // Optional: Apply custom scrollbar styling
`;
