import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Add = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20.1063C15.5492 20.1063 19.2371 16.4184 19.2371 11.8691C19.2371 7.31989 15.5492 3.63199 11 3.63199C6.45075 3.63199 2.76285 7.31989 2.76285 11.8691C2.76285 16.4184 6.45075 20.1063 11 20.1063ZM11 21.9463C16.5655 21.9463 21.0771 17.4346 21.0771 11.8691C21.0771 6.30368 16.5655 1.79199 11 1.79199C5.43454 1.79199 0.922852 6.30368 0.922852 11.8691C0.922852 17.4346 5.43454 21.9463 11 21.9463ZM16.6693 11.869C16.6693 12.3108 16.3112 12.669 15.8693 12.669L11.7997 12.6691L11.7996 16.7386C11.7996 17.1804 11.4414 17.5385 10.9996 17.5385C10.5578 17.5385 10.1996 17.1803 10.1996 16.7385L10.1997 12.6691L6.13034 12.6692C5.68851 12.6692 5.33033 12.3111 5.33032 11.8693C5.33031 11.4274 5.68847 11.0692 6.1303 11.0692L10.1998 11.0691L10.1998 6.99952C10.1999 6.55769 10.558 6.19952 10.9999 6.19953C11.4417 6.19955 11.7999 6.55773 11.7998 6.99955L11.7998 11.0691L15.8693 11.069C16.3111 11.069 16.6693 11.4272 16.6693 11.869Z"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default Add;
