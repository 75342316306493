import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Wallet = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "21"}
      height={height ? height : "21"}
      fill="none"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9609 2.44238H7.65491C7.10262 2.44238 6.65491 2.8901 6.65491 3.44238V14.0176C6.65491 14.5699 7.10262 15.0176 7.65491 15.0176H17.9609C18.5132 15.0176 18.9609 14.5699 18.9609 14.0176V3.44238C18.9609 2.8901 18.5132 2.44238 17.9609 2.44238ZM7.65491 0.442383C5.99805 0.442383 4.65491 1.78553 4.65491 3.44238V14.0176C4.65491 15.6745 5.99805 17.0176 7.65491 17.0176H17.9609C19.6178 17.0176 20.9609 15.6745 20.9609 14.0176V3.44238C20.9609 1.78553 19.6178 0.442383 17.9609 0.442383H7.65491ZM16.8566 13.4065H8.92003C8.54027 13.4065 8.29914 12.9998 8.48132 12.6666L10.1763 9.5667C10.3186 9.30633 10.6551 9.22653 10.8992 9.39524L12.3333 10.3863C12.5839 10.5595 12.9298 10.4703 13.0653 10.1975L14.6239 7.06097C14.8239 6.65841 15.4122 6.70374 15.5482 7.13219L17.3332 12.7552C17.4355 13.0776 17.1949 13.4065 16.8566 13.4065ZM2.95959 8.33017C2.95959 7.77788 2.51188 7.33017 1.95959 7.33017C1.40731 7.33017 0.959595 7.77788 0.959595 8.33017V14.6375C0.959595 17.9512 3.64589 20.6375 6.95959 20.6375H13.5422C14.0945 20.6375 14.5422 20.1898 14.5422 19.6375C14.5422 19.0852 14.0945 18.6375 13.5422 18.6375H6.95959C4.75046 18.6375 2.95959 16.8466 2.95959 14.6375V8.33017Z"
        fill={colors[color || "white"]}
      />
    </svg>
  );
};

export default Wallet;
