import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FormValues,
  fetchFash,
  updateFormValue,
} from "../../slices/receiptSlice/receiptSlice";
import { AppDispatch } from "../../slices/store";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import TableComponent from "./Table";
import SearchBar from "./SearchBar/SearchBar";
import { getProducts, getStatus } from "../../slices/productsSlice/selectors";
import { getProductsByFash } from "../../slices/productsSlice/productsSlice";
import { TextWorkSans } from "../../components/common.style";
import Button from "../../components/Button/Button";
import {
  getDataStatus,
  getFormData,
  selectFashDetails,
} from "../../slices/receiptSlice/selectors";

// Memoizing the SearchBar and TableComponent
const MemoizedSearchBar = React.memo(SearchBar);
const MemoizedTableComponent = React.memo(TableComponent);

const AddReceipt: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { control, watch, setValue, handleSubmit, getValues, resetField } =
    useForm<FormValues>({
      defaultValues: {
        fash: undefined,
        provider: undefined,
        company: undefined,
        location: undefined,
        couponNumber: "",
        products: undefined,
        pickupDate: "",
        couponDate: "",
        work: "",
        notes: "",
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const watchProducts = useWatch({ control, name: "products" }) as {
    productId: number;
    quantity: string;
  }[];

  const status = useSelector(getStatus);
  const getProductsArray = useSelector(getProducts);
  const formData = useSelector(getFormData);
  const dataStatus = useSelector(getDataStatus);
  const [previewPage, setPreviewPage] = useState<boolean>(false);
  const { fasis, location, brands, companies } = useSelector(selectFashDetails);

  const allFieldsFilled = (): boolean => {
    const values = watch();
    return !!(
      values?.fash &&
      values?.provider &&
      values?.location &&
      values?.company &&
      values?.couponNumber &&
      values?.products?.length > 0 &&
      values?.pickupDate &&
      values?.couponDate
    );
  };

  useEffect(() => {
    dispatch(fetchFash());
  }, [dispatch]);

  useEffect(() => {
    const allValues = getValues();

    dispatch(
      updateFormValue({
        field: "products",
        value: allValues.products
          ? allValues.products.map((product) => ({ ...product }))
          : [],
      })
    );
  }, [watchProducts, dispatch, getValues]);

  useEffect(() => {
    if (status === "succeeded") {
      updateFieldsBasedOnSelection();
    }
  }, [status]);

  useEffect(() => {
    const fash = watch("fash");
    const provider = watch("provider");

    if (!fash || !provider || fash.title === "") return;

    const params = {
      fash: fash.title,
      providerId: provider.id,
    };
    dispatch(getProductsByFash(params));
  }, [watch("fash"), watch("provider"), dispatch]);

  const updateFieldsBasedOnSelection = () => {
    // Reset the existing products data in the form
    resetField("products");

    // Map through getProductsArray to find and use quantities from formData if available
    getProductsArray.forEach((product) => {
      // Find the matching product from formData
      const matchingProduct = formData.products.find(
        (p) => p.productId === product.product_id
      );

      // Determine the quantity to append
      const quantityToAppend = matchingProduct ? matchingProduct.quantity : "";

      // Append the product with the appropriate quantity
      append({
        productId: product.product_id,
        quantity: quantityToAppend,
      });
    });
  };

  const onSubmit = (data: any) => {
    history("/addReceipt/preview");
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      style={{
        display: "flex",
        gap: "40px",
        justifyContent: "center",
        flexDirection: "column",
        padding: "40px",
        width: "100%",
        maxWidth: "1300px",
        marginTop: "40px",
        marginBottom: "40px",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          marginTop: "25px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <TextWorkSans fontSize={25} color={"secondary"}>
          {previewPage ? " ΕΠΙΘΕΩΡΗΣΗ ΦΟΡΜΑΣ " : " ΠΡΟΣΘΗΚΗ ΠΑΡΑΛΑΒΗΣ"}
        </TextWorkSans>
        <div style={{ marginTop: "auto" }}>
          <Button
            padding={0}
            size="medium"
            backgroundColor="primary"
            color="white"
            disabled={!allFieldsFilled()}
            type="submit"
            fontSize={12}
          >
            {previewPage ? " ΠΡΟΣΘΗΚΗ ΠΑΡΑΛΑΒΗΣ " : " ΕΠΙΘΕΩΡΗΣΗ"}
          </Button>
        </div>
      </div>

      {dataStatus === "loading" ? (
        <CircularProgress color={"primary"} size={50} />
      ) : (
        <>
          <MemoizedSearchBar
            watch={watch}
            control={control}
            fields={fields}
            remove={remove}
            append={append}
            setValue={setValue}
          />
          <MemoizedTableComponent
            watch={watch}
            control={control}
            fields={fields}
            remove={remove}
            append={append}
            setValue={setValue}
            getValues={getValues}
          />
        </>
      )}
    </form>
  );
};

export default AddReceipt;
