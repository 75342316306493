import React from "react";
import { useSetNavbarBasedOnWidth } from "../hooks/UseCurrentWitdh";
import { TextWorkSans } from "../components/common.style";
import { useAuthService } from "../components/AuthService";
import { useNavigate } from "react-router-dom";
import { Icon } from "../components";
import Logo from "../Logo.png";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const authService = useAuthService();
  authService.initializeAxiosToken();
  useSetNavbarBasedOnWidth();
  const history = useNavigate();
  const decodedToken = authService.getTokenDecoded();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <header
        style={{
          height: "80px",
          backgroundColor: "#1E3A5F",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div
          onClick={() => {
            history("/");
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ height: "65px", width: "65px" }}
          />
        </div>
        {authService.isLoggedIn() ? (
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <TextWorkSans color="metallicPearlWhite" fontSize={20}>
                Ο χρήστης{" "}
                <TextWorkSans fontSize={20} color="metallicPearlWhite">
                  {decodedToken.username}
                </TextWorkSans>
              </TextWorkSans>
              <Icon name="profile" />
            </div>
            {authService.isLoggedIn() ? (
              <div
                onClick={() => {
                  history("/login");
                  authService.logout();
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <TextWorkSans fontSize={15}>Αποσύνδεση</TextWorkSans>
              </div>
            ) : (
              <div
                onClick={() => history("/login")}
                style={{ marginLeft: "auto", cursor: "pointer" }}
              >
                <TextWorkSans fontSize={15}>Login</TextWorkSans>
              </div>
            )}
          </div>
        ) : null}
      </header>

      <main
        style={{
          flex: 1,
          alignItems: "center",
          overflowY: "auto",
          minHeight: "calc(100vh - 80px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            height: "calc(100vh - 80px)",
          }}
        >
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
