import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const AlignRight = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 10H7"
        stroke={colors[color || "spaceShade"]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6H3"
        stroke={colors[color || "spaceShade"]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14H3"
        stroke={colors[color || "spaceShade"]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 18H7"
        stroke={colors[color || "spaceShade"]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlignRight;
