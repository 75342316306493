import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import productsAPI from "../../providers/productsAPI";

export interface Product {
  fash: string;
  product_id: number;
  kay: string;
  mm: string;
  product_name: string;
  sn: string;
  syntomeysh: string;
  typos: string;
  quantity: number;
}

export interface FashState {
  products: Product[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: SerializedError | null;
}

export const initialState: FashState = {
  products: [],
  status: "idle",
  error: null,
};

export const getProductsByFash = createAsyncThunk(
  "products/getProductsByFash",
  async (params: any, thunkAPI) => {
    const { request } = productsAPI.single.getProducts(params);
    return request()
      .then((response: Product[]) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    clearProducts(state) {
      state.products = [];
      state.status = "idle";
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProductsByFash.pending, (state) => {
      state.products = [];
      state.status = "loading";
    });

    builder.addCase(
      getProductsByFash.fulfilled,
      (state, action: PayloadAction<Product[]>) => {
        state.products = action.payload;
        state.status = "succeeded";
      }
    );
    builder.addCase(getProductsByFash.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error;
    });
  },
});

export default productsSlice.reducer;
export const { clearProducts } = productsSlice.actions;
