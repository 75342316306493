import { CancelTokenSource } from "axios";
import { METHODS, request } from "../../slices/axiosInstances";

export default {
  getQuantityCrew: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getQuantityCrew.php", { params }),
  getQuantityWarehouses: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getQuantityWarehouses.php", { params }),
};
