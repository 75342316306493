import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDesktopMode } from "../slices/desktopMode/desktopMode";

export const useSetNavbarBasedOnWidth = () => {
  const dispatch = useDispatch();

  const updateStoreValues = () => {
    if (
      window.innerWidth < 840 ||
      window.navigator.userAgent.toLowerCase().includes("instagram")
    ) {
      dispatch(setDesktopMode(false));
    } else {
      dispatch(setDesktopMode(true));
    }
  };

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    setTimeout(() => {
      updateStoreValues();
    }, 0);
    // set resize listener
    window.addEventListener("resize", updateStoreValues);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", updateStoreValues);
    };
  }, []);
};
