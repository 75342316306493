import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Receipt = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "23"}
      height={height ? height : "23"}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke={colors[color || "spaceShade"]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 13h4v6a2 2 0 0 1-2 2m-2-8v6a2 2 0 0 0 2 2m-2-8V5.757c0-.905 0-1.358-.19-1.62a1 1 0 0 0-.702-.408c-.323-.035-.716.19-1.502.639l-.312.178c-.29.166-.434.248-.588.28a1 1 0 0 1-.412 0c-.154-.031-.299-.114-.588-.28l-1.912-1.092c-.29-.166-.434-.248-.588-.28a1 1 0 0 0-.412 0c-.154.032-.298.114-.588.28L7.294 4.546c-.29.166-.434.248-.588.28a1 1 0 0 1-.412 0c-.154-.031-.298-.114-.588-.28l-.312-.178c-.786-.45-1.18-.674-1.502-.639a1 1 0 0 0-.702.408C3 4.399 3 4.852 3 5.757V16.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21H19M7 13h2M7 9h6m-6 8h2m4 0h.01M13 13h.01"
      />
    </svg>
  );
};

export default Receipt;
