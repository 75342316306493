import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Code = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5779 6.90712C13.2065 7.27165 13.2037 7.86913 13.5717 8.23712L17.3143 11.9797L13.5704 15.7236C13.2015 16.0925 13.1998 16.6901 13.5666 17.0611C13.9396 17.4383 14.5494 17.4364 14.9199 17.0568L19.876 11.9797L14.9012 6.91898C14.5389 6.5505 13.9467 6.5452 13.5779 6.90712Z"
        fill={colors[color || "spaceShade"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4211 6.90712C10.7925 7.27165 10.7953 7.86913 10.4273 8.23712L6.68475 11.9797L10.4286 15.7236C10.7975 16.0925 10.7992 16.6901 10.4324 17.0611C10.0594 17.4383 9.44967 17.4364 9.07915 17.0568L4.12305 11.9797L9.09787 6.91898C9.46009 6.5505 10.0524 6.5452 10.4211 6.90712Z"
        fill={colors[color || "spaceShade"]}
      />
    </svg>
  );
};

export default Code;
