import React, { useEffect } from "react";
import { DisableComponent, TextWorkSans } from "../../components/common.style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../slices/store";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SelectComponent from "../../components/Select/Select";
import { TextField } from "../../components";
import Button from "../../components/Button/Button";
import {
  FormValues,
  transferProductsAPI,
} from "../../slices/receiptTransferSlice/receiptTransferSlice";
import TableComponent from "../Transfer/Table";
import {
  getFormData,
  selectTransferDetails,
} from "../../slices/receiptTransferSlice/selectors";

const PreviewTransfer: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { control, watch, setValue, handleSubmit, getValues } =
    useForm<FormValues>({
      defaultValues: {
        fash: undefined,
        provider: undefined,
        from: undefined,
        to: undefined,
        products: undefined,
        date: "",
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const formData = useSelector(getFormData);

  useEffect(() => {
    if (formData) {
      setValue("fash", formData.fash);
      setValue("provider", formData.provider);
      setValue("from", formData.from);
      setValue("to", formData.to);
      setValue("date", formData.date);
      setValue("products", formData.products);
    }
  }, [formData]);

  const allFieldsFilled = (): boolean => {
    const values = watch();

    if (
      values.fash &&
      values.provider &&
      values.from &&
      values.to &&
      values.products &&
      values.products.length > 0 &&
      values.date
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (data: FormValues) => {
    if (data.to && data.provider && data.from && data.products) {
      const fashId =
        data.fash?.title === "A"
          ? 1
          : data.fash?.title === "B"
          ? 2
          : data.fash?.title === "C"
          ? 3
          : 0;
      const payload = {
        supplier_id: parseInt(data.provider.id),
        employee_id: 1,
        fash_id: fashId,
        from_id: parseInt(data.from.id),
        to_id: parseInt(data.to.id),
        date: data.date,
        products: data.products.map((product: any) => ({
          product_id: parseInt(product.productId),
          quantity:
            product.quantity === "" ? 0 : parseInt(product.quantity, 10),
        })),
      };
      dispatch(
        transferProductsAPI({ transferData: payload, navigate: history })
      );
    }
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };
  const { fasis, location, companies } = useSelector(selectTransferDetails);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      style={{
        display: "flex",
        gap: "40px",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        padding: "40px",
        width: "100%",
        maxWidth: "1300px",
        marginTop: "40px",
        marginBottom: "40px",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
      }}
    >
      <div
        style={{
          marginTop: "25px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextWorkSans fontSize={25} color={"secondary"}>
          ΕΠΙΘΕΩΡΗΣΗ ΦΟΡΜΑΣ
        </TextWorkSans>
        <div style={{ marginTop: "auto" }}>
          <Button
            padding={0}
            size="medium"
            backgroundColor="primary"
            color="white"
            disabled={!allFieldsFilled()}
            type="submit"
            fontSize={12}
          >
            ΜΕΤΑΦΟΡΑ ΠΡΟΙΟΝΤΩΝ
          </Button>
        </div>
      </div>
      <DisableComponent
        opacity={0.7}
        disabled={true}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <Controller
            control={control}
            name="provider"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Πάροχο"
                placeholder="= Επέλεξε -"
                {...field}
                items={companies}
                value={watch("provider")}
              />
            )}
          />

          <Controller
            control={control}
            name="fash"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"180px"}
                required
                label="Διάλεξε Φάση"
                placeholder="= Επέλεξε -"
                {...field}
                items={fasis}
                value={watch("fash")}
              />
            )}
          />

          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TextField
                formToday
                maxwidth={200}
                label={"Ημερομηνία"}
                type="date"
                required
                placeholder="day"
                {...field}
              />
            )}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <Controller
              control={control}
              name="from"
              render={({ field }) => (
                <SelectComponent
                  maxWidth={"300px"}
                  required
                  label="Απο / Αποθήκη"
                  placeholder="= Επέλεξε -"
                  {...field}
                  items={location}
                  value={watch("from")}
                />
              )}
            />
            <Controller
              control={control}
              name="to"
              render={({ field }) => (
                <SelectComponent
                  maxWidth={"300px"}
                  required
                  label="Προς / Αποθήκη"
                  placeholder="= Επέλεξε -"
                  {...field}
                  items={location}
                  value={watch("to")}
                />
              )}
            />
          </div>
        </div>
        <TableComponent
          watch={watch}
          control={control}
          fields={fields}
          remove={remove}
          append={append}
          setValue={setValue}
          getValues={getValues}
          previewTable
        />
      </DisableComponent>
    </form>
  );
};

export default PreviewTransfer;
