import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalContent from "./ModalContent";
import { GenericModalProperties } from "../../slices/modalSlice/types";
import { getGenericModal } from "../../slices/modalSlice/selectors";

const GenericModal: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const modalParams: GenericModalProperties = {
    enableLoader: true,
  };

  const modal = useSelector(getGenericModal);

  return modal ? (
    <ModalContent params={modal as GenericModalProperties} />
  ) : null;
};

export default GenericModal;
