import styled from "@emotion/styled";
import theme from "../../theme/theme";

export const FileBtn = styled.button`
  width: 250px;
  height: 150px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: ${theme.colors.primary};
  background-color: #fff;
  border: 1.5px dashed ${theme.colors.primary};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${theme.colors.primary};
    background-color: #fff;
  }
`;

export const UploadIcon = styled.span`
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #f3ecff;
`;

export const SelectedFileStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3ecff;
  border: 1px solid ${theme.colors.primary};
  border-radius: 20px;
  margin-top: 20px;
`;

export const DeleteIcon = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.primary};
  background-color: transparent;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #fff;
    background-color: ${theme.colors.primary};
  }
`;

export const FileNameStyle = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin-left: 15px;
  color: ${theme.colors.primary};
`;
