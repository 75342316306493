import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import { TextWorkSans } from "../../components/common.style";
import { shouldForwardProp } from "@mui/system";
import ExpandableTableRow from "./Table/TableRow";
import { ColorsType } from "../../theme/types";
import SelectComponent from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import {
  Control,
  FieldArrayWithId,
  FormProvider,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FormValues } from "../../slices/receiptTransferSlice/receiptTransferSlice";
import {
  getBuildingsPage,
  selectBuildingsDetails,
} from "../../slices/dashboardSlice/selectors";
import { AppDispatch } from "../../slices/store";
import theme from "../../theme/theme";
import {
  getReceiptsLog,
  getTransferConstructionCrewLogs,
  getTransferWarehouseLogs,
  getWarehouseLog,
  setTableOffset,
  setTablePage,
  setTabOption,
} from "../../slices/dashboardAdminSlice/dashboardAdminSlice";
import SearchBar from "./SearchBar/SearchBar";
import { SearchSomePage } from "../../slices/dashboardAdminSlice/types";
import { selectDashboardData } from "../../slices/dashboardAdminSlice/selectors";
import { TextField } from "../../components";
import { selectTransferProductsToCrew } from "../../slices/transferProductsToCrewSlice/selectors";
import { selectFashDetails } from "../../slices/receiptSlice/selectors";
const tablePaginationStyles = {
  "& .Mui-disabled": {
    color: "grey !important",
  },
  "& .MuiIconButton-colorInherit": {
    color: `${theme.colors.primary}`,
  },
  color: `${theme.colors.primary}`,
  padding: "0px 0px 0px 0px",
};

export interface CustomTableCellProps extends TableCellProps {
  disablePadding?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  backgroundColor?: ColorsType;
  whiteSpace?: boolean;
}
export const CustomTableCell = styled(TableCell, {
  shouldForwardProp: (prop: any) =>
    shouldForwardProp(prop) && prop !== "disablePadding",
})<CustomTableCellProps>(
  ({ disablePadding, width, maxWidth, whiteSpace, backgroundColor }: any) => {
    const processedWidth = typeof width === "number" ? `${width}px` : width;
    const processedMaxWidth =
      typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;
    return {
      "&.MuiTableCell-head": {
        color: "#7879F1",
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
      "&.MuiTableCell-body": {
        fontSize: 14,
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: whiteSpace ? "nowrap" : "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
    };
  }
);

export const HeaderRow = styled(TableRow)({
  color: "#7879F1",
  "&.MuiTableRow-head": {
    backgroundColor: "transparent",
    color: "#7879F1",
  },
});

interface Props {
  //   // watch: UseFormWatch<FormValues>;
  //   // control: Control<FormValues, any>;
  //   // fields: FieldArrayWithId<FormValues, "products", "id">[];
  //   // remove: UseFieldArrayRemove;
  //   // append: UseFieldArrayAppend<FormValues, "products">;
  //   // setValue: UseFormSetValue<FormValues>;
  previewTable?: boolean;
  //   // getValues: UseFormGetValues<FormValues>;
}
type TotalOptionType = 0 | 1 | 2;

const TableComponent: React.FC<Props> =
  // control,
  ({ previewTable }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const {
      tabOption,
      wareHouseLogData,
      wareHouseLogStatus,
      receiptsLogData,
      receiptsLogStatus,
      transferLogData,
      transferLogStatus,
      tableOffset,
      tablePage,
    } = useSelector(selectDashboardData);

    const handleChangePage = (event: unknown, newPage: number) => {
      if (newPage > tablePage) {
        dispatch(setTableOffset(tableOffset + 5));
      }
      if (newPage < tablePage) {
        dispatch(setTableOffset(tableOffset - 5));
      }
      dispatch(setTablePage(newPage));
    };
    const limit = 5;
    const rowsPerPage = 5;

    const getTableHeaders = (totalOption: number) => {
      return headersSet[totalOption as TotalOptionType] || [];
    };

    const headersSet: {
      0: {
        id: string;
        label: string;
        align: string;
        fontSize: number;
        color: string;
      }[];
      1: {
        id: string;
        label: string;
        align: string;
        fontSize: number;
        color: string;
      }[];
      2: {
        id: string;
        label: string;
        align: string;
        fontSize: number;
        color: string;
      }[];
    } = {
      0: [
        {
          id: "id",
          label: "Id",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "user",
          label: "Χρήστης",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "supplier",
          label: "Προμηθευτής",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "provider",
          label: "Εταιρεία",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "warehouse",
          label: "Αποθήκη",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "receipt_date",
          label: "Ημερομηνία Παραλαβής",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "delivery_date",
          label: "Ημερομηνία Αποστολής",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "downlaod",
          label: "Κατέβασμα",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "cancel",
          label: "Ακύρωση",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
      ],
      1: [
        {
          id: "id",
          label: "Id",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "user",
          label: "Χρήστης",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "supplier",
          label: "Προμηθευτής",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "fash",
          label: "Φάση",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "from",
          label: "Απο / Αποθήκη",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "to",
          label: "Προς / Αποθήκη",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "date",
          label: "Ημερομηνία",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "downlaod",
          label: "Κατέβασμα",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "cancel",
          label: "Ακύρωση",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
      ],
      2: [
        {
          id: "id",
          label: "Id",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "user",
          label: "Χρήστης",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "supplier",
          label: "Προμηθευτής",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "fash",
          label: "Φάση",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "from",
          label: "Απο / Αποθήκη",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "to",
          label: "Προσ / Συνεργείο",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "date",
          label: "Ημερομηνία",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "downlaod",
          label: "Κατέβασμα",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
        {
          id: "cancel",
          label: "Ακύρωση",
          align: "left",
          fontSize: 12,
          color: "primary",
        },
      ],
    };

    const [city, setCity] = useState<{
      id: string;
      title: string;
      shortName?: string;
      icon?: JSX.Element;
      data?: any;
    } | null>();
    const [status, setStatus] = useState<
      | {
          id: string;
          title: string;
          shortName?: string;
          icon?: JSX.Element;
          data?: any;
        }
      | undefined
    >();
    const [crew, setCrew] = useState<
      | {
          id: string;
          title: string;
          shortName?: string;
          icon?: JSX.Element;
          data?: any;
        }
      | undefined
    >();
    const dispatch = useDispatch<AppDispatch>();

    const [value, setValue] = React.useState(0);

    const methods = useForm<SearchSomePage>({
      defaultValues: {
        fash: undefined,
        provider: undefined,
        from: undefined,
        to_construction_crew: undefined,
        products: undefined,
        date: "",
        location: undefined,
        company: undefined,
        couponNumber: "",
        pickupDate: "",
        couponDate: "",
        to: undefined,
      },
    });

    useEffect(() => {
      const fashId =
        methods.watch("fash")?.title === "A"
          ? 1
          : methods.watch("fash")?.title === "B"
          ? 2
          : methods.watch("fash")?.title === "C"
          ? 3
          : 0;
      if (tabOption === 0) {
        const payload = {
          ...(methods.watch("fash") && {
            fash_id: fashId,
          }),
          ...(methods.watch("provider") && {
            provider_id: methods.watch("provider")?.id,
          }),
          ...(methods.watch("company") && {
            supplier_id: methods.watch("company")?.id,
          }),
          ...(methods.watch("location") && {
            warehouse_id: methods.watch("location")?.id,
          }),
          offset: tableOffset,
          limit: limit,
        };
        dispatch(getReceiptsLog(payload));
      }

      if (tabOption === 1) {
        const warehouseLogParams = {
          ...(methods.watch("fash") && {
            fash_id: fashId,
          }),
          ...(methods.watch("company") && {
            supplier_id: methods.watch("company")?.id,
          }),
          ...(methods.watch("from") && {
            from_id: methods.watch("from")?.id,
          }),
          ...(methods.watch("to") && {
            to_id: methods.watch("to")?.id,
          }),
          offset: tableOffset,
          limit: limit,
        };
        dispatch(getTransferWarehouseLogs(warehouseLogParams));
      }

      if (tabOption === 2) {
        const transferLogParams = {
          ...(methods.watch("fash") && {
            fash_id: fashId,
          }),
          ...(methods.watch("company") && {
            supplier_id: methods.watch("company")?.id,
          }),
          ...(methods.watch("from") && {
            from_id: methods.watch("from")?.id,
          }),
          ...(methods.watch("to_construction_crew") && {
            to_id: methods.watch("to_construction_crew")?.id,
          }),
          offset: tableOffset,
          limit: limit,
        };
        dispatch(getTransferConstructionCrewLogs(transferLogParams));
      }
    }, [tablePage]);

    useEffect(() => {
      dispatch(setTableOffset(0));
      dispatch(setTablePage(0));
      const fashId =
        methods.watch("fash")?.title === "A"
          ? 1
          : methods.watch("fash")?.title === "B"
          ? 2
          : methods.watch("fash")?.title === "C"
          ? 3
          : 0;
      if (tabOption === 0) {
        const payload = {
          ...(methods.watch("fash") && {
            fash_id: fashId,
          }),
          ...(methods.watch("provider") && {
            provider_id: methods.watch("provider")?.id,
          }),
          ...(methods.watch("company") && {
            supplier_id: methods.watch("company")?.id,
          }),
          ...(methods.watch("location") && {
            warehouse_id: methods.watch("location")?.id,
          }),
          offset: tableOffset,
          limit: limit,
        };
        dispatch(getReceiptsLog(payload));
      }

      if (tabOption === 1) {
        const warehouseLogParams = {
          ...(methods.watch("fash") && {
            fash_id: fashId,
          }),
          ...(methods.watch("company") && {
            supplier_id: methods.watch("company")?.id,
          }),
          ...(methods.watch("from") && {
            from_id: methods.watch("from")?.id,
          }),
          ...(methods.watch("to") && {
            to_id: methods.watch("to")?.id,
          }),
          offset: tableOffset,
          limit: limit,
        };
        dispatch(getTransferWarehouseLogs(warehouseLogParams));
      }

      if (tabOption === 2) {
        const transferLogParams = {
          ...(methods.watch("fash") && {
            fash_id: fashId,
          }),
          ...(methods.watch("company") && {
            supplier_id: methods.watch("company")?.id,
          }),
          ...(methods.watch("from") && {
            from_id: methods.watch("from")?.id,
          }),
          ...(methods.watch("to_construction_crew") && {
            to_id: methods.watch("to_construction_crew")?.id,
          }),
          offset: tableOffset,
          limit: limit,
        };
        dispatch(getTransferConstructionCrewLogs(transferLogParams));
      }
    }, [
      methods.watch("fash"),
      methods.watch("provider"),
      methods.watch("company"),
      methods.watch("location"),
      methods.watch("from"),
      methods.watch("to"),
      methods.watch("to_construction_crew"),
      tabOption,
    ]);

    const headers = getTableHeaders(tabOption);

    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }
    const [searchTerm, setSearchTerm] = useState<string>("");
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      methods.reset();
      dispatch(setTabOption(newValue));
    };

    const dataSource =
      tabOption === 0
        ? receiptsLogData?.data
        : tabOption === 1 || tabOption === 2
        ? transferLogData?.data
        : [];

    const { constructor_crew } = useSelector(selectTransferProductsToCrew);
    const { fasis, location, brands, companies } =
      useSelector(selectFashDetails);
    const [item, setItem] = useState<{ title: string }>({ title: "" });

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Tabs
          value={tabOption}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={<TextWorkSans color="primary">ΠΑΡΑΛΑΒΕΣ</TextWorkSans>}
            {...a11yProps(0)}
          />{" "}
          <Tab
            label={
              <TextWorkSans color="primary">
                ΜΕΤΑΚΙΝΗΣΗ ΠΡΟΣ ΑΠΟΘΗΚΗ
              </TextWorkSans>
            }
            {...a11yProps(0)}
          />{" "}
          <Tab
            label={
              <TextWorkSans color="primary">
                ΜΕΤΑΚΙΝΗΣΗ ΠΡΟΣ ΣΥΝΕΡΓΕΙΟ
              </TextWorkSans>
            }
            {...a11yProps(0)}
          />{" "}
        </Tabs>
        <div style={{ marginTop: "25px", marginBottom: "25px" }}>
          {/* <TextWorkSans fontSize={22} color={"secondary"}>
          Πολυκατοικίες
        </TextWorkSans> */}
        </div>
        <FormProvider {...methods}>
          <SearchBar value={value} />
        </FormProvider>
        {/* 
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <TextField
              maxwidth={280}
              maxLength={10}
              type="text"
              label="Αναζήτηση"
              placeholder="Αναζήτηση"
              value={searchTerm}
              onChange={(value) => {
                setSearchTerm(value);
              }}
            />
          </div>
          <SelectComponent
            maxWidth={"180px"}
            label="Διάλεξε Συνεργείο"
            placeholder="= Επέλεξε -"
            items={
              constructor_crew as {
                title: string;
                id?: string;
              }[]
            }
            value={crew}
            noneOption={true}
            onChange={(value: any) => {
              setCrew(value);
            }}
          />
          <SelectComponent
            maxWidth={"180px"}
            label="Διάλεξε Πόλη"
            placeholder="= Επέλεξε -"
            items={
              location as {
                title: string;
                id?: string;
              }[]
            }
            value={city}
            noneOption={true}
            onChange={(value: any) => {
              setCity(value);
            }}
          />

         <TextField
              maxwidth={280}
              maxLength={10}
              type="text"
              label="Αναζήτηση"
              placeholder="Αναζήτηση"
              value={searchTerm}
              onChange={(value) => {
                setSearchTerm(value);
              }}
            /> 
          <div style={{ marginTop: "auto" }}>
            <Button
              padding={0}
              size="medium"
              backgroundColor="primary"
              color="white"
              type="button"
              fontSize={12}
              onClick={() => {
                setSearchTerm("");
                setCrew(undefined);
                setCity(undefined);
                setStatus(undefined);
              }}
            >
              ΚΑΘΑΡΙΣΜΑ ΦΙΛΤΡΩΝ
            </Button>
          </div>
        </div> */}

        <Table style={{ width: "100%" }}>
          <TableHead>
            <HeaderRow>
              {headers.map((header) => (
                <CustomTableCell key={header.id} width={"auto"}>
                  <TextWorkSans color="primary" fontSize={header.fontSize}>
                    {header.label}
                  </TextWorkSans>
                </CustomTableCell>
              ))}
            </HeaderRow>
          </TableHead>
          {loader ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ verticalAlign: "middle", height: "200px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress color={"primary"} size={50} />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {dataSource?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={10}
                    style={{ verticalAlign: "middle", height: "200px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <TextWorkSans>NO MORE DATA</TextWorkSans>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                dataSource?.map((dataLog, index) => (
                  <ExpandableTableRow
                    key={dataLog.id}
                    dataLog={dataLog}
                    index={index}
                  />
                ))
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          sx={tablePaginationStyles}
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={
            tabOption === 0
              ? receiptsLogData?.totalReceipts ?? 0
              : tabOption === 1 || tabOption === 2
              ? transferLogData?.totalTransfers ?? 0
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={tablePage}
          onPageChange={handleChangePage}
        />
      </div>
    );
  };

export default TableComponent;
