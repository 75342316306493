import { CancelTokenSource } from "axios";
import { METHODS, request } from "../../slices/axiosInstances";

export default {
  addBuilding: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.POST, "/addBuilding.php", { params }),
  updateBuildingStatus: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.POST, "/updateBuildingStatus.php", { params }),
  getBuildings: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getBuildings.php", { params }),
  getDashboardDetails: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getDashboardDetails.php", { params }),
  uploadBuildingExcel: (
    file: File
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => {
    const formData = new FormData();
    formData.append("file", file);

    return request(
      METHODS.POST,
      "/addBuildingsWithExcel.php",
      { params: formData },
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  },
};
