import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const EOS = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width="67px"
      height="31px"
      viewBox="0 0 67 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          x="-26.9%"
          y="-58.1%"
          width="156.2%"
          height="215.5%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          x="-11.7%"
          y="-26.0%"
          width="123.3%"
          height="151.9%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="1.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Elements-/-Buy-Tickets-/-Footer">
          <g id="Icon-/-EOS-/-with-Text" filter="url(#filter-1)">
            <g filter="url(#filter-2)">
              <text
                id="Build-on"
                fontFamily="Helvetica"
                fontSize="7"
                fontWeight="normal"
                fill="#FFFFFF"
              >
                <tspan x="39.6955566" y="9.5487013">
                  Build on{" "}
                </tspan>
              </text>
              <g id="Icon-/-EOS-">
                <ellipse
                  id="Эллипс_8"
                  fill="#FF652F"
                  fillRule="nonzero"
                  cx="15.75"
                  cy="15.4"
                  rx="15.75"
                  ry="15.4"
                ></ellipse>
                <path
                  d="M15.9907619,4.36716418 L10.9385532,11.1160791 L8.22761194,21.9625494 L15.8675373,26.6626866 L23.5074627,21.9625494 L21.0429706,11.1160791 L15.9907619,4.36716418 Z M15.7443127,5.33129488 L11.4314516,11.2365954 L12.540473,14.8520855 L15.7443127,9.54936668 L15.7443127,5.33129488 Z M16.2372111,5.33129488 L16.2372111,9.54936668 L19.4410508,14.8520855 L20.5500722,11.2365954 L16.2372111,5.33129488 Z M15.9907619,9.91091569 L12.6636976,15.3341509 L14.7585159,21.6010004 L17.0997833,21.6010004 L19.3178262,15.3341509 L15.9907619,9.91091569 Z M20.7965214,12.0802098 L19.8107246,15.2136345 L22.7681151,20.5163534 L20.7965214,12.0802098 Z M12.1707992,15.2136345 L11.1850024,11.9596934 L9.09018416,20.395837 L12.1707992,15.2136345 Z M12.4172484,15.5751835 L8.84373495,21.6010004 L14.2656175,21.6010004 L12.4172484,15.5751835 Z M17.5926818,21.6010004 L22.8913397,21.6010004 L19.5642754,15.5751835 L17.5926818,21.6010004 Z M9.33663337,22.0830658 L14.3888421,22.0830658 L15.6210881,25.9395885 L9.33663337,22.0830658 Z M14.8817405,22.0830658 L15.8675373,25.4575232 L16.9765587,22.0830658 L14.8817405,22.0830658 Z M17.4694571,22.0830658 L16.1139865,25.9395885 L22.5216659,22.0830658 L17.4694571,22.0830658 Z"
                  id="Фигура_2"
                  stroke="#FFFFFF"
                  strokeWidth="0.7"
                  fill="#FFFFFF"
                ></path>
              </g>
              <text
                id="EOS"
                fontFamily="Helvetica"
                fontSize="13.44"
                fontWeight="normal"
                fill="#FFFFFF"
              >
                <tspan x="37.9585938" y="24.9">
                  EOS{" "}
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EOS;
