import { CancelTokenSource } from "axios";
import { METHODS, request } from "../../slices/axiosInstances";

export default {
  getBuildingsAsCrew: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getBuildingsAsCrew.php", { params }),
  getProductsAsCrew: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.GET, "/getProductsAsCrew.php", { params }),
  updateBuildingStatusAsCrew: (
    params?: any
  ): {
    request: () => Promise<any>;
    cancelTokenSource: CancelTokenSource;
  } => request(METHODS.POST, "/updateBuildingStatusAsCrew.php", { params }),
};
