import React, { useEffect, useState } from "react";
import { DisableComponent, TextWorkSans } from "../../components/common.style";
import { useNavigate } from "react-router-dom";
import { FormValues, addReceipt } from "../../slices/receiptSlice/receiptSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormData,
  selectFashDetails,
} from "../../slices/receiptSlice/selectors";
import { AppDispatch } from "../../slices/store";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TableComponent from "../AddReceipt/Table";
import SelectComponent from "../../components/Select/Select";
import { TextField } from "../../components";
import Button from "../../components/Button/Button";
import TextAreaComponent from "../../components/TextArea/TextArea";

const PreviewPageReceipt: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { control, watch, setValue, handleSubmit, getValues } =
    useForm<FormValues>({
      defaultValues: {
        fash: undefined,
        provider: undefined,
        company: undefined,
        location: undefined,
        couponNumber: "",
        products: [],
        pickupDate: "",
        couponDate: "",
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const formData = useSelector(getFormData);

  useEffect(() => {
    if (formData) {
      console.log(formData);
      setValue("fash", formData.fash);
      setValue("location", formData.location);
      setValue("provider", formData.provider);
      setValue("company", formData.company);
      setValue("couponNumber", formData.couponNumber);
      setValue("pickupDate", formData.pickupDate);
      setValue("couponDate", formData.couponDate);
      setValue("products", formData.products);
      console.log(formData.notes);
      setValue("work", formData.work);
      setValue("notes", formData.notes);
    }
  }, [formData]);

  const allFieldsFilled = (): boolean => {
    const values = watch();

    if (
      values.fash &&
      values.provider &&
      values.location &&
      values.company &&
      values.couponNumber &&
      values.products.length > 0 &&
      values.pickupDate &&
      values.couponDate
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (data: FormValues) => {
    const fashId =
      data.fash.title === "A"
        ? 1
        : data.fash.title === "B"
        ? 2
        : data.fash.title === "C"
        ? 3
        : 0;
    const payload = {
      supplier_id: parseInt(data.provider.id),
      employee_id: 1,
      fash_id: fashId,
      provider_id: parseInt(data.company.id),
      batch_number: data.couponNumber,
      receipt_date: data.pickupDate,
      delivery_date: data.couponDate,
      warehouse_id: parseInt(data.location.id),
      notes: data.notes,
      work: data.work,
      products: data.products.map((product: any) => ({
        product_id: product.productId,
        quantity: product.quantity === "" ? 0 : parseInt(product.quantity, 10),
      })),
    };
    dispatch(addReceipt({ receipt: payload, navigate: history }));
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };
  const navigate = useNavigate();

  const [previewPage, setPreviewPage] = useState<boolean>(false);

  const { fasis, location, brands, companies } = useSelector(selectFashDetails);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      style={{
        display: "flex",
        gap: "40px",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        padding: "40px",
        width: "100%",
        maxWidth: "1300px",
        marginTop: "40px",
        marginBottom: "40px",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
      }}
    >
      <div
        style={{
          marginTop: "25px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextWorkSans fontSize={25} color={"secondary"}>
          ΕΠΙΘΕΩΡΗΣΗ ΦΟΡΜΑΣ
        </TextWorkSans>
        <div style={{ marginTop: "auto" }}>
          <Button
            padding={0}
            size="medium"
            backgroundColor="primary"
            color="white"
            disabled={!allFieldsFilled()}
            type="submit"
            fontSize={12}
          >
            ΠΡΟΣΘΗΚΗ ΠΑΡΑΛΑΒΗΣ
          </Button>
        </div>
      </div>
      <DisableComponent
        opacity={0.7}
        disabled={true}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <Controller
            control={control}
            name="provider"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Πάροχο"
                placeholder="= Επέλεξε -"
                {...field}
                items={companies}
                value={watch("provider")}
              />
            )}
          />

          <Controller
            control={control}
            name="fash"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"180px"}
                required
                label="Διάλεξε Φάση"
                placeholder="= Επέλεξε -"
                {...field}
                items={fasis}
                value={watch("fash")}
              />
            )}
          />

          <Controller
            control={control}
            name="company"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Εταιρεία"
                placeholder="= Επέλεξε -"
                {...field}
                items={brands}
                value={watch("company")}
              />
            )}
          />

          <Controller
            control={control}
            name="location"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Τόπο Παραλαβή"
                placeholder="= Επέλεξε -"
                {...field}
                items={location}
                value={watch("location")}
              />
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <Controller
            name="pickupDate"
            control={control}
            render={({ field }) => (
              <TextField
                formToday
                maxwidth={200}
                label={"Ημερομηνία Παραλαβής"}
                type="date"
                required
                placeholder="day"
                {...field}
              />
            )}
          />
          <Controller
            name="couponDate"
            control={control}
            render={({ field }) => (
              <TextField
                formToday
                maxwidth={200}
                label={"Ημερομηνία Δελτίου"}
                type="date"
                required
                placeholder="day"
                {...field}
              />
            )}
          />
          <Controller
            name="couponNumber"
            control={control}
            render={({ field }) => (
              <TextField
                required
                maxwidth={280}
                maxLength={10}
                type="text"
                label="Αρ. Δελτίου"
                placeholder="Αριθμός Δελτίου"
                {...field}
              />
            )}
          />
          <Controller
            name="work"
            control={control}
            render={({ field }) => (
              <TextField
                required
                maxwidth={280}
                maxLength={10}
                type="text"
                label="Έργο"
                placeholder="Έργο"
                {...field}
              />
            )}
          />
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextAreaComponent
                maxwidth={600}
                minWidth={500}
                height="150px"
                label={"Παρατηρήσεις"}
                type="text"
                required
                placeholder="Παρατηρήσεις"
                {...field}
                value={watch("notes")}
              />
            )}
          />
        </div>
        <TableComponent
          watch={watch}
          control={control}
          fields={fields}
          remove={remove}
          append={append}
          setValue={setValue}
          getValues={getValues}
          previewTable
        />
      </DisableComponent>
    </form>
  );
};

export default PreviewPageReceipt;
