import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Error = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "25"}
      height={height ? height : "25"}
      fill="none"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_2301_17206" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.501 14.3356C11.501 14.8879 11.9487 15.3356 12.501 15.3356C13.0533 15.3356 13.501 14.8879 13.501 14.3356V8.44727C13.501 7.89498 13.0533 7.44727 12.501 7.44727C11.9487 7.44727 11.501 7.89498 11.501 8.44727V14.3356ZM12.5005 19.1768C13.2396 19.1768 13.8389 18.5776 13.8389 17.8384C13.8389 17.0993 13.2396 16.5001 12.5005 16.5001C11.7613 16.5001 11.1621 17.0993 11.1621 17.8384C11.1621 18.5776 11.7613 19.1768 12.5005 19.1768Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.501 14.3356C11.501 14.8879 11.9487 15.3356 12.501 15.3356C13.0533 15.3356 13.501 14.8879 13.501 14.3356V8.44727C13.501 7.89498 13.0533 7.44727 12.501 7.44727C11.9487 7.44727 11.501 7.89498 11.501 8.44727V14.3356ZM12.5005 19.1768C13.2396 19.1768 13.8389 18.5776 13.8389 17.8384C13.8389 17.0993 13.2396 16.5001 12.5005 16.5001C11.7613 16.5001 11.1621 17.0993 11.1621 17.8384C11.1621 18.5776 11.7613 19.1768 12.5005 19.1768Z"
        fill={colors[color || "candyRed"]}
      />
      <path
        d="M12.501 14.3356H10.501C10.501 15.4402 11.3964 16.3356 12.501 16.3356V14.3356ZM12.501 14.3356V14.3356V16.3356C13.6055 16.3356 14.501 15.4402 14.501 14.3356H12.501ZM12.501 8.44727V14.3356H14.501V8.44727H12.501ZM12.501 8.44727V8.44727H14.501C14.501 7.3427 13.6055 6.44727 12.501 6.44727V8.44727ZM12.501 8.44727V8.44727V6.44727C11.3964 6.44727 10.501 7.3427 10.501 8.44727H12.501ZM12.501 14.3356V8.44727H10.501V14.3356H12.501ZM12.8389 17.8384C12.8389 18.0253 12.6874 18.1768 12.5005 18.1768V20.1768C13.7919 20.1768 14.8389 19.1299 14.8389 17.8384H12.8389ZM12.5005 17.5001C12.6874 17.5001 12.8389 17.6516 12.8389 17.8384H14.8389C14.8389 16.547 13.7919 15.5001 12.5005 15.5001V17.5001ZM12.1621 17.8384C12.1621 17.6516 12.3136 17.5001 12.5005 17.5001V15.5001C11.209 15.5001 10.1621 16.547 10.1621 17.8384H12.1621ZM12.5005 18.1768C12.3136 18.1768 12.1621 18.0253 12.1621 17.8384H10.1621C10.1621 19.1299 11.209 20.1768 12.5005 20.1768V18.1768Z"
        fill={colors[color || "candyRed"]}
        mask="url(#path-1-inside-1_2301_17206)"
      />
      <path
        d="M15.1163 5.22971L21.8377 17.199C22.9607 19.1988 21.5155 21.6679 19.2219 21.6679H5.77908C3.48556 21.6679 2.04031 19.1988 3.16331 17.199L9.88474 5.2297C11.0311 3.18825 13.9699 3.18825 15.1163 5.22971Z"
        stroke={colors[color || "candyRed"]}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Error;
