// Home.tsx
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { DisableComponent, TextWorkSans } from "../../components/common.style";
import { shouldForwardProp } from "@mui/system";
import CustomTableRow from "./Table/TableRow";
import { ColorsType } from "../../theme/types";
import { Product } from "../../slices/productsSlice/productsSlice";
import SelectComponent from "../../components/Select/Select";
import { TextField } from "../../components";
import Button from "../../components/Button/Button";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FormValues } from "../../slices/receiptTransferSlice/receiptTransferSlice";
import {
  getTransferedProducts,
  selectTransferDetails,
} from "../../slices/receiptTransferSlice/selectors";

export interface CustomTableCellProps extends TableCellProps {
  disablePadding?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  backgroundColor?: ColorsType;
  whiteSpace?: boolean;
}
export const CustomTableCell = styled(TableCell, {
  shouldForwardProp: (prop: any) =>
    shouldForwardProp(prop) && prop !== "disablePadding",
})<CustomTableCellProps>(
  ({ disablePadding, width, maxWidth, whiteSpace, backgroundColor }: any) => {
    const processedWidth = typeof width === "number" ? `${width}px` : width;
    const processedMaxWidth =
      typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;
    return {
      color: "white",
      "&.MuiTableCell-head": {
        color: "#7879F1",
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
      "&.MuiTableCell-body": {
        color: "white",
        fontSize: 14,
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: whiteSpace ? "nowrap" : "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
    };
  }
);

export const HeaderRow = styled(TableRow)({
  color: "#7879F1",
  "&.MuiTableRow-head": {
    backgroundColor: "transparent",
    color: "#7879F1",
  },
});

interface Props {
  watch: UseFormWatch<FormValues>;
  control: Control<FormValues, any>;
  fields: FieldArrayWithId<FormValues, "products", "id">[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FormValues, "products">;
  setValue: UseFormSetValue<FormValues>;
  previewTable?: boolean;
  getValues: UseFormGetValues<FormValues>;
}

const TableComponent: React.FC<Props> = ({
  control,
  previewTable,
  getValues,
  fields,
  watch,
}) => {
  const getProductsArray = useSelector(getTransferedProducts);

  useEffect(() => {
    watch("products");
  }, []);
  const [loader, setLoader] = useState<boolean>(false);
  const { typos } = useSelector(selectTransferDetails);

  function filterProductsByType(
    typeFilter: string | null | undefined
  ): Product[] {
    if (previewTable) {
      const productIdsFromForm = getValues()
        .products?.filter(
          (product) =>
            product.productId !== undefined &&
            product.quantity !== undefined &&
            product.quantity !== null &&
            product.quantity !== "" &&
            product.quantity !== " " &&
            Number(product.quantity) > 0
        )
        .map((product) => product.productId);

      return getProductsArray.filter((product) =>
        productIdsFromForm?.includes(product.product_id)
      );
    }
    if (!typeFilter) {
      return getProductsArray;
    } else {
      return getProductsArray.filter((product) => product.typos === typeFilter);
    }
  }

  const tableHeaders = [
    { id: "id", label: "Id", align: "left", fontSize: 12, color: "primary" },
    {
      id: "kay",
      label: "KAY",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "name",
      label: "Όνομα",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "mm",
      label: "MM",
      align: "left",
      fontSize: 12,
      color: "primary",
      whiteSpace: "desktopMode",
    },
    {
      id: "abbreviation",
      label: "Συντόμευση",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "type",
      label: "Τύπος",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "current_quantity",
      label: "Απόθεμα",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "quantity",
      label: "Ποσότητα",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
  ];

  const [item, setItem] = useState<{ title: string }>({ title: "" });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const filteredProducts = filterProductsByType(item.title).filter((product) =>
    product.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ marginTop: "25px", marginBottom: "25px" }}>
        <TextWorkSans fontSize={22} color={"secondary"}>
          Προϊόντα
        </TextWorkSans>
      </div>
      <DisableComponent
        disabled={getProductsArray.length === 0}
        style={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        {!previewTable && (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <SelectComponent
              maxWidth={"180px"}
              label="Διάλεξε Τύπο"
              placeholder="= Επέλεξε -"
              items={typos}
              value={item}
              noneOption={true}
              onChange={(value: any) => {
                setItem(value);
              }}
            />
            <TextField
              maxwidth={280}
              maxLength={10}
              type="text"
              label="Αναζήτηση"
              placeholder="Αναζήτηση"
              value={searchTerm}
              onChange={(value) => {
                setSearchTerm(value);
              }}
            />
            <div style={{ marginTop: "auto" }}>
              <Button
                padding={0}
                size="medium"
                backgroundColor="primary"
                color="white"
                type="button"
                fontSize={12}
                onClick={() => {
                  setSearchTerm("");
                  setItem({ title: "" });
                }}
              >
                ΚΑΘΑΡΙΣΜΑ ΦΙΛΤΡΩΝ
              </Button>
            </div>
          </div>
        )}
      </DisableComponent>
      <Table style={{ width: "100%" }}>
        <TableHead>
          <HeaderRow>
            {tableHeaders.map((header) => (
              <CustomTableCell key={header.id} width={"auto"}>
                <TextWorkSans color="primary" fontSize={header.fontSize}>
                  {header.label}
                </TextWorkSans>
              </CustomTableCell>
            ))}
          </HeaderRow>
        </TableHead>
        {loader ? (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={10}
                style={{ verticalAlign: "middle", height: "200px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress color={"primary"} size={50} />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {getProductsArray.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ verticalAlign: "middle", height: "200px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <TextWorkSans>NO MORE DATA</TextWorkSans>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              filteredProducts.map((product, index) => (
                <CustomTableRow
                  key={product.product_id}
                  product={product}
                  control={control}
                  fields={fields}
                />
              ))
            )}
          </TableBody>
        )}
      </Table>
    </div>
  );
};

export default TableComponent;
