import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../slices/store";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import TableComponent from "./Table";
import { getProducts } from "../../slices/productsSlice/selectors";
import { TextWorkSans } from "../../components/common.style";
import Button from "../../components/Button/Button";
import {
  getFormData,
  selectFashDetails,
} from "../../slices/receiptSlice/selectors";
import {
  getProductsAsCrew,
  updateBuildingStatusAsCrew,
} from "../../slices/crewDashboardSlice/crewDashboardSlice";
import { getProductsStatus } from "../../slices/crewDashboardSlice/selectors";

export interface FormValues {
  products: { productId: number; quantity: string }[];
}
const CrewProducts: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { control, watch, setValue, handleSubmit, getValues, resetField } =
    useForm<FormValues>({
      defaultValues: {
        products: undefined,
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products",
  });
  const watchProducts = useWatch({ control, name: "products" }) as {
    productId: number;
    quantity: string;
  }[];

  const updateFieldsBasedOnSelection = () => {
    // Reset the existing products data in the form
    resetField("products");

    // Append all products from getProductsArray with a default quantity
    getProductsArray.forEach((product) => {
      // Append the product with a default quantity (e.g., an empty string or a default number)
      append({
        productId: product.product_id,
        quantity: "", // Default quantity, you can change this if needed
      });
    });
  };

  const status = useSelector(getProductsStatus);

  const getProductsArray = useSelector(getProducts);
  // const allFieldsFilled = (): boolean => {
  //   const values = watch();
  //   if (values.products.length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  useEffect(() => {
    //dispatch(fetchFash());
    dispatch(getProductsAsCrew());
  }, [dispatch]);
  const formData = useSelector(getFormData);

  const dataStatus = useSelector(getProductsStatus);

  // useEffect(() => {
  //   const allValues = getValues();

  //   dispatch(
  //     updateFormValue({
  //       field: "products",
  //       value: allValues.products
  //         ? allValues.products.map((product) => ({ ...product }))
  //         : [],
  //     })
  //   );
  // }, [watchProducts]);

  // useEffect(() => {
  //   if (status === "succeeded") {
  //     updateFieldsBasedOnSelection();
  //   }
  // }, [status]);

  useEffect(() => {
    dispatch(getProductsAsCrew());
  }, []);

  // const updateFieldsBasedOnSelection = () => {
  //   // Reset the existing products data in the form
  //   resetField("products");

  //   // Map through getProductsArray to find and use quantities from formData if available
  //   getProductsArray.forEach((product) => {
  //     // Find the matching product from formData
  //     const matchingProduct = formData.products.find(
  //       (p) => p.productId === product.product_id
  //     );

  //     // Determine the quantity to append
  //     const quantityToAppend = matchingProduct ? matchingProduct.quantity : "";

  //     // Append the product with the appropriate quantity
  //     append({
  //       productId: product.product_id,
  //       quantity: quantityToAppend,
  //     });
  //   });
  // };
  const { id } = useParams(); // Get the id from the URL

  const onSubmit = (data: any) => {
    const payload = {
      building_id: id,
      products: data.products.map((product: any) => ({
        product_id: product.productId,
        quantity: product.quantity === "" ? 0 : parseInt(product.quantity, 10),
      })),
    };

    dispatch(updateBuildingStatusAsCrew(payload));
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };
  const [previewPage, setPreviewPage] = useState<boolean>(false);
  const { fasis, location, brands, companies } = useSelector(selectFashDetails);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      style={{
        display: "flex",
        gap: "40px",
        justifyContent: "center",
        flexDirection: "column",
        height: "auto",
        padding: "40px",
        width: "100%",
        maxWidth: "1300px",
        marginTop: "40px",
        marginBottom: "40px",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          marginTop: "25px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextWorkSans fontSize={25} color={"secondary"}>
          ΟΛΟΚΛΗΡΩΣΗ ΠΟΛΥΚΑΤΟΙΚΙΑΣ
        </TextWorkSans>
        <div style={{ marginTop: "auto" }}>
          <Button
            padding={0}
            size="medium"
            backgroundColor="primary"
            color="white"
            //disabled={!allFieldsFilled()}
            type="submit"
            fontSize={12}
          >
            ΟΛΟΚΛΗΡΩΣΗ ΠΟΛΥΚΑΤΟΙΚΙΑΣ
          </Button>
        </div>
      </div>

      {dataStatus === "loading" ? (
        <>loading</>
      ) : (
        <>
          {/* <SearchBar
        watch={watch}
        control={control}
        fields={fields}
        remove={remove}
        append={append}
        setValue={setValue}
      /> */}
          <TableComponent
            watch={watch}
            control={control}
            fields={fields}
            remove={remove}
            append={append}
            setValue={setValue}
            getValues={getValues}
          />
        </>
      )}
    </form>
  );
};

export default CrewProducts;
