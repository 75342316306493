import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Dots3 = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87891 13.0002C6.87891 14.1048 5.98348 15.0002 4.87891 15.0002C3.77434 15.0002 2.87891 14.1048 2.87891 13.0002C2.87891 11.8957 3.77434 11.0002 4.87891 11.0002C5.98348 11.0002 6.87891 11.8957 6.87891 13.0002ZM14 13.0002C14 14.1048 13.1046 15.0002 12 15.0002C10.8954 15.0002 10 14.1048 10 13.0002C10 11.8957 10.8954 11.0002 12 11.0002C13.1046 11.0002 14 11.8957 14 13.0002ZM19.1211 15.0002C20.2257 15.0002 21.1211 14.1048 21.1211 13.0002C21.1211 11.8957 20.2257 11.0002 19.1211 11.0002C18.0165 11.0002 17.1211 11.8957 17.1211 13.0002C17.1211 14.1048 18.0165 15.0002 19.1211 15.0002Z"
        fill={colors[color || "candyRed"]}
      />
    </svg>
  );
};

export default Dots3;
