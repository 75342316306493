import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const UnSellNft = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "25"}
      fill="none"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_204:14699)">
        <rect
          width="2"
          height="26.092"
          rx="1"
          transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 20.5186 22.4152)"
          fill={colors[color || "white"]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2541 4.92355L4.23371 12.9439C3.84318 13.3344 3.84318 13.9676 4.23371 14.3581L10.1413 20.2657C10.5318 20.6562 11.165 20.6562 11.5555 20.2657L19.5759 12.2454C19.8038 12.0174 19.9079 11.6935 19.8554 11.3755L19.0187 6.30455C18.949 5.88179 18.6176 5.55044 18.1949 5.48069L13.124 4.644C12.8059 4.59152 12.482 4.6956 12.2541 4.92355ZM20.9901 13.6596C21.674 12.9757 21.9862 12.0041 21.8287 11.0499L20.9921 5.97896C20.7828 4.71066 19.7888 3.71664 18.5205 3.50737L13.4496 2.67068C12.4953 2.51323 11.5237 2.82547 10.8399 3.50934L2.81949 11.5297C1.64792 12.7013 1.64792 14.6008 2.81949 15.7723L8.72708 21.6799C9.89865 22.8515 11.7981 22.8515 12.9697 21.6799L20.9901 13.6596ZM14.3043 9.05643C13.9903 9.37042 13.9903 9.87951 14.3043 10.1935C14.6183 10.5075 15.1274 10.5075 15.4414 10.1935C15.7554 9.87951 15.7554 9.37042 15.4414 9.05643C15.1274 8.74243 14.6183 8.74243 14.3043 9.05643ZM12.8901 7.64221C11.795 8.73726 11.795 10.5127 12.8901 11.6077C13.9851 12.7028 15.7605 12.7028 16.8556 11.6077C17.9506 10.5127 17.9506 8.73726 16.8556 7.64221C15.7605 6.54717 13.9851 6.54717 12.8901 7.64221Z"
          fill={colors[color || "white"]}
        />
      </g>
      <defs>
        <clipPath id="clip0_204:14699">
          <rect
            width="24"
            height="24"
            transform="translate(0 0.539734)"
            fill={colors[color || "white"]}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnSellNft;
