import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const StripeSuccess = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    // <?xml version="1.0" encoding="UTF-8"?>
    <svg
      width={width ? width : "84"}
      height={height ? height : "84"}
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle
        className="border"
        cx="42"
        cy="42"
        r="40"
        strokeLinecap="round"
        strokeWidth="4"
        stroke="#fcd669"
        fill="none"
      ></circle>
      <path
        className="checkmark"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338"
        strokeWidth="4"
        stroke="#fff"
        fill="none"
      ></path>
    </svg>
    // <svg
    //   width={width ? width : '17'}
    //   height={height ? height : '13'}
    //   viewBox="0 0 23 23"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M2.06607 6.27954C2.06607 5.63889 2.58542 5.11954 3.22607 5.11954H6.85139L6.85139 5.53967C6.85139 6.04593 7.26179 6.45634 7.76805 6.45634C8.27432 6.45634 8.68472 6.04593 8.68472 5.53967L8.68472 5.11954H19.2261C19.8667 5.11954 20.3861 5.63889 20.3861 6.27954V7.84776C19.0141 8.04842 17.9606 9.23004 17.9606 10.6577V12.6708C17.9606 14.0984 19.0141 15.2801 20.3861 15.4807V17.0489C20.3861 17.6896 19.8667 18.2089 19.2261 18.2089H8.68472L8.68472 17.7885C8.68472 17.2823 8.27432 16.8719 7.76806 16.8719C7.2618 16.8719 6.85139 17.2823 6.85139 17.7886L6.85139 18.2089H3.22607C2.58542 18.2089 2.06607 17.6896 2.06607 17.0489V15.5006C3.52142 15.378 4.66434 14.1578 4.66434 12.6708V10.6577C4.66434 9.17065 3.52142 7.95053 2.06607 7.82786V6.27954ZM0.226074 6.27954C0.226074 4.62269 1.56922 3.27954 3.22607 3.27954H19.2261C20.8829 3.27954 22.2261 4.62269 22.2261 6.27954V8.65771C22.2261 9.21 21.7784 9.65771 21.2261 9.65771H20.8006C20.2484 9.65771 19.8006 10.1054 19.8006 10.6577V12.6708C19.8006 13.2231 20.2484 13.6708 20.8006 13.6708H21.2261C21.7784 13.6708 22.2261 14.1185 22.2261 14.6708V17.0489C22.2261 18.7058 20.8829 20.0489 19.2261 20.0489H3.22607C1.56922 20.0489 0.226074 18.7058 0.226074 17.0489V14.6708C0.226074 14.1185 0.67379 13.6708 1.22607 13.6708H1.82434C2.37663 13.6708 2.82434 13.2231 2.82434 12.6708V10.6577C2.82434 10.1054 2.37663 9.65771 1.82434 9.65771H1.22607C0.67379 9.65771 0.226074 9.21 0.226074 8.65771V6.27954ZM8.68472 8.84464C8.68472 8.33838 8.27432 7.92797 7.76806 7.92797C7.2618 7.92797 6.85139 8.33838 6.85139 8.84464L6.85139 14.4838C6.85139 14.9901 7.26179 15.4005 7.76805 15.4005C8.27432 15.4005 8.68472 14.9901 8.68472 14.4838L8.68472 8.84464Z"
    //     fill={colors[color || 'metallicPearlWhite']}
    //   />
    // </svg>
  );
};

export default StripeSuccess;
