import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const UpArrow = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.093 7.24937C10.7285 7.62078 10.131 7.62358 9.76302 7.25559L6.02042 3.513L2.27658 7.25683C1.90764 7.62576 1.31001 7.62746 0.938996 7.26063V7.26063C0.561808 6.88769 0.563755 6.27792 0.943318 5.9074L6.02042 0.951294L11.0812 5.92611C11.4496 6.28833 11.4549 6.8806 11.093 7.24937V7.24937Z"
        fill={colors[color || "candyRed"]}
      />
    </svg>
  );
};

export default UpArrow;
