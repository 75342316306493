import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Google = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "21"}
      height={height ? height : "22"}
      fill="none"
      viewBox="0 0 21 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7688 5.44259L17.1379 2.68431C15.3368 1.18891 13.0231 0.290039 10.4996 0.290039C6.48022 0.290039 2.9934 2.57 1.26252 5.9071H1.26246C0.519022 7.3391 0.0996094 8.96539 0.0996094 10.69C0.0996094 12.4734 0.548191 14.1517 1.33904 15.6185L1.3389 15.6187C3.09548 18.8764 6.5389 21.0901 10.4995 21.0901C12.964 21.0901 15.2281 20.233 17.0103 18.8004L17.0098 18.7998L17.0096 18.7997L17.01 18.7998L17.0106 18.8003C18.8432 17.3272 20.166 15.2455 20.6723 12.8615C20.8214 12.1609 20.8997 11.4346 20.8997 10.69C20.8997 10.026 20.8374 9.37631 20.718 8.74719H16.3706H10.7284V12.8615H16.2895C15.7889 14.1975 14.8386 15.3135 13.6227 16.0266L13.6227 16.027L13.6223 16.0267C12.7063 16.5644 11.6389 16.8724 10.4995 16.8724C7.84866 16.8724 5.58755 15.2039 4.70924 12.86L4.70933 12.8597C4.45563 12.1848 4.31734 11.4534 4.31726 10.69C4.31726 9.98655 4.43498 9.31056 4.65098 8.68083L4.65105 8.68089C5.48533 6.25286 7.78877 4.50773 10.4996 4.50773C11.7002 4.50773 12.8208 4.85003 13.7688 5.44259Z"
        fill={colors[color || "metallicPearlWhite"]}
      />
    </svg>
  );
};

export default Google;
