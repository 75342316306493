import { JwtPayload, jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import usersAPI from "../providers/usersAPI";
import { setAxiosToken } from "../slices/axiosInstances";
import { useDispatch } from "react-redux";
import { loginUser } from "../slices/dashboardSlice/dashboardSlice";
import { LoginParams } from "../slices/dashboardSlice/types";
import { AppDispatch } from "../slices/store";

export enum AvailableRoles {
  USER = "user",
  BACKOFFICE = "backOffice",
  ADMIN = "admin",
}

interface CustomJwtPayload extends JwtPayload {
  role: string; // Add any other properties as needed
}

export const useAuthService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  // Utility functions
  const setToken = (token: string): void => {
    localStorage.setItem("jwt", token);
  };

  const getToken = (): string | null => {
    return localStorage.getItem("jwt");
  };

  const isTokenExpired = (token: string): boolean => {
    try {
      const decoded: any = jwtDecode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (err) {
      return true; // Assume the token is expired if error occurs
    }
  };

  const getRole = (): AvailableRoles => {
    const token = getToken();
    if (!token) return "User" as AvailableRoles;

    const decodedToken: any = jwtDecode(token);

    return (decodedToken.role.charAt(0).toUpperCase() +
      decodedToken.role.slice(1)) as AvailableRoles;
  };

  const getTokenDecoded = (): any => {
    const token = getToken();
    if (!token) return;
    const decodedToken: any = jwtDecode(token);

    return decodedToken;
  };

  const isLoggedIn = (): boolean => {
    const token = getToken();
    return !!token && !isTokenExpired(token);
  };

  const logout = (): void => {
    localStorage.removeItem("jwt");
    setAxiosToken("");
    navigate("/"); // Navigate to the homepage after logout
  };

  const handleLogin = async (params: LoginParams): Promise<void> => {
    const newParms = {
      username: params.username,
      password: params.password,
      navigate: navigate,
    };
    dispatch(loginUser(newParms));
  };

  const initializeAxiosToken = () => {
    const token = getToken();
    if (token) {
      setAxiosToken(token);
    }
  };

  return {
    logout,
    setToken,
    isLoggedIn,
    getTokenDecoded,
    handleLogin,
    getRole,
    initializeAxiosToken,
  };
};
