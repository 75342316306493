import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const TransferIntentory = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 48 48"
      fill={colors[color || "spaceShade"]}
      {...props}
    >
      <title>{"transfer-solid"}</title>
      <g data-name="Layer 2">
        <path fill="none" d="M0 0h48v48H0z" data-name="invisible box" />
        <g data-name="icons Q2">
          <path d="M19 26a2 2 0 0 0-2 2v4H7v-4a2 2 0 0 0-4 0v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V28a2 2 0 0 0-2-2ZM43 26a2 2 0 0 0-2 2v4H31v-4a2 2 0 0 0-4 0v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V28a2 2 0 0 0-2-2ZM34 16v1.2l-2.6-2.6a1.9 1.9 0 0 0-3 .2 2.1 2.1 0 0 0 .2 2.7l6 5.9a1.9 1.9 0 0 0 2.8 0l6-5.9a2.1 2.1 0 0 0 .2-2.7 1.9 1.9 0 0 0-3-.2L38 17.2V16a14 14 0 0 0-28 0v6a2 2 0 0 0 4 0v-6a10 10 0 0 1 20 0Z" />
        </g>
      </g>
    </svg>
  );
};

export default TransferIntentory;
