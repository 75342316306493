import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Search = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05037 5.33553C8.66119 4.19661 10.6585 3.74424 12.6028 4.07792C14.5472 4.4116 16.2793 5.50401 17.4182 7.11482C18.5572 8.72564 19.0095 10.7229 18.6759 12.6673C18.3422 14.6116 17.2498 16.3438 15.6389 17.4827C14.0281 18.6216 12.0309 19.074 10.0865 18.7403C8.14215 18.4066 6.40999 17.3142 5.27107 15.7034C4.13216 14.0926 3.67978 12.0953 4.01347 10.151C4.34715 8.20661 5.43956 6.47444 7.05037 5.33553ZM12.9411 2.10674C10.474 1.68333 7.93966 2.25734 5.89574 3.70249C3.85182 5.14763 2.46569 7.34553 2.04228 9.81267C1.61888 12.2798 2.19289 14.8141 3.63803 16.858C5.08318 18.902 7.28108 20.2881 9.74822 20.7115C12.2154 21.1349 14.7497 20.5609 16.7936 19.1157C16.9962 18.9725 17.1923 18.8218 17.3817 18.6642L20.3828 21.6653C20.7733 22.0558 21.4065 22.0558 21.797 21.6653C22.1875 21.2748 22.1875 20.6416 21.797 20.2511L18.775 17.2291C19.7307 16.009 20.3801 14.5608 20.647 13.0056C21.0704 10.5384 20.4964 8.00412 19.0513 5.96019C17.6061 3.91627 15.4082 2.53014 12.9411 2.10674Z"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default Search;
