import { IconProps } from "../Icon.interface";

const CTHorBlack = ({ color, width, height, ...props }: IconProps) => {
  return (
    <svg
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      viewBox="0 0 715 125"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <g transform="matrix(1,0,0,1,-32563.2,-30419.6)">
        <g transform="matrix(0.981507,0,0,1.6278,739.369,-15939)">
          <g transform="matrix(1.94132,0,0,1.17055,32404.4,28477.5)">
            <g transform="matrix(0.649606,0,0,0.649606,82.3276,32.6094)">
              <path
                d="M0,0.002C0,-15.378 11.803,-26.18 27.897,-26.18C37.698,-26.18 45.422,-22.602 50.358,-16.165L39.7,-6.651C36.768,-10.371 33.263,-12.518 28.756,-12.518C21.746,-12.518 17.025,-7.653 17.025,0.002C17.025,7.655 21.746,12.519 28.756,12.519C33.263,12.519 36.768,10.372 39.7,6.653L50.358,16.168C45.422,22.605 37.698,26.182 27.897,26.182C11.803,26.182 0,15.379 0,0.002"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,130.459,37.7343)">
              <path
                d="M0,-0.062C0,-4.523 -2.45,-6.784 -5.59,-6.784C-8.731,-6.784 -11.181,-4.523 -11.181,-0.062C-11.181,4.396 -8.731,6.721 -5.59,6.721C-2.45,6.721 0,4.396 0,-0.062M-25.565,-0.062C-25.565,-10.49 -17.21,-17.838 -5.59,-17.838C6.093,-17.838 14.384,-10.49 14.384,-0.062C14.384,10.365 6.093,17.776 -5.59,17.776C-17.21,17.776 -25.565,10.365 -25.565,-0.062"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,178.89,38.8771)">
              <path
                d="M0,-4.209L0,15.389L-14.194,15.389L-14.194,-1.759C-14.194,-6.03 -15.765,-7.664 -18.026,-7.664C-20.54,-7.664 -22.486,-5.969 -22.486,-1.384L-22.486,15.389L-36.682,15.389L-36.682,-1.759C-36.682,-6.03 -38.126,-7.664 -40.514,-7.664C-43.026,-7.664 -44.973,-5.969 -44.973,-1.384L-44.973,15.389L-59.169,15.389L-59.169,-18.971L-45.664,-18.971L-45.664,-15.767C-43.088,-18.342 -39.698,-19.598 -35.929,-19.598C-31.468,-19.598 -27.574,-17.965 -25.187,-14.385C-22.424,-17.715 -18.404,-19.598 -13.692,-19.598C-5.904,-19.598 0,-15.013 0,-4.209"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,188.923,39.2851)">
              <path
                d="M0,-4.837L10.994,-6.973C10.24,-9.108 8.417,-10.554 5.843,-10.554C2.638,-10.554 0.44,-8.732 0,-4.837M14.447,2.387L21.859,9.86C18.466,13.504 13.63,15.389 6.846,15.389C-5.778,15.389 -13.944,7.788 -13.944,-2.449C-13.944,-12.877 -5.527,-20.226 5.779,-20.226C15.766,-20.226 24.434,-14.07 24.434,-2.639L0.942,1.884C2.074,4.019 4.21,4.899 7.288,4.899C10.427,4.899 12.186,4.082 14.447,2.387"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,228.999,36.7457)">
              <path
                d="M0,-13.877C-3.719,-15.952 -7.51,-17.454 -11.729,-18.312L-11.729,18.67L-28.611,18.67L-28.611,-18.312C-32.831,-17.454 -36.624,-15.952 -40.343,-13.877L-45.922,-25.895C-38.698,-30.258 -29.542,-32.547 -20.171,-32.547C-10.801,-32.547 -1.645,-30.258 5.58,-25.895L0,-13.877Z"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,241.022,37.7343)">
              <path
                d="M0,-0.062C0,-4.523 -2.448,-6.784 -5.59,-6.784C-8.73,-6.784 -11.179,-4.523 -11.179,-0.062C-11.179,4.396 -8.73,6.721 -5.59,6.721C-2.448,6.721 0,4.396 0,-0.062M-25.564,-0.062C-25.564,-10.49 -17.211,-17.838 -5.59,-17.838C6.095,-17.838 14.384,-10.49 14.384,-0.062C14.384,10.365 6.095,17.776 -5.59,17.776C-17.211,17.776 -25.564,10.365 -25.564,-0.062"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,266.808,46.4658)">
              <path
                d="M0,-14.76C0,-18.09 -2.514,-20.225 -5.842,-20.225C-9.172,-20.225 -11.746,-18.09 -11.746,-14.76C-11.746,-11.431 -9.172,-9.232 -5.842,-9.232C-2.514,-9.232 0,-11.431 0,-14.76M14.069,-30.653L14.069,-3.14C14.069,10.177 6.28,16.52 -6.848,16.52C-13.631,16.52 -19.787,15.076 -24.058,12.249L-19.285,2.577C-16.584,4.649 -11.998,5.968 -8.292,5.968C-2.45,5.968 -0.127,3.392 -0.127,-1.194L-0.127,-1.822C-2.263,0.628 -5.402,1.823 -9.61,1.823C-18.343,1.823 -26.131,-4.584 -26.131,-14.76C-26.131,-24.936 -18.343,-31.28 -9.61,-31.28C-4.9,-31.28 -1.57,-29.835 0.565,-26.758L0.565,-30.653L14.069,-30.653Z"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,285.656,39.2851)">
              <path
                d="M0,-4.837L10.993,-6.973C10.239,-9.108 8.418,-10.554 5.843,-10.554C2.64,-10.554 0.44,-8.732 0,-4.837M14.446,2.387L21.859,9.86C18.468,13.504 13.631,15.389 6.847,15.389C-5.778,15.389 -13.943,7.788 -13.943,-2.449C-13.943,-12.877 -5.527,-20.226 5.779,-20.226C15.766,-20.226 24.435,-14.07 24.435,-2.639L0.942,1.884C2.074,4.019 4.21,4.899 7.287,4.899C10.427,4.899 12.187,4.082 14.446,2.387"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,317.522,22.7184)">
              <path
                d="M0,39.07C-2.198,40.326 -5.401,40.891 -8.731,40.891C-18.215,40.891 -23.743,36.43 -23.743,26.947L-23.743,-1.821L-9.548,-1.821L-9.548,7.789L-2.512,7.789L-2.512,18.153L-9.548,18.153L-9.548,26.822C-9.548,28.958 -8.291,30.15 -6.596,30.15C-5.464,30.15 -4.334,29.837 -3.392,29.209L0,39.07Z"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,342.247,31.3281)">
              <path
                d="M0,7.412L0,27.01L-14.196,27.01L-14.196,9.862C-14.196,5.591 -15.828,3.957 -18.403,3.957C-21.293,3.957 -23.742,5.779 -23.742,10.866L-23.742,27.01L-37.938,27.01L-37.938,-19.598L-23.742,-19.598L-23.742,-4.585C-21.104,-6.847 -17.775,-7.977 -14.069,-7.977C-6.155,-7.977 0,-3.392 0,7.412"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,352.282,39.2851)">
              <path
                d="M0,-4.837L10.992,-6.973C10.238,-9.108 8.417,-10.554 5.841,-10.554C2.639,-10.554 0.44,-8.732 0,-4.837M14.447,2.387L21.858,9.86C18.466,13.504 13.629,15.389 6.847,15.389C-5.779,15.389 -13.945,7.788 -13.945,-2.449C-13.945,-12.877 -5.528,-20.226 5.777,-20.226C15.765,-20.226 24.435,-14.07 24.435,-2.639L0.94,1.884C2.073,4.019 4.209,4.899 7.286,4.899C10.427,4.899 12.185,4.082 14.447,2.387"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.649606,0,0,0.649606,384.801,48.8735)">
              <path
                d="M0,-34.986L0,-22.424C-1.257,-22.612 -2.136,-22.674 -3.142,-22.674C-7.413,-22.674 -10.428,-20.727 -10.428,-15.263L-10.428,0L-24.623,0L-24.623,-34.359L-11.119,-34.359L-11.119,-30.652C-8.605,-33.542 -4.774,-34.986 0,-34.986"
                fillRule="nonzero"
              />
            </g>
          </g>
          <g transform="matrix(0.60627,0,0,0.36556,32335.2,28463.7)">
            <g transform="matrix(0.698081,0,0,0.698081,169.314,182.562)">
              <path
                d="M0,64.878C-42.17,20.936 -33.178,-42.171 -33.178,-42.171L5.284,-37.544L19.428,-10.503C-6.235,21.002 16.146,48.765 16.146,48.765C16.146,48.765 43.778,71.015 75.315,45.385L102.291,59.463L106.984,97.99C106.984,97.99 43.942,107.049 0,64.878"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,169.314,111.589)">
              <path
                d="M0,-64.879C-42.17,-20.937 -33.178,42.17 -33.178,42.17L5.284,37.543L19.428,10.502C-6.235,-21.003 16.146,-48.766 16.146,-48.766C16.146,-48.766 43.778,-71.016 75.315,-45.386L102.291,-59.464L106.984,-97.992C106.984,-97.992 43.942,-107.049 0,-64.879"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,330.708,182.562)">
              <path
                d="M0,64.878C42.138,20.936 33.146,-42.171 33.146,-42.171L-5.316,-37.544L-19.428,-10.503C6.202,21.002 -16.146,48.765 -16.146,48.765C-16.146,48.765 -43.811,71.015 -75.348,45.385L-102.323,59.463L-107.017,97.99C-107.017,97.99 -43.942,107.049 0,64.878"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,330.708,111.589)">
              <path
                d="M0,-64.879C42.138,-20.937 33.146,42.17 33.146,42.17L-5.316,37.543L-19.428,10.502C6.202,-21.003 -16.146,-48.766 -16.146,-48.766C-16.146,-48.766 -43.811,-71.016 -75.348,-45.386L-102.323,-59.464L-107.017,-97.992C-107.017,-97.992 -43.942,-107.049 0,-64.879"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.133982,0,0,0.133982,-487.415,7.50055)">
              <path
                d="M5628.79,1166.65C5676.5,1184.78 5730.18,1171.78 5804.57,1097.23C5822.34,1079.44 5838.59,1060.81 5853.12,1041.83C5959.48,1180.5 5979.82,1344.81 5893.3,1431.34C5806.96,1517.85 5642.46,1497.68 5503.97,1391.15C5509.83,1386.67 5509.82,1386.67 5503.97,1391.15C5484.82,1376.79 5466.18,1360.37 5448.4,1342.59C5373.85,1268.04 5360.85,1214.35 5379.15,1166.64C5398.85,1159.14 5398.85,1159.14 5379.15,1166.64C5395.39,1123.73 5437.11,1085.6 5481.57,1042C5485.33,1045.59 5488.92,1049.18 5492.68,1052.94L5503.97,1064.23L5503.97,1064.23C5547.74,1108.68 5585.87,1150.4 5628.79,1166.64L5628.79,1166.65Z"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.133982,0,0,0.133982,-487.415,7.50055)">
              <path
                d="M5378.98,917.004C5331.27,898.88 5277.58,911.874 5203.03,986.428C5185.25,1004.21 5169.01,1022.85 5154.47,1041.82C5048.12,903.157 5027.77,738.841 5114.29,652.319C5200.81,565.803 5365.3,586.149 5503.97,692.501C5484.82,706.865 5484.82,706.867 5503.97,692.502C5522.95,707.034 5541.58,723.279 5559.37,741.062C5633.92,815.611 5646.74,869.302 5628.79,917.008C5585.87,933.248 5547.74,974.972 5503.97,1019.6C5460.19,975.144 5422.07,933.248 5378.98,917.008L5378.98,917.004Z"
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.133982,0,0,0.133982,-487.415,7.50055)">
              <path
                d="M5628.79,917.006C5646.74,869.301 5633.92,815.61 5559.36,741.061C5541.58,723.278 5522.95,707.033 5503.97,692.501C5642.46,585.977 5806.96,565.803 5893.3,652.32C5979.82,738.836 5959.48,903.158 5853.12,1041.82C5838.59,1022.85 5838.59,1022.85 5853.12,1041.82C5838.59,1060.81 5822.34,1079.45 5804.57,1097.23C5730.19,1171.78 5676.5,1184.77 5628.79,1166.65C5622.02,1148.94 5622.02,1148.94 5628.79,1166.65C5585.87,1150.4 5547.74,1108.68 5503.97,1064.22L5492.68,1052.94C5488.92,1049.18 5485.33,1045.59 5481.57,1042L5481.57,1042C5467.06,1027.65 5467.06,1027.65 5481.57,1042C5437.11,1085.6 5395.39,1123.73 5379.15,1166.64C5331.44,1184.77 5331.44,1184.77 5379.15,1166.65C5360.85,1214.35 5373.85,1268.04 5448.4,1342.59C5466.18,1360.37 5484.82,1376.79 5503.97,1391.15C5365.3,1497.5 5200.81,1517.85 5114.29,1431.33C5027.77,1344.82 5048.12,1180.5 5154.47,1041.82L5154.47,1041.83C5169.01,1022.85 5185.25,1004.21 5203.03,986.427C5277.58,911.878 5331.27,898.879 5378.98,917.005C5379.23,917.677 5379.5,918.348 5379.77,919.022C5379.5,918.351 5379.24,917.68 5378.98,917.008C5422.06,933.248 5460.2,975.144 5503.97,1019.6C5500.21,1023.19 5500.21,1023.19 5503.97,1019.6C5504.27,1019.91 5504.57,1020.22 5504.86,1020.53C5504.57,1020.22 5504.27,1019.91 5503.97,1019.6C5547.74,974.969 5585.87,933.251 5628.79,917.006ZM5628.79,917.006C5641.09,912.333 5641.09,912.334 5628.79,917.007C5625.94,924.448 5625.94,924.447 5628.79,917.006Z"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CTHorBlack;
