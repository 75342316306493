import { RootState } from "../store";
import { createSelector } from "reselect";

// Selector to get multiple parts of FashState as an object
export const __REDUX_STATE_KEY__ = "transferToCrew";
export const getReduxStateSlice = (state: RootState) =>
  state[__REDUX_STATE_KEY__];
export const getFasi = (state: RootState) => {
  return getReduxStateSlice(state).fasis;
};
export const getLocation = (state: RootState) => {
  return getReduxStateSlice(state).location;
};

export const getContructorCrews = (state: RootState) => {
  return getReduxStateSlice(state).constructor_crew;
};

export const getTypos = (state: RootState) => {
  return getReduxStateSlice(state).typos;
};

export const getCompanies = (state: RootState) => {
  return getReduxStateSlice(state).companies;
};

export const getTransferedProducts = (state: RootState) => {
  return getReduxStateSlice(state).products;
};

export const getFormData = (state: RootState) => {
  return getReduxStateSlice(state).formValues;
};

export const getTransferedProductsStatus = (state: RootState) =>
  getReduxStateSlice(state).productsStatus;

export const getStatus = (state: RootState) => getReduxStateSlice(state).status;
export const getError = (state: RootState) => getReduxStateSlice(state).error;

export const selectTransferProductsToCrew = createSelector(
  getFasi,
  getLocation,
  getTypos,
  getCompanies,
  getContructorCrews,
  getStatus,
  getError,
  (fasis, location, typos, companies, constructor_crew) => {
    return {
      fasis: fasis,
      location: location,
      typos: typos,
      companies: companies,
      constructor_crew: constructor_crew,
    };
  }
);
