import { TextWorkSans } from "../../components/common.style";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";

export const LoginFormContainer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  margin: 40px;
  background-color: white;
  border-radius: 5px;
  gap: 40px;
  border: 1px solid #cccccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

export const Divider = styled.div`
  border: 3px solid #1976d2;
  border-radius: 20px;
  width: 30px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
