import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Close = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5778 4.06731C17.9371 3.70803 18.5196 3.70803 18.8789 4.06731C19.2382 4.4266 19.2382 5.00911 18.8789 5.36839L13.0111 11.2362L18.8792 17.1042C19.2385 17.4635 19.2385 18.046 18.8792 18.4053C18.5199 18.7646 17.9374 18.7646 17.5781 18.4053L11.7101 12.5372L5.84202 18.4053C5.48274 18.7646 4.90023 18.7646 4.54095 18.4053C4.18166 18.046 4.18166 17.4635 4.54095 17.1042L10.409 11.2362L4.54122 5.36839C4.18193 5.00911 4.18193 4.4266 4.54122 4.06731C4.9005 3.70803 5.48301 3.70803 5.84229 4.06731L11.7101 9.93508L17.5778 4.06731Z"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default Close;
