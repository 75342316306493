import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import { TextWorkSans } from "../../components/common.style";
import { shouldForwardProp } from "@mui/system";
import ExpandableTableRow from "./Table/TableRow";
import { ColorsType } from "../../theme/types";
import SelectComponent from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FormValues } from "../../slices/receiptTransferSlice/receiptTransferSlice";
import { selectBuildingsDetails } from "../../slices/dashboardSlice/selectors";
import {
  getBuildings,
  setBuildingsPage,
} from "../../slices/dashboardSlice/dashboardSlice";
import { AppDispatch } from "../../slices/store";
import theme from "../../theme/theme";
import {
  getBuildingsAsCrew,
  setBuildingsOffset,
} from "../../slices/crewDashboardSlice/crewDashboardSlice";
import {
  getBuildingsPage,
  selectCrewDashboardDetails,
} from "../../slices/crewDashboardSlice/selectors";
const tablePaginationStyles = {
  "& .Mui-disabled": {
    color: "grey !important",
  },
  "& .MuiIconButton-colorInherit": {
    color: `${theme.colors.primary}`,
  },
  color: `${theme.colors.primary}`,
  padding: "0px 0px 0px 0px",
};

export interface CustomTableCellProps extends TableCellProps {
  disablePadding?: boolean;
  width?: number | string;
  maxWidth?: number | string;
  backgroundColor?: ColorsType;
  whiteSpace?: boolean;
}
export const CustomTableCell = styled(TableCell, {
  shouldForwardProp: (prop: any) =>
    shouldForwardProp(prop) && prop !== "disablePadding",
})<CustomTableCellProps>(
  ({ disablePadding, width, maxWidth, whiteSpace, backgroundColor }: any) => {
    const processedWidth = typeof width === "number" ? `${width}px` : width;
    const processedMaxWidth =
      typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;
    return {
      "&.MuiTableCell-head": {
        color: "#7879F1",
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
      "&.MuiTableCell-body": {
        fontSize: 14,
        width: processedWidth || "auto",
        maxWidth: processedMaxWidth || undefined,
        borderBottom: "1px solid #2B2E43",
        padding: disablePadding ? "0px" : undefined,
        whiteSpace: whiteSpace ? "nowrap" : "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: backgroundColor || "transparent",
      },
    };
  }
);

export const HeaderRow = styled(TableRow)({
  color: "#7879F1",
  "&.MuiTableRow-head": {
    backgroundColor: "transparent",
    color: "#7879F1",
  },
});

interface Props {
  watch: UseFormWatch<any>;
  control: Control<any, any>;
  fields: FieldArrayWithId<any, "products", "id">[];
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<any, "products">;
  setValue: UseFormSetValue<any>;
  previewTable?: boolean;
  getValues: UseFormGetValues<any>;
}

const TableComponent: React.FC<Props> = ({
  control,
  previewTable,
  getValues,
  fields,
  watch,
}) => {
  useEffect(() => {
    watch("products");
  }, []);
  const [loader, setLoader] = useState<boolean>(false);
  const { cities, statuses, construction_crews, offset } = useSelector(
    selectBuildingsDetails
  );
  const page = useSelector(getBuildingsPage);
  const { buildings, totalBuildings } = useSelector(selectCrewDashboardDetails);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      dispatch(setBuildingsOffset(offset + 5));
    }
    if (newPage < page) {
      dispatch(setBuildingsOffset(offset - 5));
    }
    dispatch(setBuildingsPage(newPage));
  };
  const limit = 5;
  const rowsPerPage = 5;

  const tableHeaders = [
    { id: "id", label: "Id", align: "left", fontSize: 12, color: "primary" },
    {
      id: "City",
      label: "Πόλη",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "Address",
      label: "Διεύθυνση",
      align: "left",
      fontSize: 12,
      color: "primary",
    },

    {
      id: "Overall Status",
      label: "Κατάσταση",
      align: "left",
      fontSize: 12,
      color: "primary",
    },
    {
      id: "Dropdown",
      label: "",
      align: "",
      fontSize: 12,
      color: "primary",
    },
  ];

  const [city, setCity] = useState<{
    id: string;
    title: string;
    shortName?: string;
    icon?: JSX.Element;
    data?: any;
  } | null>();
  const [status, setStatus] = useState<
    | {
        id: string;
        title: string;
        shortName?: string;
        icon?: JSX.Element;
        data?: any;
      }
    | undefined
  >();
  const [crew, setCrew] = useState<
    | {
        id: string;
        title: string;
        shortName?: string;
        icon?: JSX.Element;
        data?: any;
      }
    | undefined
  >();
  const dispatch = useDispatch<AppDispatch>();

  const [searchTerm, setSearchTerm] = useState<string>("");

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ marginTop: "25px", marginBottom: "25px" }}>
        <TextWorkSans fontSize={22} color={"secondary"}>
          Πολυκατοικίες
        </TextWorkSans>
      </div>
      {!previewTable && (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <SelectComponent
            maxWidth={"180px"}
            label="Διάλεξε Πόλη"
            placeholder="= Επέλεξε -"
            items={
              cities as {
                title: string;
                id?: string;
              }[]
            }
            value={city}
            noneOption={true}
            onChange={(value: any) => {
              setCity(value);
            }}
          />
          {/* <SelectComponent
            maxWidth={"220px"}
            label="Διάλεξε Κατάσταση"
            placeholder="= Επέλεξε -"
            items={
              statuses as {
                title: string;
                id?: string;
              }[]
            }
            value={status}
            noneOption={true}
            onChange={(value: any) => {
              setStatus(value);
            }}
          /> */}

          {/* <TextField
              maxwidth={280}
              maxLength={10}
              type="text"
              label="Αναζήτηση"
              placeholder="Αναζήτηση"
              value={searchTerm}
              onChange={(value) => {
                setSearchTerm(value);
              }}
            /> */}
          <div style={{ marginTop: "auto" }}>
            <Button
              padding={0}
              size="medium"
              backgroundColor="primary"
              color="white"
              type="button"
              fontSize={12}
              onClick={() => {
                setSearchTerm("");
                setCrew(undefined);
                setCity(undefined);
                setStatus(undefined);
              }}
            >
              ΚΑΘΑΡΙΣΜΑ ΦΙΛΤΡΩΝ
            </Button>
          </div>
        </div>
      )}
      <Table style={{ width: "100%" }}>
        <TableHead>
          <HeaderRow>
            {tableHeaders.map((header) => (
              <CustomTableCell key={header.id} width={"auto"}>
                <TextWorkSans color="primary" fontSize={header.fontSize}>
                  {header.label}
                </TextWorkSans>
              </CustomTableCell>
            ))}
          </HeaderRow>
        </TableHead>
        {loader ? (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={10}
                style={{ verticalAlign: "middle", height: "200px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress color={"primary"} size={50} />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {buildings?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ verticalAlign: "middle", height: "200px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <TextWorkSans>NO MORE DATA</TextWorkSans>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              buildings?.map((building, index) => (
                <ExpandableTableRow
                  key={building.id}
                  building={building}
                  index={index}
                />
              ))
            )}
          </TableBody>
        )}
      </Table>
      <TablePagination
        sx={tablePaginationStyles}
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={totalBuildings}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </div>
  );
};

export default TableComponent;
