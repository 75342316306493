import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";

import { H4, TextWorkSans } from "../common.style";
import { ColorsType } from "../../theme/types";

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 110;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContainer = styled.div<{
  width?: string;
  disableTextAlign?: boolean;
}>`
  width: ${(props) => (props.width ? props.width : "333px")};
  background-color: ${(props) => props.theme.colors.white};
  border: solid 2px black;
  border-radius: 30px;
  position: absolute;
  padding: 0px 31.5px;
  text-align: ${(props) =>
    props.disableTextAlign === true ? "none" : "center"};
`;

export const SelfieModalContainer = styled.div`
  width: auto;
  padding: 30px 0px;
  position: absolute;
  background: #1e1e32;
  /* BG/Obsidian Shade - 90 */
  border-radius: 5px;

  @media (min-width: 768px) {
    min-width: 550px;
  }
`;

export const ModalMessageContainer = styled(TextWorkSans)`
  display: flex;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  border-radius: 30px;
  position: absolute;
  padding: 0px 31.5px;
`;

export const Title = styled(H4)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 48px;
`;

type ButtonProps = {
  backgroundColor?: ColorsType;
  colour?: ColorsType;
};

const buttonStyles = css`
  text-transform: none;
  height: 48px;
  border-radius: 10px;
  width: auto;
`;
export const PrimaryButton = styled(Button)<ButtonProps>`
  && {
    font-size: 14px;
    ${buttonStyles};
    ${(props) =>
      props.backgroundColor &&
      `background-color: ${props.theme.colors[props.backgroundColor]}; 
      &:hover {
        background-color: ${props.theme.colors[props.backgroundColor]};
        opacity: 0.8;
      }`}
    ${(props) => props.colour && `color: ${props.theme.colors[props.colour]};`}
  }
`;

export const SecondaryButton = styled(Button)<ButtonProps>`
  && {
    ${buttonStyles};
    margin-right: 16px;
    border-width: 3px;
    &:hover {
      border-width: 3px;
    }
  }
`;

export const FormValidationWrapper = styled.div`
  margin-bottom: 30px;
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;
