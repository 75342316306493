import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const DownArrow = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.093 0.625635C10.7285 0.254215 10.131 0.251418 9.76302 0.619407L6.02042 4.362L2.27658 0.618166C1.90764 0.249235 1.31001 0.247541 0.938996 0.614374V0.614374C0.561808 0.987308 0.563755 1.59708 0.943318 1.9676L6.02042 6.92371L11.0812 1.94889C11.4496 1.58667 11.4549 0.994399 11.093 0.625635V0.625635Z"
        fill={colors[color || "candyRed"]}
      />
    </svg>
  );
};

export default DownArrow;
