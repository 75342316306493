import React, { useEffect, useState } from "react";
import {
  TextInput,
  TextFieldLabel,
  TextInputWrapper,
  TextFieldWrapper,
  DeleteIconWrapper,
} from "./TextField.style";
import { IconType } from "../Icon/Icon.interface";
import { H4, TextWorkSans } from "../common.style";
import Icon from "../Icon/Icon";
import { ColorsType } from "../../theme/types";
import { InputAdornment, styled } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDatePicker,
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import theme from "../../theme/theme";

export type FunctionType = (() => void) | ((e: string) => void);

const CustomDatePicker = styled(DesktopDatePicker)({
  "& .MuiOutlinedInput-notchedOutline": {
    border: `3px solid ${theme.colors.metallicDark} !important`,
    borderRadius: "12px",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderRadius: "12px",
    border: `3px solid ${theme.colors.metallicDark} !important`,
  },
  "& .css-hx28vi-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-input": {
    borderRadius: "12px",
    padding: "13px",
  },
  "& .MuiOutlinedInput-input::placeholder": {
    opacity: 1,
  },
});

type Props = {
  id?: string;
  label?: string;
  placeholder?: string;
  iconLeft?: IconType;
  maxwidth?: number;
  disabled?: boolean;
  iconRight?: IconType;
  iconColor?: ColorsType;
  deleteIcon?: boolean;
  required?: boolean;
  maxLength?: number; // the max length of the input
  multiline?: boolean;
  border?: string;
  borderBottom?: string;
  fontSize?: string;
  borderRadius?: string;
  errorMessage?: string;
  rows?: number; // number of rows to display when multiline option is set to true.
  maxRows?: number;
  value?: string;
  formToday?: boolean;
  arrayValue?: any[];
  minWidth?: number;
  variant?: string;
  padding?: string;
  acceptInteger?: boolean;
  onChange?: FunctionType;
  onDateChange?: (value?: any) => void;
  onTimeChange?: (value?: any) => void;
  dataTest?: string;
  style?: React.CSSProperties;
  type?: string;
  direction?: string;
  onBlur?: () => void;
  onDelete?: () => void;
  defaultValue?: any;
  readOnly?: boolean;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
};
const TextField: React.FC<Props> = ({
  id,
  label,
  placeholder,
  maxwidth,
  disabled = false,
  iconLeft,
  iconRight,
  iconColor = "secondary",
  deleteIcon,
  required,
  borderRadius,
  maxLength,
  formToday,
  acceptInteger = false,
  multiline = false,
  rows = 3,
  maxRows,
  style,
  border,
  padding,
  minWidth,
  borderBottom,
  type,
  direction,
  fontSize,
  onBlur,
  arrayValue,
  dataTest,
  onDateChange = () => {
    return;
  },
  onTimeChange = () => {
    return;
  },

  onDelete,
  defaultValue,
  errorMessage = null,
  textAlign = "start",
  ...rest
}) => {
  const [textLength, setTextLength] = useState(0);
  const [arrayValueState, setArrayValue] = useState("");

  useEffect(() => {
    if (arrayValue != undefined && arrayValue?.length > 0) {
      arrayValue.forEach((item) => {
        setArrayValue(arrayValue + item.title);
      });
    }
  }, [arrayValue]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => {
    if (type === "time") {
      if (event) onTimeChange(event.format("HH:mm"));
      if (rest.onChange) {
        rest?.onChange?.(event.format("HH:mm"));
      }
    } else if (type === "date") {
      onDateChange(event.format("YYYY-MM-DD"));
      if (rest.onChange) {
        rest?.onChange?.(event.format("YYYY-MM-DD"));
      }
    } else {
      if (type === "number" && acceptInteger) {
        const value = event.target.value;
        const intValue = parseInt(value, 1000);
        if (!isNaN(intValue) && intValue.toString() !== value) {
          event.target.value = intValue.toString();
        }
      }

      setTextLength(event.target.value.length);
      if (rest.onChange) {
        rest?.onChange?.(event.target.value);
      }
    }
  };

  const handleDeletion = () => {
    if (onDelete) onDelete();
  };

  const resolveEndAdornment = () => {
    if (iconRight) {
      return (
        <InputAdornment position="end">
          <Icon name={iconRight} width={22} color={iconColor} />
        </InputAdornment>
      );
    }
    if (maxLength) {
      return (
        <InputAdornment position="end">
          <TextWorkSans>{`${textLength} / ${maxLength}`}</TextWorkSans>
        </InputAdornment>
      );
    }
    return undefined;
  };

  const handleKeyDown = (event: any) => {
    if (type === "number" && acceptInteger) {
      // Prevent '-' and '+' from being typed
      if (event.key === "-" || event.key === "+") {
        event.preventDefault();
      }
    }
  };

  return (
    <TextFieldWrapper style={style} label={label}>
      {label && (
        <TextFieldLabel>
          <H4 color="secondary">{label}</H4>
          {required && <H4 color="candyRed"> *</H4>}
        </TextFieldLabel>
      )}

      <TextInputWrapper direction={direction}>
        {type === "date" && (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            dateFormats={{ normalDate: "DD-MM-YYYY" }}
          >
            <div data-test={dataTest}>
              <CustomDatePicker
                sx={{ maxWidth: 200 }} // This sets the width to 230
                value={dayjs(rest.value, "YYYY-MM-DD")}
                onChange={handleChange}
                format="DD-MM-YYYY"
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        )}
        {type != "date" && type != "time" && (
          <TextInput
            id={id}
            sx={{
              "& .MuiInput-input": {
                whiteSpace: "pre-wrap",
              },
            }}
            fontSize={fontSize}
            defaultValue={defaultValue}
            onBlur={onBlur}
            type={type}
            borderRadius={borderRadius}
            value={rest.value}
            borderBottom={borderBottom}
            maxwidth={maxwidth}
            minWidth={minWidth}
            required={required}
            disabled={disabled}
            placeholder={placeholder as string}
            multiline={multiline}
            maxRows={maxRows}
            rows={rows}
            border={border}
            heightFlag={false}
            padding={padding}
            onKeyDown={handleKeyDown}
            startAdornment={
              iconLeft && (
                <InputAdornment position="start">
                  <Icon width={22} name={iconLeft} color={iconColor} />
                </InputAdornment>
              )
            }
            inputProps={{
              maxLength: maxLength,
              step: type === "time" ? 900 : 0,
              style: { textAlign: textAlign },
            }}
            endAdornment={resolveEndAdornment()}
            {...rest}
            onChange={handleChange}
          />
        )}

        {deleteIcon && (
          <DeleteIconWrapper onClick={handleDeletion}>
            <Icon color={"candyRed"} name="delete" />
          </DeleteIconWrapper>
        )}
        <div
          style={{
            wordWrap: "break-word",
            maxWidth: maxwidth ? `${maxwidth}px` : "170px",
          }}
        >
          <TextWorkSans fontWeight={400} color="candyRed">
            {errorMessage && <p>{errorMessage} </p>}
          </TextWorkSans>
        </div>
      </TextInputWrapper>
    </TextFieldWrapper>
  );
};

export default React.memo(TextField);
