import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const CancelCircle = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();
  // fill={colors[color || 'onyxDark']}
  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0675 21.1532C17.1227 21.1532 21.2207 17.0552 21.2207 12C21.2207 6.94476 17.1227 2.84671 12.0675 2.84671C7.01227 2.84671 2.91422 6.94476 2.91422 12C2.91422 17.0552 7.01227 21.1532 12.0675 21.1532ZM12.0675 22.9932C18.1389 22.9932 23.0607 18.0714 23.0607 12C23.0607 5.92856 18.1389 1.00671 12.0675 1.00671C5.99606 1.00671 1.07422 5.92856 1.07422 12C1.07422 18.0714 5.99606 22.9932 12.0675 22.9932ZM16.3891 7.67788C16.7016 7.99029 16.7016 8.49682 16.3892 8.80925L13.1986 12L16.389 15.1906C16.7014 15.5031 16.7014 16.0096 16.389 16.322C16.0766 16.6344 15.57 16.6344 15.2576 16.322L12.0672 13.1314L8.87678 16.322C8.56437 16.6344 8.05783 16.6344 7.74541 16.322C7.43298 16.0096 7.43297 15.5031 7.74538 15.1906L10.9359 12L7.74525 8.80925C7.43284 8.49682 7.43285 7.99029 7.74528 7.67788C8.0577 7.36546 8.56424 7.36548 8.87665 7.6779L12.0672 10.8686L15.2578 7.6779C15.5702 7.36548 16.0767 7.36546 16.3891 7.67788Z"
        fill={colors[color || "spaceShade"]}
      />
    </svg>
  );
};

export default CancelCircle;
