// theme.ts
import { Breakpoint, createTheme } from "@mui/material/styles";
import { baseColors } from "./colors";
import { BREAK_POINTS } from "./types";

// Add this interface to your theme.ts file
interface MyCustomColors {
  primary: string;
  secondary: string;
  hoverColor: string;
  white: string;
  lightPurple: string;
  black: string;
  spaceShade: string;
  orangeLogo: string;
  yellowLogo: string;
  blue: string;
  blueGradient: string;
  greenLight: string;
  redLight: string;
  grey: string;
  orange: string;
  lightGrey: string;
  purple: string;
  vividPink: string;
  irisDark: string;
  irisLight: string;
  irisPure: string;
  metallicDark: string;
  metallicPearlWhite: string;
  obsidian: string;
  candyOrange: string;
  candyYellow: string;
  candyRed: string;
  metallicLight: string;
  metallicLight2: string;
  horizonalLine: string;
  facebookBlue: string;
  googleRed: string;
  onyxDark: string;
  carinalTeal: string;
  spacePure: string;
  dark: string;
  bgCard: string;
  lineChartorange: string;
  lineChartDarkyellow: string;
  lineChartlavender: string;
  lineChartdarkRed: string;
  lineChartyellow: string;
  lineChartred: string;
  lineChartlightBrown: string;
  lineChartdarkGreen: string;
  lineChartlighGreen: string;
  lineChartmediumGreen: string;
  lineChartGreen: string;
  lineChartlighGreen2: string;
  lineChartpink: string;
  lineChartlightBlue: string;
  lineChartdarkBlue: string;
  lineChartblue: string;
  lineChartlightPurple: string;
  lineChartpurple: string;
  lineChartdarkPink: string;
  lineChartlightPink: string;
  dividerDark: string;
  dividerLight: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    colors: MyCustomColors & {
      primary: string;
      secondary: string;
      hoverColor: string;
    };
  }

  interface ThemeOptions {
    colors?: MyCustomColors;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1E3A5F",
    },
    secondary: {
      main: "#dc004e",
    },
  },
  // Custom colors
  colors: {
    ...baseColors,
    primary: "#154c79",
    secondary: "#1E3A5F",
    hoverColor: "#14233C",
  },
  // Additional customization
});

export default theme;
