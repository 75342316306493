import React from "react";

import { StyledButton, IconWrapper } from "./Button.style";
import { IconType } from "../Icon/Icon.interface";
import CircularProgress from "@mui/material/CircularProgress";
import { ColorsType } from "../../theme/types";
import Icon from "../Icon/Icon";

export type ModesType = "contained" | "outlined";
export type SizesType =
  | "none"
  | "auto"
  | "mini"
  | "small"
  | "medium"
  | "large"
  | "stretch"
  | "stretch-small"
  | "semi-large";
export type FunctionType = (() => void) | ((e: any) => void);

interface Props {
  mode?: ModesType;
  size?: SizesType;
  fontSize?: number;
  loader?: boolean;
  backgroundColor?: ColorsType;
  color?: ColorsType;
  icon?: IconType;
  iconColor?: ColorsType;
  iconStroke?: ColorsType;
  iconMargin?: string;
  gap?: number;
  iconWidth?: number;
  iconHeight?: number;
  // native props, passed to component with ...rest
  disabled?: boolean;
  onPress?: FunctionType;
  onClick?: FunctionType;
  padding?: number;
  type?: "button" | "submit" | "reset";
  isMobileEnabled?: boolean;
  justifyContent?: string;
  border?: string;
  borderRadius?: number;
  disableRipple?: boolean;
  children?: any;
}

const Button: React.FC<Props> = ({
  mode = "contained",
  size = "medium",
  color = "metallicDark",
  iconMargin = "0px",
  gap = 0,
  iconColor,
  iconStroke,
  iconWidth,
  iconHeight,
  icon,
  backgroundColor = "candyYellow",
  children,
  loader,
  fontSize = 16,
  onPress: handlePress,
  onClick,
  padding,
  disabled = false,
  isMobileEnabled = false,
  disableRipple = false,
  ...rest
}) => {
  return (
    <StyledButton
      disableRipple={disableRipple}
      type="button"
      isMobileEnabled={isMobileEnabled}
      gap={gap}
      mode={mode}
      size={size}
      color={color}
      backgroundColor={backgroundColor}
      onClick={loader ? undefined : onClick ? onClick : handlePress}
      fontSize={fontSize}
      disabled={disabled}
      padding={padding}
      {...rest}
    >
      {icon && (
        <IconWrapper style={{ marginRight: iconMargin }}>
          <Icon
            name={icon}
            color={iconColor}
            width={iconWidth}
            height={iconHeight}
            stroke={iconStroke}
          />
        </IconWrapper>
      )}
      {loader ? (
        <div style={{ display: "flex" }}>
          <CircularProgress color={"inherit"} size={20} />
        </div>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
