import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const GoogleMaps = ({ color, width, height, stroke, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.86882 4.75495L18.3741 1.61441L9.92368 16.066L9.6902 7.14198L1.86882 4.75495Z"
        stroke={colors[stroke || "candyRed"]}
        strokeWidth="1.84"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={colors[color || "candyRed"]}
      />
    </svg>
  );
};

export default GoogleMaps;
