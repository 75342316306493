import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { GenericModalProperties } from "../modalSlice/types";
import { closeGenericModal, openGenericModal } from "../modalSlice/modalSlice";
import {
  CustomJwtPayload,
  Dashboard,
  DashboardDetailsAPI,
  GetBuldingAPIResponse,
  LoginParams,
} from "./types";
import dashboardAPI from "../../providers/dashboardAPI";
import fasisAPI from "../../providers/fasisAPI";
import { ReceiptIformationReponse } from "../receiptSlice/receiptSlice";
import { getReceiptTranserProductsDetails } from "../transferProductsToCrewSlice/transferProductsToCrewSlice";
import usersAPI from "../../providers/usersAPI";
import { setAxiosToken } from "../axiosInstances";
import { jwtDecode } from "jwt-decode";

export const initialState: Dashboard = {
  buildings: [],
  totalBuildings: 0,
  buildingsPage: 0,
  dashboardDetails: {
    statuses: undefined,
    cities: undefined,
    construction_crew: undefined,
  },
  addBuildingDetails: {
    suppliers: undefined,
    providers: undefined,
    cities: undefined,
  },
  expandedRow: -1,
  addBuildingStatus: "loading",
  addBuildingError: null,
  getDashboardDetailsStatus: "loading",
  getDashboardDetailsError: null,
  getBuildingsStatus: "loading",
  getBuildingsError: null,
  updateBuildingStatus: "loading",
  updateBuildingError: null,
  getBuildingDetailsStatus: "loading",
  getBuildingDetailsError: null,
  getBuildingsOffset: 0,
};

export const addBuilding = createAsyncThunk(
  "programmingSlice/addBuilding",
  async (params: { data: any; navigate: any }, thunkAPI) => {
    const { request } = dashboardAPI.single.addBuilding(params.data);
    return request()
      .then((response: any) => {
        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η προσθήκη Πολυκατοικίας έγινε με επιτυχία",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            params.navigate("/dashboard");
            // You can now use the `someOtherProp` here
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const uploadBuildingExcel = createAsyncThunk(
  "programmingSlice/uploadBuildingExcel",
  async (params: { file: File; navigate?: any }, thunkAPI) => {
    const { request } = dashboardAPI.single.uploadBuildingExcel(params.file);
    return request()
      .then((response: any) => {
        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Το αρχείο Excel ανεβάστηκε με επιτυχία",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
            params.navigate("/dashboard");
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message:
            error.message || "Κάτι πήγε στραβά κατά την αποστολή του αρχείου.",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (params: LoginParams, thunkAPI) => {
    try {
      const response = await usersAPI.single.loginUser(params).request();
      if (response.jwt) {
        localStorage.setItem("jwt", response.jwt);
        setAxiosToken(response.jwt);
        // Check if the user is admin or user
        const decodedToken = jwtDecode<CustomJwtPayload>(response.jwt);
        if (decodedToken.role === "user") {
          params.navigate("/myBuildings");
        } else {
          params.navigate("/");
        }
      }
      return response;
    } catch (error: any) {
      console.error("Login failed:", error);
      const modalParams = {
        title: "ERROR",
        color: "candyRed",
        message: error.message || "Κάτι πήγε στραβά κατά τη σύνδεση.",
        enableLoader: false,
        disableBackdropClick: true,
        enableProgressBar: false,
        primaryLabel: "ΚΛΕΙΣΙΜΟ",
        primaryOnPress: () => {
          thunkAPI.dispatch(closeGenericModal());
        },
      };
      thunkAPI.dispatch(openGenericModal(modalParams));
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const updateBuildingStatus = createAsyncThunk(
  "programmingSlice/updateBuildingStatus",
  async (
    params: {
      statusData: any;
      navigate: any;
      offset: number;
      limit: number;
      page: number;
    },
    thunkAPI
  ) => {
    const { request } = dashboardAPI.single.updateBuildingStatus(
      params.statusData
    );

    return request()
      .then((response: any) => {
        const modalParams: GenericModalProperties = {
          title: "🎉Συγχαρητήρια",
          message: "Η Αλλαγή της Πολυκατοικίας έγινε με επιτυχία!",
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: async () => {
            thunkAPI.dispatch(closeGenericModal());
            thunkAPI.dispatch(getDashboardDetails());
            thunkAPI.dispatch(getReceiptTranserProductsDetails());
            const newParams = {
              offset: params.offset,
              limit: params.limit,
            };
            await thunkAPI.dispatch(getBuildings(newParams));
            // You can now use the `someOtherProp` here
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return response;
      })
      .catch((error) => {
        const modalParams: GenericModalProperties = {
          title: "ERROR",
          color: "candyRed",
          message: error.error,
          enableLoader: false,
          disableBackdropClick: true,
          enableProgressBar: false,
          primaryLabel: "ΚΛΕΙΣΙΜΟ",
          primaryOnPress: () => {
            thunkAPI.dispatch(closeGenericModal());
          },
        };
        thunkAPI.dispatch(openGenericModal(modalParams));
        return thunkAPI.rejectWithValue(error.error);
      });
  }
);

export const getBuildings = createAsyncThunk(
  "programmingSlice/getBuildings",
  async (params: undefined | any, thunkAPI) => {
    const { request } = dashboardAPI.single.getBuildings(params);
    return request()
      .then((response: GetBuldingAPIResponse) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

export const getBuildingDetails = createAsyncThunk(
  "programmingSlice/getBuildingDetails",
  async (_, thunkAPI) => {
    const { request } = fasisAPI.single.getReceiptInformation();
    return request()
      .then((response: ReceiptIformationReponse) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);
export const getDashboardDetails = createAsyncThunk(
  "programmingSlice/getDashboardDetails",
  async (params, thunkAPI) => {
    const { request } = dashboardAPI.single.getDashboardDetails(params);
    return request()
      .then((response: DashboardDetailsAPI) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.toString());
      });
  }
);

const programmingSlice = createSlice({
  name: "programmingSlice",
  initialState: initialState,
  reducers: {
    setExpandedRow(state, action) {
      state.expandedRow = action.payload;
    },

    setBuildingsOffset(state, action) {
      state.getBuildingsOffset = action.payload;
    },

    setBuildingsPage(state, action) {
      state.buildingsPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      addBuilding.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addBuildingStatus = "succeeded";
      }
    );
    builder.addCase(addBuilding.rejected, (state, action) => {
      state.addBuildingStatus = "failed";
      state.addBuildingError = action.error;
    });

    builder.addCase(
      updateBuildingStatus.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateBuildingStatus = "succeeded";
      }
    );
    builder.addCase(updateBuildingStatus.rejected, (state, action) => {
      state.updateBuildingStatus = "failed";
      state.updateBuildingError = action.error;
    });

    builder.addCase(
      getBuildings.fulfilled,
      (state, action: PayloadAction<GetBuldingAPIResponse>) => {
        state.buildings = initialState.buildings;
        state.buildings = action.payload.data;
        state.totalBuildings = action.payload.totalBuildings;

        state.updateBuildingStatus = "succeeded";
      }
    );
    builder.addCase(getBuildings.rejected, (state, action) => {
      state.updateBuildingStatus = "failed";
      state.updateBuildingError = action.error;
    });

    builder.addCase(
      getDashboardDetails.fulfilled,
      (state, action: PayloadAction<DashboardDetailsAPI>) => {
        state.dashboardDetails = action.payload;
        state.getDashboardDetailsStatus = "succeeded";
      }
    );
    builder.addCase(getDashboardDetails.rejected, (state, action) => {
      state.getDashboardDetailsStatus = "failed";
      state.getDashboardDetailsError = action.error;
    });

    builder.addCase(
      getBuildingDetails.fulfilled,
      (state, action: PayloadAction<ReceiptIformationReponse>) => {
        state.addBuildingDetails.cities = action.payload.cities;
        state.addBuildingDetails.providers = action.payload.brands;
        state.addBuildingDetails.suppliers = action.payload.companies;
        state.getBuildingDetailsStatus = "succeeded";
      }
    );
    builder.addCase(getBuildingDetails.rejected, (state, action) => {
      state.getBuildingDetailsStatus = "failed";
      state.getBuildingDetailsError = action.error;
    });
  },
});

export const { setExpandedRow, setBuildingsOffset, setBuildingsPage } =
  programmingSlice.actions;

const { reducer } = programmingSlice;
export default reducer;
