import React, { useEffect, useState } from "react";
import {
  DisableComponent,
  TextWorkSans,
} from "../../../components/common.style";
import { CustomTableCell } from "../Table";
import { Icon, TextField } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  Fade,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TableRow,
  styled,
} from "@mui/material";
import { getDesktopNavbarVisibility } from "../../../slices/desktopMode/selectors";
import {
  setExpandedRow,
  updateBuildingStatus,
} from "../../../slices/dashboardSlice/dashboardSlice";
import {
  getBuildings,
  getBuildingsPage,
  getExpandedRow,
  selectBuildingsDetails,
} from "../../../slices/dashboardSlice/selectors";
import { Building, Status } from "../../../slices/dashboardSlice/types";
import { WrapperComponent } from "./Table.style";
import SelectComponent from "../../../components/Select/Select";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../slices/store";
import { Controller, useForm } from "react-hook-form";

interface CustomTableRowProps {
  enableBackgroundColor?: boolean;
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) =>
    prop !== "enableBackgroundColor" && prop !== "width",
})<CustomTableRowProps & { width?: string }>(
  ({ enableBackgroundColor, width = "auto" }) => ({
    "&:hover": {
      backgroundColor: "transparent",
    },
    backgroundColor: enableBackgroundColor ? "#2B2E43" : undefined,
    "&.MuiTableRow-head": {
      backgroundColor: "transparent",
    },
    width: `${width} !important`, // force the width to apply
  })
);

const TextFieldValidatorTable = styled(TextField)(({ theme }) => ({
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.primary,
  },
}));

interface ExpandableTableRowProps {
  index: number;
  building: Building;
}

const ExpandableTableRow: React.FC<ExpandableTableRowProps> = ({
  index,
  building,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const desktopMode = useSelector(getDesktopNavbarVisibility);
  const dispatch = useDispatch<AppDispatch>();
  const isExpanded = useSelector(getExpandedRow);
  const history = useNavigate();

  const renderStatus = () => {
    if (building.status.overall_status === "Completed") {
      return "Ολοκληρωμένη";
    } else if (building.status.overall_status === "Ongoing") {
      return "Σε Εξέλιξη";
    } else {
      return "Ανοικτή";
    }
  };

  interface ExpandableFormValues {
    hlektrologoi: {
      title: string;
      id?: string;
    };
    emfisites: {
      title: string;
      id?: string;
    };
    optikoi: {
      title: string;
      id?: string;
    };
    xwmatourgoi: {
      title: string;
      id?: string;
    };
    address: string;
    city: {
      title: string;
      id?: string;
    };
    iron_pipe: "BP" | "Ironpipe" | "BCP";
  }

  const renderCrew = (status: { title: string; id?: string | undefined }) => {
    if (status.id == "5") {
      return undefined;
    } else {
      return status;
    }
  };

  const { control, watch, setValue, handleSubmit, getValues, resetField } =
    useForm<ExpandableFormValues>({
      defaultValues: {
        hlektrologoi: building.status.status_hl,
        emfisites: renderCrew(building.status.status_em),
        optikoi: building.status.status_opt,
        xwmatourgoi: renderCrew(building.status.status_xwm),
        address: building.address,
        city: building.city,
        iron_pipe:
          building.iron_pipe === 1
            ? "BP"
            : building.iron_pipe === 2
            ? "Ironpipe"
            : building.iron_pipe === 3
            ? "BCP"
            : "BCP",
      },
    });
  const page = useSelector(getBuildingsPage);

  const onSubmit = () => {
    const params = {
      id_building: building.id,
      ...(watch("hlektrologoi")?.id &&
        watch("hlektrologoi")?.id !== null &&
        watch("hlektrologoi")?.id !== "" && {
          status_hl: watch("hlektrologoi")?.id,
        }),
      ...(watch("emfisites")?.id &&
        watch("emfisites")?.id !== null &&
        watch("emfisites")?.id !== "" &&
        watch("iron_pipe") !== "BCP" && {
          status_em: Number(watch("emfisites")?.id),
        }),
      ...(watch("optikoi")?.id &&
        watch("optikoi")?.id !== null &&
        watch("optikoi")?.id !== "" && {
          status_opt: watch("optikoi")?.id,
        }),
      ...(watch("xwmatourgoi")?.id &&
        watch("xwmatourgoi")?.id !== null &&
        watch("xwmatourgoi")?.id !== "" &&
        watch("iron_pipe") === "BP" && {
          status_xwm: watch("xwmatourgoi")?.id,
        }),
      address: getValues().address,
      id_city: getValues().city.id,
      iron_pipe:
        getValues("iron_pipe") === "BP"
          ? 1
          : getValues("iron_pipe") === "Ironpipe"
          ? 2
          : getValues("iron_pipe")
          ? 3
          : undefined,
    };
    dispatch(
      updateBuildingStatus({
        statusData: params,
        navigate: history,
        offset,
        limit: 10,
        page: page,
      })
    );
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };
  const { construction_crews, cities, offset } = useSelector(
    selectBuildingsDetails
  );

  const newContructionCrew = construction_crews?.filter(
    (crews: any) => crews.id != 5
  );

  const filteredCrews = construction_crews?.filter(
    (crews: any) => crews.id !== 5
  );

  // Separate the crews based on their construction type
  const hlektrologoiCrews = filteredCrews?.filter(
    (crew: any) => crew.construction_type === "HLEKTROLOGOI"
  );
  const optikaCrews = filteredCrews?.filter(
    (crew: any) => crew.construction_type === "OPTIKA"
  );
  const emfisisCrews = filteredCrews?.filter(
    (crew: any) => crew.construction_type === "EMFISISEIS"
  );
  const xwmatourgikaCrews = filteredCrews?.filter(
    (crew: any) => crew.construction_type === "XWMATOURGIKA"
  );

  return (
    <>
      <StyledTableRow>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.id}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.city.title}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.address} , {building.number}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.status.status_hl.title !== null
              ? building.status.status_hl.title === "NO NEED"
                ? "ΟΛΟΚΛΗΡΩΜΕΝΟ"
                : building.status.status_hl.title
              : "NA"}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.status.status_hl.title !== null
              ? building.status.status_em.title === "NO NEED"
                ? "ΟΛΟΚΛΗΡΩΜΕΝΟ"
                : building.status.status_em.title
              : "NA"}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.status.status_hl.title !== null
              ? building.status.status_opt.title === "NO NEED"
                ? "ΟΛΟΚΛΗΡΩΜΕΝΟ"
                : building.status.status_opt.title
              : "NA"}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.status.status_hl.title !== null
              ? building.status.status_xwm.title === "NO NEED"
                ? "ΟΛΟΚΛΗΡΩΜΕΝΟ"
                : building.status.status_xwm.title
              : "NA"}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {renderStatus()}
          </TextWorkSans>
        </CustomTableCell>

        <CustomTableCell width={"auto"} align="center" padding="checkbox">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingRight: "5px",
            }}
          >
            <IconButton
              size="large"
              onClick={() => {
                if (isExpanded === index) {
                  dispatch(setExpandedRow(-1));
                } else {
                  dispatch(setExpandedRow(index));
                }
              }}
            >
              {isExpanded === index ? (
                <Icon
                  color="primary"
                  width={15}
                  height={15}
                  name="upArrow"
                ></Icon>
              ) : (
                <Icon
                  color="primary"
                  width={15}
                  height={15}
                  name="downArrow"
                ></Icon>
              )}
            </IconButton>
          </div>
        </CustomTableCell>
      </StyledTableRow>
      {isExpanded === index && (
        <Fade in={isExpanded === index}>
          <TableRow>
            <CustomTableCell
              disablePadding={!desktopMode ? true : false}
              colSpan={9}
            >
              <WrapperComponent onSubmit={handleSubmit(onSubmit, onError)}>
                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                  <Controller
                    control={control}
                    name="hlektrologoi"
                    render={({ field }) => (
                      <SelectComponent
                        maxWidth={"220px"}
                        label="Διάλεξε Συνεργείο Ηλεκτρολόγων"
                        placeholder="= Επέλεξε -"
                        items={hlektrologoiCrews}
                        {...field}
                        value={watch("hlektrologoi")}
                        noneOption={true}
                      />
                    )}
                  />
                  <DisableComponent disabled={watch("iron_pipe") === "BCP"}>
                    <Controller
                      control={control}
                      name="emfisites"
                      render={({ field }) => (
                        <SelectComponent
                          maxWidth={"220px"}
                          label="Διάλεξε Συνεργείο Εμφισιτών"
                          placeholder="= Επέλεξε -"
                          items={emfisisCrews}
                          {...field}
                          value={watch("emfisites")}
                          noneOption={true}
                        />
                      )}
                    />
                  </DisableComponent>

                  <Controller
                    control={control}
                    name="optikoi"
                    render={({ field }) => (
                      <SelectComponent
                        maxWidth={"220px"}
                        label="Διάλεξε Συνεργείο Οπτικών"
                        placeholder="= Επέλεξε -"
                        items={optikaCrews}
                        {...field}
                        value={watch("optikoi")}
                        noneOption={true}
                      />
                    )}
                  />
                  <DisableComponent disabled={watch("iron_pipe") != "BP"}>
                    <Controller
                      control={control}
                      name="xwmatourgoi"
                      render={({ field }) => (
                        <SelectComponent
                          maxWidth={"220px"}
                          label="Διάλεξε Συνεργείο Χωματουργών"
                          placeholder="= Επέλεξε -"
                          items={xwmatourgikaCrews}
                          {...field}
                          value={watch("xwmatourgoi")}
                          noneOption={true}
                        />
                      )}
                    />
                  </DisableComponent>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <Controller
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <SelectComponent
                        maxWidth={"180px"}
                        label="Διάλεξε Πόλη"
                        placeholder="= Επέλεξε -"
                        items={cities}
                        {...field}
                        value={watch("city")}
                        noneOption={true}
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        formToday
                        maxwidth={200}
                        label={"Διεύθυνση"}
                        type="text"
                        placeholder="Διεύθυνση"
                        {...field}
                      />
                    )}
                  />
                </div>

                <Controller
                  name={"iron_pipe"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <RadioGroup
                        style={{ paddingTop: "30px" }}
                        row
                        defaultValue={"BP"}
                        {...field}
                      >
                        <FormControlLabel
                          value="BP"
                          control={<Radio />}
                          label="BP"
                        />
                        <FormControlLabel
                          value="Ironpipe"
                          control={<Radio />}
                          label="Σιδηροσωλήνας"
                        />
                        <FormControlLabel
                          value="BCP"
                          control={<Radio />}
                          label="BCP"
                        />
                      </RadioGroup>
                    );
                  }}
                />
                <Button
                  padding={0}
                  size="medium"
                  backgroundColor="primary"
                  color="white"
                  type="submit"
                  fontSize={12}
                >
                  ΠΡΟΣΘΗΚΗ ΣΥΝΕΡΓΕΙΩΝ
                </Button>
              </WrapperComponent>
            </CustomTableCell>
          </TableRow>
        </Fade>
      )}
    </>
  );
};

export default ExpandableTableRow;
