import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const SVGDiscover = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "23"}
      height={height ? height : "23"}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="m7.518 7.289 1.611-1.42-.423 2.106.792.457.602-4.243-3.375 2.643.793.457ZM8 13.714A5.714 5.714 0 1 1 13.714 8 5.714 5.714 0 0 1 8 13.714ZM8 1.143a6.857 6.857 0 1 0 0 13.715A6.857 6.857 0 0 0 8 1.143Zm.455 7.083a.686.686 0 1 0-1.373 0 .686.686 0 0 0 1.373 0Zm-2.047 2.356.424-2.105-.793-.457-.6 4.243L8.811 9.62l-.792-.457-1.612 1.42Z"
          id="a"
        />
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#999" xlinkHref="#a" />
      </g>
    </svg>
  );
};

export default SVGDiscover;
