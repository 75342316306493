import React, { useEffect, useState } from "react";
import { TextField } from "../../../components";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import SelectComponent from "../../../components/Select/Select";
import { useSelector } from "react-redux";
import { selectTransferProductsToCrew } from "../../../slices/transferProductsToCrewSlice/selectors";
import { FormValues } from "../../../slices/transferProductsToCrewSlice/transferProductsToCrewSlice";
import { selectFashDetails } from "../../../slices/receiptSlice/selectors";
import Button from "../../../components/Button/Button";
import { selectDashboardData } from "../../../slices/dashboardAdminSlice/selectors";

const SearchBar: React.FC<any> = () => {
  const { watch, control, reset, setValue } = useFormContext(); // Access form methods

  const [previewsCompany, setPreviewsCompany] = useState<
    | {
        title: string;
        id: string;
      }
    | undefined
  >({ title: "", id: "" });

  const { constructor_crew } = useSelector(selectTransferProductsToCrew);
  const { fasis, location, brands, companies } = useSelector(selectFashDetails);
  const { tabOption } = useSelector(selectDashboardData);
  const [fileterdCompanies, setFilteredCompanies] = useState<
    {
      title: string;
      id: string;
    }[]
  >([]);

  useEffect(() => {
    setPreviewsCompany(watch("from"));
  }, [watch("from"), location]);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        alignItems: "center",
        width: "100%",
        marginBottom: "40px",
      }}
    >
      {tabOption === 0 && (
        <>
          <Controller
            control={control}
            name="fash"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"180px"}
                required
                label="Διάλεξε Φάση"
                placeholder="= Επέλεξε -"
                {...field}
                items={fasis}
                value={watch("fash")}
              />
            )}
          />
          <Controller
            control={control}
            name="provider"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Πάροχο"
                placeholder="= Επέλεξε -"
                {...field}
                items={companies}
                value={watch("provider")}
              />
            )}
          />{" "}
          <Controller
            control={control}
            name="company"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Εταιρεία"
                placeholder="= Επέλεξε -"
                {...field}
                items={brands}
                value={watch("company")}
                onChange={(value) => {
                  field.onChange(value);
                  //dispatch(updateFormValue({ field: "company", value: value }));
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="location"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Τόπο Παραλαβή"
                placeholder="= Επέλεξε -"
                {...field}
                items={location}
                value={watch("location")}
                onChange={(value) => {
                  field.onChange(value);
                  // dispatch(
                  //   updateFormValue({ field: "location", value: value })
                  // );
                }}
              />
            )}
          />
        </>
      )}
      {tabOption === 1 && (
        <>
          <Controller
            control={control}
            name="from"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Απο / Αποθήκη"
                placeholder="= Επέλεξε -"
                {...field}
                items={location}
                value={watch("from")}
                onChange={(value) => {
                  field.onChange(value);
                  // dispatch(updateFormValue({ field: "from", value: value }));
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="to"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Προς / Αποθήκη"
                placeholder="= Επέλεξε -"
                {...field}
                items={location.length === 0 ? companies : location}
                value={watch("to")}
                onChange={(value) => {
                  field.onChange(value);
                  // dispatch(updateFormValue({ field: "to", value: value }));
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="company"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Διάλεξε Εταιρεία"
                placeholder="= Επέλεξε -"
                {...field}
                items={brands}
                value={watch("company")}
                onChange={(value) => {
                  field.onChange(value);
                  //dispatch(updateFormValue({ field: "company", value: value }));
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="fash"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"180px"}
                required
                label="Διάλεξε Φάση"
                placeholder="= Επέλεξε -"
                {...field}
                items={fasis}
                value={watch("fash")}
              />
            )}
          />
        </>
      )}
      {tabOption === 2 && (
        <>
          <Controller
            control={control}
            name="fash"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"180px"}
                required
                label="Διάλεξε Φάση"
                placeholder="= Επέλεξε -"
                {...field}
                items={fasis}
                value={watch("fash")}
              />
            )}
          />{" "}
          <Controller
            control={control}
            name="from"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Απο / Αποθήκη"
                placeholder="= Επέλεξε -"
                {...field}
                items={location}
                value={watch("from")}
              />
            )}
          />
          <Controller
            control={control}
            name="to_construction_crew"
            render={({ field }) => (
              <SelectComponent
                maxWidth={"300px"}
                required
                label="Προς / Συνεργείο"
                placeholder="= Επέλεξε -"
                {...field}
                items={constructor_crew}
                value={watch("to_construction_crew")}
              />
            )}
          />
          {/* <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TextField
                formToday
                maxwidth={200}
                label={"Ημερομηνία"}
                type="date"
                required
                placeholder="day"
                {...field}
              />
            )}
          /> */}
        </>
      )}
      <div style={{ marginTop: "auto" }}>
        <Button
          padding={0}
          size="medium"
          backgroundColor="primary"
          color="white"
          type="button"
          fontSize={12}
          onClick={() => {
            reset();
          }}
        >
          ΚΑΘΑΡΙΣΜΑ ΦΙΛΤΡΩΝ
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
