import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const DownloadArrow = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "18"}
      height={height ? height : "19"}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2034 8.05989C13.8883 7.66135 13.3097 7.59378 12.9111 7.90895L9.91991 10.2745V1.18025C9.91991 0.672152 9.50801 0.260254 8.99991 0.260254C8.49181 0.260254 8.07991 0.672151 8.07991 1.18025V10.1773L5.07397 7.89754C4.66914 7.5905 4.09204 7.66979 3.785 8.07462C3.47796 8.47946 3.55725 9.05655 3.96208 9.36359L8.53774 12.8339C8.87197 13.0874 9.33534 13.0827 9.66436 12.8225L14.0525 9.35218C14.451 9.037 14.5186 8.45843 14.2034 8.05989ZM0.0854492 14.5964C0.0854492 16.7613 1.8405 18.5164 4.00545 18.5164H13.9947C16.1597 18.5164 17.9147 16.7613 17.9147 14.5964V13.0311C17.9147 12.523 17.5028 12.1111 16.9947 12.1111C16.4866 12.1111 16.0747 12.523 16.0747 13.0311V14.5964C16.0747 15.7451 15.1435 16.6764 13.9947 16.6764H4.00545C2.8567 16.6764 1.92545 15.7451 1.92545 14.5964V13.0311C1.92545 12.523 1.51355 12.1111 1.00545 12.1111C0.497347 12.1111 0.0854492 12.523 0.0854492 13.0311V14.5964Z"
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default DownloadArrow;
