import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const FaceDetector = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "27"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 38c6.627 0 12-6.268 12-14s-5.373-14-12-14-12 6.268-12 14 5.373 14 12 14Z"
        stroke="currentColor"
        strokeWidth={0.3}
        fill={colors[color || "onyxDark"]}
      />
    </svg>
  );
};

export default FaceDetector;
