import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const Profile = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={width ? width : "40"}
      height={height ? height : "200"}
      viewBox="0 0 31 31"
      {...props}
    >
      <path
        fill="#2197F3"
        d="m30.39 7.08-14-6a.963.963 0 0 0-.78 0l-14 6C1.24 7.24 1 7.6 1 8c0 .15.07 9.9 4.25 16.66.41.67.86 1.31 1.36 1.91C8.79 29.18 11.81 31 16 31s7.21-1.82 9.39-4.43c.5-.6.95-1.24 1.36-1.91C30.93 17.9 31 8.15 31 8c0-.4-.24-.76-.61-.92z"
      />
      <path
        fill="#3F51B5"
        d="M26.75 24.66c-.41.67-.86 1.31-1.36 1.91C23.21 29.18 20.19 31 16 31s-7.21-1.82-9.39-4.43c-.5-.6-.95-1.24-1.36-1.91C7.22 20.71 11.3 18 16 18s8.78 2.71 10.75 6.66z"
      />
      <circle cx={16} cy={12} r={5} fill="#FFC10A" />
    </svg>
  );
};

export default Profile;
