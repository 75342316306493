import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const CTVerOrange = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      viewBox="0 0 666 461"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <g transform="matrix(1,0,0,1,-30020.5,-27766.9)">
        <g transform="matrix(0.981507,0,0,1.6278,739.369,-15939)">
          <g transform="matrix(1.99201,0,0,1.20111,29673.8,26750.5)">
            <g transform="matrix(0.698081,0,0,0.698081,79.8181,290.699)">
              <path
                d="M0,0.002C0,-16.102 12.359,-27.413 29.212,-27.413C39.475,-27.413 47.563,-23.668 52.731,-16.927L41.57,-6.964C38.5,-10.859 34.831,-13.107 30.111,-13.107C22.771,-13.107 17.827,-8.014 17.827,0.002C17.827,8.017 22.771,13.109 30.111,13.109C34.831,13.109 38.5,10.861 41.57,6.968L52.731,16.93C47.563,23.67 39.475,27.415 29.212,27.415C12.359,27.415 0,16.105 0,0.002"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,133.979,296.465)">
              <path
                d="M0,-0.064C0,-4.735 -2.565,-7.103 -5.853,-7.103C-9.143,-7.103 -11.708,-4.735 -11.708,-0.064C-11.708,4.605 -9.143,7.039 -5.853,7.039C-2.565,7.039 0,4.605 0,-0.064M-26.77,-0.064C-26.77,-10.983 -18.021,-18.678 -5.853,-18.678C6.381,-18.678 15.062,-10.983 15.062,-0.064C15.062,10.854 6.381,18.614 -5.853,18.614C-18.021,18.614 -26.77,10.854 -26.77,-0.064"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,188.477,297.751)">
              <path
                d="M0,-4.407L0,16.114L-14.864,16.114L-14.864,-1.842C-14.864,-6.314 -16.508,-8.025 -18.876,-8.025C-21.508,-8.025 -23.546,-6.25 -23.546,-1.448L-23.546,16.114L-38.412,16.114L-38.412,-1.842C-38.412,-6.314 -39.924,-8.025 -42.424,-8.025C-45.054,-8.025 -47.093,-6.25 -47.093,-1.448L-47.093,16.114L-61.958,16.114L-61.958,-19.864L-47.816,-19.864L-47.816,-16.51C-45.119,-19.206 -41.569,-20.521 -37.623,-20.521C-32.951,-20.521 -28.874,-18.811 -26.375,-15.062C-23.481,-18.55 -19.271,-20.521 -14.338,-20.521C-6.183,-20.521 0,-15.72 0,-4.407"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,199.767,298.21)">
              <path
                d="M0,-5.064L11.512,-7.3C10.722,-9.537 8.814,-11.05 6.118,-11.05C2.763,-11.05 0.461,-9.142 0,-5.064M15.128,2.499L22.89,10.326C19.337,14.141 14.273,16.114 7.169,16.114C-6.05,16.114 -14.602,8.157 -14.602,-2.564C-14.602,-13.483 -5.787,-21.178 6.052,-21.178C16.509,-21.178 25.585,-14.733 25.585,-2.762L0.987,1.973C2.171,4.209 4.409,5.131 7.631,5.131C10.918,5.131 12.761,4.275 15.128,2.499"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,244.863,295.353)">
              <path
                d="M0,-14.531C-3.895,-16.704 -7.864,-18.277 -12.283,-19.175L-12.283,19.55L-29.96,19.55L-29.96,-19.175C-34.379,-18.277 -38.35,-16.704 -42.245,-14.531L-48.086,-27.115C-40.522,-31.683 -30.935,-34.081 -21.122,-34.081C-11.31,-34.081 -1.723,-31.683 5.843,-27.115L0,-14.531Z"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,258.392,296.465)">
              <path
                d="M0,-0.064C0,-4.735 -2.564,-7.103 -5.854,-7.103C-9.142,-7.103 -11.706,-4.735 -11.706,-0.064C-11.706,4.605 -9.142,7.039 -5.854,7.039C-2.564,7.039 0,4.605 0,-0.064M-26.77,-0.064C-26.77,-10.983 -18.022,-18.678 -5.854,-18.678C6.382,-18.678 15.062,-10.983 15.062,-0.064C15.062,10.854 6.382,18.614 -5.854,18.614C-18.022,18.614 -26.77,10.854 -26.77,-0.064"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,287.408,306.292)">
              <path
                d="M0,-15.457C0,-18.944 -2.631,-21.18 -6.116,-21.18C-9.604,-21.18 -12.3,-18.944 -12.3,-15.457C-12.3,-11.971 -9.604,-9.668 -6.116,-9.668C-2.631,-9.668 0,-11.971 0,-15.457M14.732,-32.098L14.732,-3.289C14.732,10.655 6.577,17.298 -7.17,17.298C-14.273,17.298 -20.719,15.785 -25.19,12.825L-20.193,2.697C-17.365,4.867 -12.563,6.248 -8.682,6.248C-2.565,6.248 -0.132,3.552 -0.132,-1.251L-0.132,-1.908C-2.368,0.656 -5.656,1.908 -10.063,1.908C-19.206,1.908 -27.362,-4.802 -27.362,-15.457C-27.362,-26.112 -19.206,-32.755 -10.063,-32.755C-5.131,-32.755 -1.644,-31.242 0.593,-28.02L0.593,-32.098L14.732,-32.098Z"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,308.617,298.21)">
              <path
                d="M0,-5.064L11.512,-7.3C10.722,-9.537 8.814,-11.05 6.117,-11.05C2.764,-11.05 0.461,-9.142 0,-5.064M15.127,2.499L22.89,10.326C19.338,14.141 14.272,16.114 7.169,16.114C-6.051,16.114 -14.602,8.157 -14.602,-2.564C-14.602,-13.483 -5.788,-21.178 6.051,-21.178C16.509,-21.178 25.586,-14.733 25.586,-2.762L0.986,1.973C2.172,4.209 4.408,5.131 7.631,5.131C10.918,5.131 12.76,4.275 15.127,2.499"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,344.475,279.568)">
              <path
                d="M0,40.912C-2.302,42.227 -5.656,42.818 -9.143,42.818C-19.073,42.818 -24.861,38.148 -24.861,28.218L-24.861,-1.906L-9.997,-1.906L-9.997,8.156L-2.631,8.156L-2.631,19.009L-9.997,19.009L-9.997,28.087C-9.997,30.323 -8.682,31.572 -6.906,31.572C-5.722,31.572 -4.538,31.243 -3.552,30.586L0,40.912Z"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,372.297,289.257)">
              <path
                d="M0,7.762L0,28.283L-14.865,28.283L-14.865,10.327C-14.865,5.855 -16.574,4.144 -19.271,4.144C-22.297,4.144 -24.86,6.052 -24.86,11.379L-24.86,28.283L-39.726,28.283L-39.726,-20.521L-24.86,-20.521L-24.86,-4.801C-22.098,-7.169 -18.613,-8.352 -14.732,-8.352C-6.445,-8.352 0,-3.551 0,7.762"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,383.589,298.21)">
              <path
                d="M0,-5.064L11.511,-7.3C10.721,-9.537 8.813,-11.05 6.116,-11.05C2.763,-11.05 0.462,-9.142 0,-5.064M15.128,2.499L22.889,10.326C19.337,14.141 14.271,16.114 7.169,16.114C-6.051,16.114 -14.602,8.157 -14.602,-2.564C-14.602,-13.483 -5.788,-21.178 6.051,-21.178C16.509,-21.178 25.586,-14.733 25.586,-2.762L0.985,1.973C2.171,4.209 4.407,5.131 7.63,5.131C10.918,5.131 12.76,4.275 15.128,2.499"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,420.182,309.001)">
              <path
                d="M0,-36.636L0,-23.482C-1.316,-23.679 -2.236,-23.744 -3.289,-23.744C-7.762,-23.744 -10.919,-21.705 -10.919,-15.984L-10.919,0L-25.783,0L-25.783,-35.979L-11.643,-35.979L-11.643,-32.097C-9.012,-35.123 -4.999,-36.636 0,-36.636"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g transform="matrix(1.53698,0,0,0.926742,29787.6,26810.2)">
            <g transform="matrix(0.698081,0,0,0.698081,169.314,182.562)">
              <path
                d="M0,64.878C-42.17,20.936 -33.178,-42.171 -33.178,-42.171L5.284,-37.544L19.428,-10.503C-6.235,21.002 16.146,48.765 16.146,48.765C16.146,48.765 43.778,71.015 75.315,45.385L102.291,59.463L106.984,97.99C106.984,97.99 43.942,107.049 0,64.878"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,169.314,111.589)">
              <path
                d="M0,-64.879C-42.17,-20.937 -33.178,42.17 -33.178,42.17L5.284,37.543L19.428,10.502C-6.235,-21.003 16.146,-48.766 16.146,-48.766C16.146,-48.766 43.778,-71.016 75.315,-45.386L102.291,-59.464L106.984,-97.992C106.984,-97.992 43.942,-107.049 0,-64.879"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,330.708,182.562)">
              <path
                d="M0,64.878C42.138,20.936 33.146,-42.171 33.146,-42.171L-5.316,-37.544L-19.428,-10.503C6.202,21.002 -16.146,48.765 -16.146,48.765C-16.146,48.765 -43.811,71.015 -75.348,45.385L-102.323,59.463L-107.017,97.99C-107.017,97.99 -43.942,107.049 0,64.878"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.698081,0,0,0.698081,330.708,111.589)">
              <path
                d="M0,-64.879C42.138,-20.937 33.146,42.17 33.146,42.17L-5.316,37.543L-19.428,10.502C6.202,-21.003 -16.146,-48.766 -16.146,-48.766C-16.146,-48.766 -43.811,-71.016 -75.348,-45.386L-102.323,-59.464L-107.017,-97.992C-107.017,-97.992 -43.942,-107.049 0,-64.879"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.133982,0,0,0.133982,-487.415,7.50055)">
              <path
                d="M5628.79,1166.65C5676.5,1184.78 5730.18,1171.78 5804.57,1097.23C5822.34,1079.44 5838.59,1060.81 5853.12,1041.83C5959.48,1180.5 5979.82,1344.81 5893.3,1431.34C5806.96,1517.85 5642.46,1497.68 5503.97,1391.15C5509.83,1386.67 5509.82,1386.67 5503.97,1391.15C5484.82,1376.79 5466.18,1360.37 5448.4,1342.59C5373.85,1268.04 5360.85,1214.35 5379.15,1166.64C5398.85,1159.14 5398.85,1159.14 5379.15,1166.64C5395.39,1123.73 5437.11,1085.6 5481.57,1042C5485.33,1045.59 5488.92,1049.18 5492.68,1052.94L5503.97,1064.23L5503.97,1064.23C5547.74,1108.68 5585.87,1150.4 5628.79,1166.64L5628.79,1166.65Z"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.133982,0,0,0.133982,-487.415,7.50055)">
              <path
                d="M5378.98,917.004C5331.27,898.88 5277.58,911.874 5203.03,986.428C5185.25,1004.21 5169.01,1022.85 5154.47,1041.82C5048.12,903.157 5027.77,738.841 5114.29,652.319C5200.81,565.803 5365.3,586.149 5503.97,692.501C5484.82,706.865 5484.82,706.867 5503.97,692.502C5522.95,707.034 5541.58,723.279 5559.37,741.062C5633.92,815.611 5646.74,869.302 5628.79,917.008C5585.87,933.248 5547.74,974.972 5503.97,1019.6C5460.19,975.144 5422.07,933.248 5378.98,917.008L5378.98,917.004Z"
                fill={colors["orangeLogo"]}
                fillRule="nonzero"
              />
            </g>
            <g transform="matrix(0.133982,0,0,0.133982,-487.415,7.50055)">
              <path
                d="M5628.79,917.006C5646.74,869.301 5633.92,815.61 5559.36,741.061C5541.58,723.278 5522.95,707.033 5503.97,692.501C5642.46,585.977 5806.96,565.803 5893.3,652.32C5979.82,738.836 5959.48,903.158 5853.12,1041.82C5838.59,1022.85 5838.59,1022.85 5853.12,1041.82C5838.59,1060.81 5822.34,1079.45 5804.57,1097.23C5730.19,1171.78 5676.5,1184.77 5628.79,1166.65C5622.02,1148.94 5622.02,1148.94 5628.79,1166.65C5585.87,1150.4 5547.74,1108.68 5503.97,1064.22L5492.68,1052.94C5488.92,1049.18 5485.33,1045.59 5481.57,1042L5481.57,1042C5467.06,1027.65 5467.06,1027.65 5481.57,1042C5437.11,1085.6 5395.39,1123.73 5379.15,1166.64C5331.44,1184.77 5331.44,1184.77 5379.15,1166.65C5360.85,1214.35 5373.85,1268.04 5448.4,1342.59C5466.18,1360.37 5484.82,1376.79 5503.97,1391.15C5365.3,1497.5 5200.81,1517.85 5114.29,1431.33C5027.77,1344.82 5048.12,1180.5 5154.47,1041.82L5154.47,1041.83C5169.01,1022.85 5185.25,1004.21 5203.03,986.427C5277.58,911.878 5331.27,898.879 5378.98,917.005C5379.23,917.677 5379.5,918.348 5379.77,919.022C5379.5,918.351 5379.24,917.68 5378.98,917.008C5422.06,933.248 5460.2,975.144 5503.97,1019.6C5500.21,1023.19 5500.21,1023.19 5503.97,1019.6C5504.27,1019.91 5504.57,1020.22 5504.86,1020.53C5504.57,1020.22 5504.27,1019.91 5503.97,1019.6C5547.74,974.969 5585.87,933.251 5628.79,917.006ZM5628.79,917.006C5641.09,912.333 5641.09,912.334 5628.79,917.007C5625.94,924.448 5625.94,924.447 5628.79,917.006Z"
                fill={colors["yellowLogo"]}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CTVerOrange;
