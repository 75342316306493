import React, { useEffect } from "react";
import { TextWorkSans } from "../../../components/common.style";
import { CustomTableCell } from "../Table";
import { TextField } from "../../../components";
import { Product } from "../../../slices/productsSlice/productsSlice";
import { Control, Controller } from "react-hook-form";
import { FormValues } from "../../../slices/receiptTransferSlice/receiptTransferSlice";

const CustomTableRow: React.FC<{
  product: Product;
  control: Control<FormValues, any>;
  fields: any[];
}> = ({ product, control, fields }) => {
  const findFieldIndex = (productId: number) => {
    return fields.findIndex((field) => field.productId === productId);
  };

  const index = findFieldIndex(product.product_id);

  const productRow = [
    {
      key: "id_product",
      value: <TextWorkSans color="black">{product.product_id}</TextWorkSans>,
    },
    {
      key: "kay",
      value: <TextWorkSans color="black">{product.kay}</TextWorkSans>,
    },
    {
      key: "name_product",
      value: <TextWorkSans color="black">{product.product_name}</TextWorkSans>,
    },
    {
      key: "mm",
      value: <TextWorkSans color="black">{product.mm}</TextWorkSans>,
    },
    {
      key: "fash",
      value: <TextWorkSans color="black">{product.fash}</TextWorkSans>,
    },
    {
      key: "typos",
      value: <TextWorkSans color="black">{product.typos}</TextWorkSans>,
    },
    {
      key: "apothema",
      value: <TextWorkSans color="black">{product.quantity}</TextWorkSans>,
    },
    {
      key: "posothta",
      value: (
        <Controller
          control={control}
          name={`products.${index}.quantity`}
          render={({ field }) => (
            <TextField
              type="number"
              borderBottom="1px solid blue"
              borderRadius="0px"
              acceptInteger
              minWidth={100}
              {...field}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
      ),
    },
  ];
  return (
    <tr>
      {productRow.map((row, key) => (
        <CustomTableCell
          backgroundColor={product.sn === "NO" ? "lightGrey" : undefined}
          key={key}
        >
          {row.value}
        </CustomTableCell>
      ))}
    </tr>
  );
};

export default CustomTableRow;
