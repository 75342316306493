import { useTheme } from "@mui/material";
import { IconProps } from "../Icon.interface";

const SVGMyTickets = ({ color, width, height, ...props }: IconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      width={width ? width : "17"}
      height={height ? height : "17"}
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.141.814A1.14 1.14 0 0 1 13.33 1.92l.28 9.692a1.14 1.14 0 0 1-1.092 1.172l-2.566.107-6.408 1.81a1.14 1.14 0 0 1-1.414-.816L.833 8.789.105 5.926l-.103-.83a1.14 1.14 0 0 1 .806-1.233l8.09-2.41a1.14 1.14 0 0 1 1.43.813l.254.996.5 1.967 1.469 5.775c.048.19.045.381 0 .559.11-.2.16-.432.134-.67l-.02-.122-2.069-9.335a1.14 1.14 0 0 0-.292-.545L12.14.814Zm2.086.693.898.199a1.14 1.14 0 0 1 .869 1.354l-1.855 8.588.088-10.14ZM5.257 5.4l-.108.155-.168-.06c-.95-.305-1.851-.104-2.162.546-.327.69.114 1.651 1.002 2.296-.34 1.043-.131 2.081.539 2.446.63.342 1.475-.02 2.064-.821l.107-.155.168.059c.95.306 1.85.105 2.161-.546.31-.648-.065-1.538-.851-2.178l-.152-.117.054-.183c.26-.98.04-1.92-.592-2.263-.63-.343-1.474.02-2.062.82ZM7.917.992 3.433 2.445l-.005-.138A1.14 1.14 0 0 1 4.52 1.135L7.918.992Z"
        fill={colors[color || "onyxDark"]}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SVGMyTickets;
