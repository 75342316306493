import React, { useEffect, useState } from "react";
import {
  DisableComponent,
  TextWorkSans,
} from "../../../components/common.style";
import { CustomTableCell } from "../Table";
import { Icon, TextField } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  Fade,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TableRow,
  styled,
} from "@mui/material";
import { getDesktopNavbarVisibility } from "../../../slices/desktopMode/selectors";
import {
  setExpandedRow,
  updateBuildingStatus,
} from "../../../slices/dashboardSlice/dashboardSlice";
import {
  getBuildings,
  getBuildingsPage,
  getExpandedRow,
  selectBuildingsDetails,
} from "../../../slices/dashboardSlice/selectors";
import { WrapperComponent } from "./Table.style";
import SelectComponent from "../../../components/Select/Select";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../slices/store";
import { Controller, useForm } from "react-hook-form";
import { Building } from "../../../slices/crewDashboardSlice/crewDashboardSlice";

interface CustomTableRowProps {
  enableBackgroundColor?: boolean;
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) =>
    prop !== "enableBackgroundColor" && prop !== "width",
})<CustomTableRowProps & { width?: string }>(
  ({ enableBackgroundColor, width = "auto" }) => ({
    "&:hover": {
      backgroundColor: "transparent",
    },
    backgroundColor: enableBackgroundColor ? "#2B2E43" : undefined,
    "&.MuiTableRow-head": {
      backgroundColor: "transparent",
    },
    width: `${width} !important`, // force the width to apply
  })
);

const TextFieldValidatorTable = styled(TextField)(({ theme }) => ({
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.primary,
  },
}));

interface ExpandableTableRowProps {
  index: number;
  building: Building;
}

const ExpandableTableRow: React.FC<ExpandableTableRowProps> = ({
  index,
  building,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const desktopMode = useSelector(getDesktopNavbarVisibility);
  const dispatch = useDispatch<AppDispatch>();
  const isExpanded = useSelector(getExpandedRow);
  const history = useNavigate();

  const renderStatus = () => {
    if (building.status.overall_status === "Completed") {
      return "Ολοκληρωμένη";
    } else if (building.status.overall_status === "Ongoing") {
      return "Σε Εξέλιξη";
    } else {
      return "Ανοικτή";
    }
  };

  interface ExpandableFormValues {
    hlektrologoi: {
      title: string;
      id?: string;
    };
    emfisites: {
      title: string;
      id?: string;
    };
    optikoi: {
      title: string;
      id?: string;
    };
    xwmatourgoi: {
      title: string;
      id?: string;
    };
    address: string;
    city: {
      title: string;
      id?: string;
    };
    iron_pipe: "BP" | "Ironpipe" | "BCP";
  }

  const renderCrew = (status: { title: string; id?: string | undefined }) => {
    if (status.id == "5") {
      return undefined;
    } else {
      return status;
    }
  };
  const navigate = useNavigate();

  // const { control, watch, setValue, handleSubmit, getValues, resetField } =
  //   useForm<any>({
  //     defaultValues: {
  //       hlektrologoi: building.status.status_hl,
  //       emfisites: renderCrew(building.status.status_em),
  //       optikoi: building.status.status_opt,
  //       xwmatourgoi: renderCrew(building.status.status_xwm),
  //       address: building.address,
  //       city: building.city,
  //       iron_pipe:
  //         building.iron_pipe === 1
  //           ? "BP"
  //           : building.iron_pipe === 2
  //           ? "Ironpipe"
  //           : building.iron_pipe === 3
  //           ? "BCP"
  //           : "BCP",
  //     },
  //   });
  const page = useSelector(getBuildingsPage);

  const onSubmit = () => {
    // console.log(getValues());
    // console.log(
    //   watch("emfisites")?.id !== null && watch("iron_pipe") !== "BCP"
    // );
    // const params = {
    //   id_building: building.id,
    //   ...(watch("hlektrologoi")?.id !== null &&
    //     watch("hlektrologoi")?.id !== "" && {
    //       status_hl: watch("hlektrologoi")?.id,
    //     }),
    //   ...(watch("emfisites")?.id !== null &&
    //     watch("emfisites")?.id !== "" &&
    //     watch("iron_pipe") !== "BCP" && {
    //       status_em: Number(watch("emfisites")?.id),
    //     }),
    //   ...(watch("optikoi")?.id !== null &&
    //     watch("optikoi")?.id !== "" && {
    //       status_opt: watch("optikoi")?.id,
    //     }),
    //   ...(watch("xwmatourgoi")?.id !== null &&
    //     watch("xwmatourgoi")?.id !== "" &&
    //     watch("iron_pipe") === "BP" && {
    //       status_xwm: watch("xwmatourgoi")?.id,
    //     }),
    //   address: getValues().address,
    //   id_city: getValues().city.id,
    //   iron_pipe:
    //     getValues("iron_pipe") === "BP"
    //       ? 1
    //       : getValues("iron_pipe") === "Ironpipe"
    //       ? 2
    //       : getValues("iron_pipe")
    //       ? 3
    //       : undefined,
    // };
    // dispatch(
    //   updateBuildingStatus({
    //     statusData: params,
    //     navigate: history,
    //     offset,
    //     limit: 10,
    //     page: page,
    //   })
    // );
  };

  const onError = () => {
    console.log("ERROR SUBMITTED");
  };

  return (
    <>
      <StyledTableRow>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.id}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.city.title}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.address} , {building.number}
          </TextWorkSans>
        </CustomTableCell>
        <CustomTableCell width={"auto"} align="left">
          <TextWorkSans fontSize={14} color="primary">
            {building.status.overall_status}
          </TextWorkSans>
        </CustomTableCell>

        <CustomTableCell width={"auto"} align="center" padding="checkbox">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingRight: "5px",
            }}
          >
            <IconButton
              size="large"
              onClick={() => {
                navigate(`/myBuildings/${building.id}`);
              }}
            >
              <Icon color="primary" width={15} height={15} name="edit"></Icon>
            </IconButton>
          </div>
        </CustomTableCell>
      </StyledTableRow>
    </>
  );
};

export default ExpandableTableRow;
