import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Basic selectors
export const getGenericModal = (state: RootState) => state.modal.genericModal;
export const getLoginModal = (state: RootState) => state.modal.loginModal;
export const getModalButtonLoader = (state: RootState) =>
  state.modal.modalButtonLoader;
export const getLoaderStatusModal = (state: RootState) => state.modal.loader;
export const getData = (state: RootState) => state.modal.data;

// More complex selectors can be created using createSelector if needed
// For example, a selector that returns true if any modal is currently active:
export const isAnyModalOpen = createSelector(
  [getGenericModal, getLoginModal],
  (genericModal, loginModal) => genericModal !== undefined || loginModal
);
